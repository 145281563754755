import React from 'react';
import { useTranslationX } from 'i18n';

import { renderComponent } from 'utils/components';
import { Grid, Row } from 'components/containers';
import { KeyValueRow, Tag, WrapperCard } from 'components/elements';
import { appendDots, formatDateToBrazilian } from 'utils/stringUtils';
import { CheckCircle } from 'components/icons';

import Tippy from '@tippyjs/react';

import { ProjectItem } from '../ProjectList';
import { ProjectStatus, projectStatusColors } from 'pages/project/types';

import styles from './ProjectCard.module.scss';

type Props = {
  item: ProjectItem;
  onPressView: () => void;
};

export const ProjectCard: React.FC<Props> = ({ item, onPressView }) => {
  const { numero, sigla, estado, coordenador, data_finalizacao, data_publicacao } = item;
  const { tx, t } = useTranslationX('accountability.projectList', 'finance');

  return (
    <>
      <WrapperCard maxHeight="160px" padding="16px 18px">
        <div className={styles.expenseCard}>
          <p className={styles.title}>
            <Tippy
              content={tx('identification', {
                number: numero,
                sigla,
              })}
              animation="perspective"
              touch={false}
              className={styles.popup}
            >
              <span>
                {appendDots(
                  tx('identification', {
                    number: numero,
                    sigla,
                  }),
                  30
                )}
              </span>
            </Tippy>
          </p>
          <Grid spacing={0} gap={8}>
            <Row>
              <Tag
                color={projectStatusColors[estado as ProjectStatus]}
                text={t(`accountability.status.${estado}`)}
              />
            </Row>

            <Row>
              <KeyValueRow
                label={tx('columns.coordinator')}
                value={coordenador}
                size="small"
                margin="0"
                customStyles={{
                  flexWrap: 'nowrap',
                  whiteSpace: 'nowrap',
                }}
              />
            </Row>
            <Row>
              <KeyValueRow
                value={formatDateToBrazilian(new Date(data_publicacao))}
                size="small"
                margin="0"
                customStyles={{
                  flexWrap: 'nowrap',
                  whiteSpace: 'nowrap',
                }}
              />
              <KeyValueRow
                value={formatDateToBrazilian(new Date(data_finalizacao))}
                size="small"
                margin="0"
                customStyles={{
                  flexWrap: 'nowrap',
                  whiteSpace: 'nowrap',
                }}
              />
            </Row>
          </Grid>
          <div className={styles.footer} onClick={onPressView}>
            <div className={styles.order}>
              {renderComponent(CheckCircle, { size: 0.8 })}
              <span className={styles.label}>{tx('view')}</span>
            </div>
          </div>
        </div>
      </WrapperCard>
    </>
  );
};

export default ProjectCard;
