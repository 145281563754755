import React from 'react';
import { useTranslation, useTranslationX } from 'i18n';

import { classes, renderComponent } from 'utils/components';
import { Grid, Row } from 'components/containers';
import { KeyValueRow, WrapperCard } from 'components/elements';
import { appendDots } from 'utils/stringUtils';
import { CheckCircle } from 'components/icons';
import { getMasked } from 'utils/inputMask';

import Tippy from '@tippyjs/react';

import { ProjectMotherAccountItem } from 'pages/finance/ConciliationSourceList/types';

import styles from './MotherAccountCard.module.scss';
import { useHistory } from 'react-router-dom';

type Props = {
  item: ProjectMotherAccountItem;
};

export const MotherAccountCard: React.FC<Props> = ({ item }) => {
  const {
    id,
    name,
    source,
    debitValue,
    creditValue,
    pendingCredit,
    pendingDebit,
    agency,
    account,
  } = item;
  const { tx } = useTranslationX('expenses', 'finance');
  const { t } = useTranslation('translation');
  const history = useHistory();

  const onClick = () => {
    history.push(`/dashboard/financeiro/conciliacao/contas-mae/${id}`);
  };

  return (
    <>
      <WrapperCard maxHeight="260px" padding="16px 18px">
        <div className={styles.expenseCard}>
          <p className={styles.title}>
            <Tippy
              content={name}
              animation="perspective"
              touch={false}
              className={styles.popup}
            >
              <span>{appendDots(name, 30)}</span>
            </Tippy>
          </p>
          <Grid spacing={0} gap={8}>
            <Row>
              <KeyValueRow
                label={tx('source')}
                value={source}
                size="small"
                margin="0"
                customStyles={{
                  flexWrap: 'nowrap',
                  whiteSpace: 'nowrap',
                }}
              />
            </Row>
            <Row>
              <KeyValueRow
                label={tx('agency')}
                value={agency}
                size="small"
                margin="0"
                customStyles={{
                  flexWrap: 'nowrap',
                  whiteSpace: 'nowrap',
                }}
              />
              <KeyValueRow
                label={tx('account')}
                value={account}
                margin="0"
                customStyles={{
                  flexWrap: 'nowrap',
                  whiteSpace: 'nowrap',
                }}
              />
            </Row>
            <Row>
              <div className={classes(styles.tag, styles.credit)}>{tx('credit')}</div>
              <div className={classes(styles.tag, styles.debit)}>{tx('debit')}</div>
            </Row>

            <Row>
              <KeyValueRow
                label={tx('pending')}
                value={pendingCredit}
                size="small"
                margin="0"
              />
              <KeyValueRow label={tx('pending')} value={pendingDebit} margin="0" />
            </Row>
            <Row>
              <KeyValueRow
                label={tx('value')}
                value={`${t('currency')} ${getMasked((creditValue || 0).toFixed(2), {
                  pattern: 'currency',
                })}`}
                margin="0"
                customStyles={{
                  flexWrap: 'nowrap',
                  whiteSpace: 'nowrap',
                }}
              />
              <KeyValueRow
                label={tx('value')}
                value={`${t('currency')} ${getMasked((debitValue || 0).toFixed(2), {
                  pattern: 'currency',
                })}`}
                margin="0"
                customStyles={{
                  flexWrap: 'nowrap',
                  whiteSpace: 'nowrap',
                }}
              />
            </Row>
          </Grid>
          <div className={styles.footer} onClick={onClick}>
            <div className={styles.order}>
              {renderComponent(CheckCircle, { size: 0.8 })}
              <span className={styles.label}>{tx('view')}</span>
            </div>
          </div>
        </div>
      </WrapperCard>
    </>
  );
};

export default MotherAccountCard;
