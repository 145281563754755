import React from 'react';

import { Expenditure, OthersExpenditure } from 'components/icons';
import { TopMenuItemIcon } from 'pages/dashboard/types';
import { ConfigTopPanel } from 'pages/dashboard';

import { useTranslationX } from 'i18n';
import RHDebitList from './RHDebitList';
import DRDebitList from './DRDebitList';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'pages/finance/ConciliationSourceList/types';

export type ExpenseItem = {
  number: string;
  slug: string;
  pendingCredit: number;
  pendingDebit: number;
  debitValue: number;
  creditValue: number;
  id: number;
};

type Props = {
  onReload: () => void;
};

const DebitList: React.FC<Props> = ({ onReload }) => {
  const { tx } = useTranslationX('expenses', 'finance');
  const { lancamento_id, id } = useParams<RouteParams>();

  const items: TopMenuItemIcon[] = [
    {
      key: 'recursos-humanos',
      component: RHDebitList,
      icon: OthersExpenditure,
      itemProps: {
        onReload,
        lancamento_id,
        id,
      },
      title: tx('rhExpense'),
    },
    {
      key: 'demais-rubricas',
      component: DRDebitList,
      icon: Expenditure,
      itemProps: {
        onReload,
        lancamento_id,
        id,
      },
      title: tx('drExpense'),
    },
  ];

  return (
    <>
      <ConfigTopPanel items={items} />
    </>
  );
};

export default DebitList;
