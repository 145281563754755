import React, { useState } from 'react';
import { AiOutlineFileText, AiOutlineDownload, AiOutlineUpload } from 'react-icons/ai';
import { MdOutlineDriveFolderUpload } from 'react-icons/md';
import { RiMailSendLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';

import { useFetch, useRequest } from 'apis';
import {
  Grid,
  Modal,
  ModalPDFViewer,
  Row,
  ScrollPanel,
  Tab,
  Tabs,
} from 'components/containers';
import { QuestionType } from 'components/containers/Questions/Questions';
import { AlertLine, Button, Loader, NotFoundMessage } from 'components/elements';
import { Pageable } from 'components/form/types';
import { RolesList } from 'pages/project/Sections/HumanResources';
import {
  DatePicker,
  FileUploader,
  Form,
  HiddenField,
  RadioGroup,
  SubmitButton,
  TextArea,
  TextField,
  useFormObserver,
} from 'components/form';
import { Warning, X } from 'components/icons';
import InfoDialog from 'components/containers/Dialog/InfoDialog';
import { useProjectEditor } from 'pages/project/ProjectEditor/contexts';
import { useTranslationX } from 'i18n';
import { Contract, PartData } from 'pages/project/types';

import styles from './ActionButtons.module.scss';
import { messageService } from 'services';

interface ModalProps {
  visible: boolean;
  data?: Contract;
  setVisible(value: boolean): void;
  mainAction(): void;
}

interface ButtonCancelProps {
  mainAction?(value?: boolean): void;
  secondAction?(): void;
  text: {
    textButton: string;
    textCancel?: string;
  };
  icon?: React.FC;
}

const ButtonCancel: React.FC<ButtonCancelProps> = ({
  mainAction,
  secondAction,
  text,
  icon,
}) => {
  return (
    <div className={styles.cancelDesligamento}>
      <Button
        style={{
          root: {
            background: '#37A64A',
            color: '#fff',
          },
          text: { fontFamily: 'Roboto', fontWeight: 500 },
        }}
        icon={icon ?? AiOutlineFileText}
        theme="light"
        key={1}
        onClick={() => mainAction && mainAction(true)}
      >
        {text.textButton}
      </Button>

      {text.textCancel && (
        <div className={styles.cancelText} onClick={() => secondAction && secondAction()}>
          <X size={1.5} />
          <span>{text.textCancel}</span>
        </div>
      )}
    </div>
  );
};

const ModalConfirmation: React.FC<ModalProps> = ({ mainAction, setVisible, visible }) => {
  const { tx } = useTranslationX('viewPerson', 'project');

  return (
    <Modal visible={visible} minWidth={true} width={600} delay={100}>
      <div className={styles.modalContainer}>
        <div className={styles.card}>
          <div className={styles.warning}>
            <Warning size={3} />
          </div>
          <h2>{tx('modals.terminationConfirm.title')}</h2>
          <p style={{ marginBottom: '20px' }}>{tx('modals.terminationConfirm.text')}</p>
          <div className={styles.buttons}>
            <Button onClick={() => mainAction()} style={{ root: { width: 300 } }}>
              {tx('modals.terminationConfirm.confirmButton')}
            </Button>
            <Button
              type="secondary"
              onClick={() => {
                setVisible(false);
              }}
            >
              {tx('buttons.cancel')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const ModalTermination: React.FC<ModalProps> = ({
  mainAction,
  setVisible,
  visible,
  data,
}) => {
  const { state } = useProjectEditor();
  const { tx } = useTranslationX('viewPerson.modals.terminationConfirm', 'project');

  return (
    <Modal visible={visible} minWidth={true} width={600} delay={100}>
      <div className={styles.modalContainer}>
        <div className={styles.card}>
          <h2>{tx('title')}</h2>
          <p style={{ marginBottom: '20px' }}>{tx('text')}</p>
          <div>
            <Form
              metadata={{}}
              baseUrl={`projeto/${state.id}/recursos-humanos/desligamento/${state.contract?.projetoRHId}`}
              onDone={() => mainAction()}
            >
              <ModalTerminationForm
                visible={visible}
                setVisible={setVisible}
                mainAction={mainAction}
                data={data}
              />
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const ModalTerminationForm: React.FC<ModalProps> = ({ mainAction, setVisible, data }) => {
  const avisoPrevio = useFormObserver('avisoPrevio', true);
  const { tx } = useTranslationX('viewPerson.modals.terminationConfirm', 'project');

  return (
    <>
      {data?.projetoRH.vinculo === 'CELETISTA' ? (
        <>
          <DatePicker
            theme="classic"
            name="dataDesligamento"
            label={tx('terminationDate')}
            metadata={{ rules: { required: true } }}
          />
          <RadioGroup
            label={tx('earlyWarning')}
            name="avisoPrevio"
            columns={2}
            autosave={false}
            metadata={{
              rules: {
                required: true,
              },
              options: [
                { value: 'indenizado', text: 'Indenizado' },
                { value: 'trabalhado', text: 'Trabalhado' },
              ],
            }}
          />
          {String(avisoPrevio?.data?.value) === 'indenizado' && (
            <TextArea label={tx('justificativa')} name={'justificativa'} minRows={3} />
          )}
          <RadioGroup
            label={tx('initiative')}
            name="iniciativa"
            columns={2}
            autosave={false}
            metadata={{
              rules: {
                required: true,
              },
              options: [
                { value: 'empregado', text: 'Empregado' },
                { value: 'empregador', text: 'Empregador' },
              ],
            }}
          />
        </>
      ) : (
        <>
          <DatePicker
            theme="classic"
            name="dataDesligamento"
            label={tx('dataDesligamento')}
            metadata={{ rules: { required: true } }}
          />
        </>
      )}

      <div className={styles.buttons}>
        <SubmitButton onClick={() => mainAction()}>{tx('confirmButton')}</SubmitButton>
        <Button
          type="secondary"
          onClick={() => {
            setVisible(false);
          }}
        >
          {tx('cancelButton')}
        </Button>
      </div>
    </>
  );
};

const ModalTransferPerson: React.FC<ModalProps> = props => {
  const { tx } = useTranslationX('rh.person.manage', 'project');
  const { state } = useProjectEditor();
  const { data } = useFetch<PartData[]>(
    `/projeto/${state.id}/${state.section}/${props.data?.pessoa.id}/vagas`
  );

  if (!data) {
    return <Loader />;
  }

  return (
    <Modal
      visible={props.visible}
      onClose={() => props.setVisible(false)}
      onBlur={() => props.setVisible(false)}
      width={1200}
    >
      <div className={styles.card}>
        <div className={styles.headerTable}>
          <h2>{tx('transfer-title')}</h2>
          <span
            onClick={() => {
              props.setVisible(false);
            }}
          >
            <X size={2} />
          </span>
        </div>
        <Tabs selectedIndex={0}>
          {data.map((item: any) => (
            <Tab title={item.nome} key={item.id}>
              <ScrollPanel>
                <RolesList item={item} padding="15px 20px 20px 5px" fullWidth />
              </ScrollPanel>
            </Tab>
          ))}
        </Tabs>

        {!data?.length && <NotFoundMessage />}
      </div>
    </Modal>
  );
};

const ModalVacation: React.FC<ModalProps> = props => {
  const generateVocation = () => {
    props.mainAction();
    props.setVisible(false);
  };
  const { state } = useProjectEditor();
  const { tx } = useTranslationX('viewPerson', 'project');

  return (
    <Modal
      visible={props.visible}
      onClose={() => props.setVisible(false)}
      onBlur={() => props.setVisible(false)}
    >
      <div className={styles.card} style={{ width: 600 }}>
        <div className={styles.headerTable}>
          <h2 className={styles.h2}>{tx('modals.vacation.title')}</h2>
          <span
            onClick={() => {
              props.setVisible(false);
            }}
          >
            <X size={2} />
          </span>
        </div>

        <Form
          submit={{ format: 'multipart', method: 'post' }}
          baseUrl={`/projeto/${state?.id}/execucao/recursos-humanos/solicitacoes/ferias-rh`}
          onDone={props.mainAction}
        >
          <HiddenField name="id" value={state.contract?.projetoRHId} />
          <Row width={[0.5, 0.5]} align="center">
            <DatePicker
              theme="classic"
              name="dataInicio"
              label={tx('modals.vacation.initDate')}
            />
            {state.contract?.projetoRH.vinculo === 'ESTAGIARIO' ? (
              <TextField
                theme="classic"
                name="duracao"
                value={30}
                type="readonly"
                label={tx('modals.vacation.period')}
              />
            ) : (
              <RadioGroup
                label={tx('modals.vacation.period')}
                metadata={{
                  rules: { required: true },
                  options: [
                    { value: '20', text: '20 DIAS' },
                    { value: '30', text: '30 DIAS' },
                  ],
                }}
                columns={3}
                name="duracao"
              />
            )}
          </Row>
          <Row align="center">
            <Button
              type="secondary"
              onClick={() => {
                props.setVisible(false);
              }}
            >
              {tx('buttons.cancel')}
            </Button>
            <SubmitButton onClick={() => generateVocation()}>
              {tx('modals.vacation.confirmButton')}
            </SubmitButton>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

const ModalExtension: React.FC<ModalProps> = props => {
  const { state } = useProjectEditor();
  const { tx } = useTranslationX('viewPerson', 'project');

  return (
    <Modal
      visible={props.visible}
      onClose={() => props.setVisible(false)}
      onBlur={() => props.setVisible(false)}
    >
      <div className={styles.card} style={{ width: 600 }}>
        <div className={styles.headerTable}>
          <h2 className={styles.h2}>{tx('modals.extension.title')}</h2>
          <span
            onClick={() => {
              props.setVisible(false);
            }}
          >
            <X size={2} />
          </span>
        </div>
        <Form
          submit={{ format: 'multipart', method: 'post' }}
          baseUrl={`/projeto/${state?.id}/execucao/recursos-humanos/solicitacoes/prorrogacao-rh`}
          onDone={props.mainAction}
        >
          <HiddenField name="id" value={props.data?.projetoRHId} />
          <Row width={[0.1]} align="center">
            <TextArea
              minRows={3}
              theme="classic"
              name="justificativa"
              label={tx('modals.extension.justification')}
            />
          </Row>
          <Row width={[0.5, 0.5]} align="center">
            <TextField
              theme="classic"
              name="meses"
              label={tx('modals.extension.months')}
            />
          </Row>
          <Row align="center">
            <Button
              type="secondary"
              onClick={() => {
                props.setVisible(false);
              }}
            >
              {tx('buttons.cancel')}
            </Button>
            <SubmitButton>{tx('modals.extension.confirmButton')}</SubmitButton>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

const ModalInternship: React.FC<ModalProps> = props => {
  const { state } = useProjectEditor();
  const { tx } = useTranslationX('viewPerson', 'project');

  const request = useRequest();

  const handleSubmitArchives = ({ requestBody }: { requestBody?: any }) => {
    if (requestBody.termoEstagio?.length === 0) {
      return;
    }

    if (requestBody.termoEstagio?.[0]) {
      const formData = new FormData();
      formData.set('file', requestBody.termoEstagio?.[0]);
      const source = request({
        method: 'POST',
        url: `/projeto/${state.id}/recursos-humanos/${state.contract?.projetoRHId}/documento/set_doc_termo_estagio`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onSuccess: () => {
          props.setVisible(false);
          messageService.success(tx('modals.internship.successMessage'), {
            duration: 2000,
          });
        },
        onError: () => {
          props.setVisible(false);
          messageService.error(tx('modals.internship.errorMessage'), {
            duration: 2000,
          });
        },
      });
      return () => source.cancel();
    }
  };

  return (
    <Modal
      visible={props.visible}
      onClose={() => props.setVisible(false)}
      onBlur={() => props.setVisible(false)}
    >
      <div className={styles.card} style={{ width: 300 }}>
        <div className={styles.headerTable}>
          <h2 className={styles.h2}>{tx('modals.internship.title')}</h2>
          <span
            onClick={() => {
              props.setVisible(false);
            }}
          >
            <X size={2} />
          </span>
        </div>
        <Form onSubmit={handleSubmitArchives}>
          <HiddenField name="id" value={props.data?.projetoRHId} />
          <Row width={[0.1]} align="center">
            <FileUploader
              name="termoEstagio"
              message={tx('modals.internship.title')}
              metadata={{ rules: { accept: ['pdf'], maxfiles: 1, filesize: 5242880 } }}
            />
          </Row>

          <Row align="center">
            <SubmitButton>{tx('modals.internship.confirmButton')}</SubmitButton>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

interface ActionsProps {
  hasButtons: boolean;
  data?: Contract;
  reload: any;
}

const ActionsButtons: React.FC<ActionsProps> = ({ hasButtons, data, reload }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [showTransfer, setShowTransfer] = useState<boolean>(false);
  const [showVacation, setShowVacation] = useState<boolean>(false);
  const [showExtension, setShowExtension] = useState<boolean>(false);
  const [showTermination, setShowTermination] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [showInternship, setShowInternship] = useState<boolean>(false);

  const [successMessage, setSuccessMessage] = useState<string>('');
  const [pdfData, setPdfData] = useState<string | ArrayBuffer | null>(null);

  const { state } = useProjectEditor();
  const history = useHistory();
  const request = useRequest();

  const { tx } = useTranslationX('viewPerson', 'project');

  const hasVacations = data?.ferias.hasVacation;
  const isInVacation = data?.ferias.isInVacation;
  const isInProrrogacao = data?.prorrogacao.isInExtension;
  const isInTransfer = data?.transferencia.isInTransfer;
  const isInTermination = data?.desligamento.isInTermination;
  const isCLT = data?.projetoRH.vinculoTrabalho === 2;
  const isInternship = data?.projetoRH.vinculoTrabalho === 4;

  const vacation = () => {
    setShowVacation(false);
    reload();
  };

  const extension = () => {
    setShowExtension(false);
    reload();
  };

  const confirmTermination = () => {
    setShowTermination(false);
    reload();
  };

  const downloadOffices = (solicitationId: number | undefined) => {
    let url =
      isCLT && isInTermination
        ? `projeto/${state.id}/recursos-humanos/oficio/${state.contract?.projetoRHId}/${solicitationId}`
        : `/projeto/${state.id}/execucao/${
            state.section ? state.section : 'servicos'
          }/solicitacao/${solicitationId}/pdf`;

    if (data?.projetoRH.estado === 'ECT') {
      url = `/projeto/${state.id}/recursos-humanos/termo-estagiario/${data?.projetoRH.id}/${solicitationId}`;
    }

    request<any>({
      url,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
      onSuccess: (blob: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          let base64data = reader.result;
          setPdfData(base64data);
        };
        reload();
        setIsVisible(false);
      },
      onError: data => console.error(data),
    });
  };

  const cancelSolicitation = (notificationId: number | undefined, tipo: string) => {
    const formData = new FormData();
    formData.append('notificationId', String(notificationId));
    formData.append('tipo', tipo);
    formData.append('accept', String(false));
    formData.append('isCancel', String(true));
    request<any>({
      url: `/homologacao/cancelar`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
      onSuccess: () => {
        reload();
      },
      onError: data => console.error(data),
    });
  };

  const handleTermination = () => {
    if (data?.projetoRH.vinculo === 'BOLSISTA' && data?.projetoRH.perfil === 'aluno') {
      history.push(history.location.pathname + '/desligamento');
    } else {
      setShowTermination(true);
    }
  };

  const resendSelfEvaluation = () => {
    request<Pageable<QuestionType>>({
      url: `bolsista/avaliacao/reenviar/${data?.desligamento.terminationSolicitationId}`,
      method: 'post',
      data: { state },
      onSuccess: () => {
        setSuccessMessage('modals.successMessage.selfEvaluation');
        setShowSuccessMessage(true);
      },
    });
  };

  const getTransfer = () => {
    if (isInTransfer) {
      return (
        <Button
          style={{
            root: {
              background: '#E61616',
              color: '#fff',
            },
            text: { fontSize: '14px', fontFamily: 'Roboto', fontWeight: 500 },
          }}
          icon={X}
          theme="light"
          onClick={() =>
            cancelSolicitation(
              data?.transferencia.transferSolicitationId,
              'transferencia'
            )
          }
        >
          {tx('buttons.cancelTransfer')}
        </Button>
      );
    }
    return (
      <Button
        style={{
          root: {
            marginLeft: 13,
          },
          text: { fontFamily: 'Roboto', fontWeight: 500 },
        }}
        icon={MdOutlineDriveFolderUpload}
        theme="light"
        onClick={() => setShowTransfer(true)}
      >
        {tx('buttons.transfer')}
      </Button>
    );
  };

  const getProrogation = () => {
    if ((state?.item as any)?.tipo.includes('CLT')) return null;
    if (isInProrrogacao)
      return (
        <ButtonCancel
          mainAction={() => downloadOffices(data?.prorrogacao.extensionSolicitationId)}
          secondAction={() =>
            cancelSolicitation(data?.prorrogacao.extensionSolicitationId, 'prorrogacao')
          }
          text={{
            textButton: tx('buttons.downloadExtensionDocument'),
            textCancel: tx('buttons.cancelExtension'),
          }}
        />
      );
    return (
      Number(data?.projetoRH?.duracao) > Number(data?.bolsa?.duracao) && (
        <Button
          style={{
            root: {
              marginLeft: 13,
              minWidth: 215,
            },
            text: { fontFamily: 'Roboto', fontWeight: 500 },
          }}
          icon={MdOutlineDriveFolderUpload}
          theme="light"
          onClick={() => setShowExtension(true)}
        >
          {tx('buttons.extension')}
        </Button>
      )
    );
  };

  const getActionButtons = () => {
    if (isInTermination) {
      if (
        !data?.desligamento.isStudentAndNotResponded &&
        !data?.desligamento.isSupervisorAnalysing
      ) {
        return (
          <ButtonCancel
            mainAction={() => setIsVisible(true)}
            secondAction={() =>
              cancelSolicitation(
                data?.desligamento.terminationSolicitationId,
                'desligamento'
              )
            }
            text={{
              textButton: tx('buttons.downloadTerminationDocument'),
              textCancel: tx('buttons.cancelTermination'),
            }}
          />
        );
      }
      if (data?.desligamento.isSupervisorAnalysing) {
        return (
          <div
            className={styles.cancelText}
            onClick={() =>
              cancelSolicitation(
                data?.desligamento.terminationSolicitationId,
                'desligamento'
              )
            }
          >
            <X size={1.5} />
            <span>{tx('buttons.cancelTermination')}</span>
          </div>
        );
      }
      return (
        <div>
          {data.projetoRH.vinculo !== 'ESTAGIARIO' && (
            <AlertLine text={tx('buttons.terminationFormSent')} />
          )}
          <Row width={data.projetoRH.vinculo === 'ESTAGIARIO' ? [3] : [2.5, 3]}>
            {data.projetoRH.vinculo !== 'ESTAGIARIO' ? (
              <Button
                style={{
                  root: {
                    background: '#E61616',
                    color: '#fff',
                  },
                  text: { fontSize: '14px', fontFamily: 'Roboto', fontWeight: 500 },
                }}
                icon={X}
                theme="light"
                onClick={() =>
                  cancelSolicitation(
                    data?.desligamento.terminationSolicitationId,
                    'desligamento'
                  )
                }
              >
                {tx('buttons.cancelTermination')}
              </Button>
            ) : (
              <ButtonCancel
                mainAction={() => setIsVisible(true)}
                secondAction={() =>
                  cancelSolicitation(
                    data?.desligamento.terminationSolicitationId,
                    'desligamento'
                  )
                }
                text={{
                  textButton: tx('buttons.downloadTerminationDocument'),
                  textCancel: tx('buttons.cancelTermination'),
                }}
              />
            )}
            {data.projetoRH.vinculo !== 'ESTAGIARIO' && (
              <Button
                style={{
                  root: {
                    background: '#fff',
                    color: '#333333',
                    minWidth: '100%',
                  },
                  text: { fontSize: '14px', fontFamily: 'Roboto', fontWeight: 500 },
                }}
                icon={RiMailSendLine}
                theme="light"
                onClick={() => resendSelfEvaluation()}
              >
                {tx('buttons.sendFormAgain')}
              </Button>
            )}
          </Row>
        </div>
      );
    }

    if (isInternship && data.projetoRH.estado === 'ECT') {
      return (
        <>
          {data.projetoRH.termoEstagioEnviado && (
            <Button
              icon={AiOutlineDownload}
              onClick={() => downloadOffices(data?.projetoRH.solicitacaoId)}
              theme="light"
            >
              {tx('buttons.downloadInternshipDocument')}
            </Button>
          )}
          <Button
            icon={AiOutlineUpload}
            onClick={() => setShowInternship(true)}
            theme="light"
          >
            {tx('buttons.sendInternshipDocument')}
          </Button>
        </>
      );
    }

    if (
      data?.projetoRH.estado === 'TER' ||
      data?.projetoRH.estado === 'FIN' ||
      data?.projetoRH.estado === 'INA'
    )
      return null;

    return (
      <>
        {getTransfer()}

        {hasVacations ? (
          isInVacation ? (
            <ButtonCancel
              mainAction={() => downloadOffices(data?.ferias.vacationSolicitationId)}
              secondAction={() =>
                cancelSolicitation(data?.ferias.vacationSolicitationId, 'ferias')
              }
              text={{
                textButton: tx('buttons.downloadVacationDocument'),
                textCancel: tx('buttons.cancelVacation'),
              }}
            />
          ) : (
            <Button
              style={{
                root: {
                  marginLeft: 13,
                  minWidth: 215,
                },
                text: { fontFamily: 'Roboto', fontWeight: 500 },
              }}
              icon={MdOutlineDriveFolderUpload}
              theme="light"
              onClick={() => {
                setShowVacation(true);
              }}
            >
              {tx('buttons.vacation')}
            </Button>
          )
        ) : null}

        {getProrogation()}

        <Button
          style={{
            root: {
              marginLeft: 13,
              minWidth: 215,
            },
            text: { fontFamily: 'Roboto', fontWeight: 500 },
          }}
          icon={MdOutlineDriveFolderUpload}
          theme="light"
          onClick={handleTermination}
        >
          {tx('buttons.termination')}
        </Button>
      </>
    );
  };

  const handleClose = () => {
    setPdfData(null);
  };

  return (
    <>
      <Modal
        visible={pdfData !== null}
        width="auto"
        onBlur={handleClose}
        onClose={handleClose}
      >
        <div className={styles.popup}>
          <ModalPDFViewer pdfData={pdfData} onClose={handleClose} title={tx('office')} />
        </div>
      </Modal>

      <ModalConfirmation
        visible={isVisible}
        mainAction={() => {
          setIsVisible(true);
          downloadOffices(data?.desligamento.terminationSolicitationId);
        }}
        setVisible={setIsVisible}
      />

      <ModalTermination
        visible={showTermination}
        data={data}
        mainAction={confirmTermination}
        setVisible={setShowTermination}
      />

      <ModalTransferPerson
        data={data}
        visible={showTransfer}
        setVisible={setShowTransfer}
        mainAction={() => null}
      />

      <ModalVacation
        visible={showVacation}
        setVisible={setShowVacation}
        mainAction={vacation}
      />

      <ModalExtension
        visible={showExtension}
        setVisible={setShowExtension}
        mainAction={extension}
        data={data}
      />

      <ModalInternship
        visible={showInternship}
        setVisible={setShowInternship}
        mainAction={() => {}}
        data={data}
      />

      {hasButtons && (
        <Grid className={styles.header} width="1500px" spacing={0}>
          <div style={{ display: 'flex' }}>{getActionButtons()}</div>
        </Grid>
      )}

      <InfoDialog
        visible={showSuccessMessage}
        title={tx('modals.successMessage.title')}
        buttonLabel={tx('buttons.successMessage')}
        message={tx(successMessage)}
        onClose={() => setShowSuccessMessage(false)}
      />
    </>
  );
};
export default ActionsButtons;
