import React from 'react';

const List = ({ size = 1 }: { size?: number }) => (
  <svg
    height={`${size}em`}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.25 10.625H3.75C3.41848 10.625 3.10054 10.7567 2.86612 10.9911C2.6317 11.2255 2.5 11.5435 2.5 11.875V15C2.5 15.3315 2.6317 15.6495 2.86612 15.8839C3.10054 16.1183 3.41848 16.25 3.75 16.25H16.25C16.5815 16.25 16.8995 16.1183 17.1339 15.8839C17.3683 15.6495 17.5 15.3315 17.5 15V11.875C17.5 11.5435 17.3683 11.2255 17.1339 10.9911C16.8995 10.7567 16.5815 10.625 16.25 10.625ZM16.25 15H3.75V11.875H16.25V15ZM16.25 3.75H3.75C3.41848 3.75 3.10054 3.8817 2.86612 4.11612C2.6317 4.35054 2.5 4.66848 2.5 5V8.125C2.5 8.45652 2.6317 8.77446 2.86612 9.00888C3.10054 9.2433 3.41848 9.375 3.75 9.375H16.25C16.5815 9.375 16.8995 9.2433 17.1339 9.00888C17.3683 8.77446 17.5 8.45652 17.5 8.125V5C17.5 4.66848 17.3683 4.35054 17.1339 4.11612C16.8995 3.8817 16.5815 3.75 16.25 3.75ZM16.25 8.125H3.75V5H16.25V8.125Z"
      fill="currentColor"
    />
  </svg>
);

export default List;
