import React from 'react';
import _ from 'lodash';
import { usePaymentContext } from '../../PaymentContext';
import { Payment, PaymentContract, paymentStatusColors } from '../../types';
import { CheckBox as CheckIcon, CheckBoxFrame as UncheckIcon } from 'components/icons';
import { classes } from 'utils/components';

import Tippy from '@tippyjs/react';

import styles from './PaymentNode.module.scss';
import { useTranslationX } from 'i18n';

type Props = {
  payment: Payment;
  contract: PaymentContract;
  date?: Date;
};

const PaymentNode: React.FC<Props> = ({ payment, contract, date }) => {
  const { id, estado } = payment;
  const { payments, addPayment, removePayment, vinculos, updateVinculos } =
    usePaymentContext();

  const { tx } = useTranslationX('paymentPanel', 'project');

  const isSelected = _.some(payments, { id: id });

  const getCheckBox = () => {
    if (isSelected) {
      return <CheckIcon size={1.2} />;
    } else {
      return <UncheckIcon size={1.2} />;
    }
  };

  const togglePayment = () => {
    if (isSelected) {
      removePayment(payment.id);
      updateVinculos('');
    } else {
      if (vinculos.length === 0) {
        addPayment(payment);
        updateVinculos(contract.vinculo);
      }

      if (vinculos.length > 0 && vinculos === contract.vinculo) {
        addPayment(payment);
        updateVinculos(contract.vinculo);
      }
    }
  };

  const nodeControl: { clickable: boolean; reason?: string } = (() => {
    if (
      !(
        ['LAT', 'PLA'].includes(estado) &&
        ['CON', 'TER', 'TRA', 'FIN', 'INA'].includes(contract.estado)
      )
    ) {
      return { clickable: false };
    }

    if (payment.alteracao_pt_block) {
      return { clickable: false, reason: tx('reasons.updateWp') };
    }
    if (contract.vinculo === 'CELETISTA') {
      return { clickable: false, reason: tx('reasons.CLT') };
    }
    if (payments.length > 0 && vinculos.length > 0 && vinculos !== contract.vinculo) {
      return { clickable: false };
    }
    if (estado === 'PLA' && date) {
      const today = new Date();
      if (date > today) {
        return { clickable: false };
      }

      const paymentDate = new Date(date);
      paymentDate.setDate(16);
      if (today < paymentDate && today.getMonth() !== 11) {
        return { clickable: false, reason: tx('reasons.month') };
      }
    }
    if (payments.length === 0) {
      return { clickable: true };
    } else {
      const firstPayment = payments[0];
      const isClickable = firstPayment.mes === payment.mes;
      return {
        clickable: isClickable,
        reason: !isClickable ? tx('sameMonth') : undefined,
      };
    }
  })();

  const { clickable, reason } = nodeControl;

  return (
    <Tippy
      content={reason}
      className={styles.popup}
      animation="perspective"
      touch={false}
      followCursor="horizontal"
      disabled={clickable || !reason}
    >
      <div
        className={classes(styles.paymentNode, clickable ? styles.clickable : '')}
        style={{
          backgroundColor: paymentStatusColors[estado],
        }}
        onClick={clickable ? togglePayment : undefined}
      >
        <div className={classes(styles.checkbox, isSelected ? styles.selected : '')}>
          {getCheckBox()}
        </div>
      </div>
    </Tippy>
  );
};

export default PaymentNode;
