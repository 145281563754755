import React, { useEffect, useRef } from 'react';

import { useTranslationX } from 'i18n';

import { TableList } from 'components/containers';
import { ColumnConfig } from 'components/containers/TableList/types';
import { Instance } from 'components/containers/TableList/TableList';
import {
  ProjectCounterpartRequestTypes,
  projectCounterpartRequestTypesColors,
  ProjectRequestStatus,
  projectRequestStatusColors,
} from 'pages/project/types';
import { useParams } from 'react-router-dom';
import { ActionCounterpart, CounterpartState } from '../Dashboard/types';

type Item = {
  id: number;
  rownum: number;
  nome: string;
  email: string;
  lattes: string;
  titulacao: string;
  area_formacao: string | undefined;
  area_pesquisa: string;
  titulacao_id: number;
  categoria: string;
  estado: string;
};

const CounterpartSolicitationList = ({
  state,
}: {
  state: CounterpartState;
  dispatch: React.Dispatch<ActionCounterpart>;
}) => {
  const { tx } = useTranslationX('contrapartida', 'project');

  const table = useRef<Instance>();

  const { id } = useParams<{ id: string }>();

  const config: ColumnConfig = [
    {
      title: tx('solicitations.headers.identification'),
      field: 'numero',
      weight: 0.3,
      type: 'html',
      align: 'left',
      search: true,
      parse: value => `<strong>${value}</strong>`,
    },
    {
      title: tx('solicitations.headers.title'),
      field: 'titulo',
      weight: 0.3,
      type: 'html',
      align: 'left',
      search: true,
      parse: value => `<strong>${value}</strong>`,
    },
    {
      title: tx('solicitations.headers.type'),
      field: 'tipo',
      type: 'tag',
      weight: 0.15,
      search: true,
      metadata: {
        options: Object.keys(projectCounterpartRequestTypesColors).map(status => {
          return {
            value: status,
            text: tx(status),
          };
        }),
      },
      searchType: 'status',
      parse: status => {
        return {
          value: tx(status),
          bgColor:
            projectCounterpartRequestTypesColors[
              status as ProjectCounterpartRequestTypes
            ],
        };
      },
    },
    {
      title: tx('solicitations.headers.date'),
      field: 'data',
      weight: 0.2,
      search: true,
      type: 'date',
      searchType: 'date',
      align: 'center',
    },
    {
      title: tx('solicitations.headers.status'),
      field: 'estado',
      type: 'tag',
      weight: 0.15,
      search: true,
      metadata: {
        options: Object.keys(projectRequestStatusColors).map(status => {
          return {
            value: status,
            text: tx(`status.${status}`),
          };
        }),
      },
      searchType: 'status',
      parse: status => {
        return {
          value: tx(`status.${status}`),
          bgColor: projectRequestStatusColors[status as ProjectRequestStatus],
        };
      },
    },
  ];

  useEffect(() => {
    if (!state.lastUpdate) return;
    table.current?.reload();
  }, [state.lastUpdate]);

  return (
    <>
      <div style={{ width: '100%' }}>
        <TableList<Item>
          baseUrl={`/projeto/${id}/contrapartidas/solicitacoes`}
          listHeader={{
            title: tx('solicitations.title'),
            position: 'inside',
          }}
          style={{
            root: {
              maxWidth: 'calc(100% - 20%)',
              maxHeight: 'calc(100vh - 150px)',
            },
          }}
          textSize="small"
          theme="light"
          config={config}
          reference={table}
          keyComposer={(item, index) => `${item.id}-${index}`}
          editIcon={null}
          refreshesOn={95}
          pageSize={5}
          notFoundProps={{}}
          filter
          defaultSearchProps={{
            sort: 'data,desc',
            filters: {},
          }}
        />
      </div>
    </>
  );
};

export default CounterpartSolicitationList;
