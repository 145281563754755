import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { useTranslationX } from 'i18n';
import { useRequest } from 'apis';
import TableList, { ListType } from 'components/containers/TableList/TableList';
import { Button, Loader, NotFoundMessage } from 'components/elements';
import { ColumnConfig } from 'components/containers/TableList/types';
import { formatDateToBrazilian, removeLeadingZeros } from 'utils/stringUtils';
import { Pageable } from 'components/form/types';
import { messageService } from 'services';
import { SuccessDialog } from 'components/containers';
import { Account, ImportacaoExtrato } from './ImportReviewAndAlerts';

const ImportAlerts = () => {
  const { tx } = useTranslationX('importReviewAndAlerts', 'finance');

  const { tx: tx2 } = useTranslationX('expenses', 'finance');
  const [visible, setVisible] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<Account[]>();
  const history = useHistory();
  const request = useRequest();

  useEffect(() => {
    const source = request<Pageable<any>>({
      url: '/financeiro/importacao/contas/problemas',
      method: 'POST',
      data: { size: 50 },
      onSuccess: response => {
        setAccounts(response.content);
      },
    });

    return () => source.cancel();
  }, [request]);

  const cancelImport = () => {
    const source = request<Pageable<any>>({
      url: '/financeiro/importacao/cancelar',
      method: 'PUT',
      onError: () => {
        messageService.error(tx('importError'), {
          duration: 2000,
        });
      },
      onSuccess: () => {
        history.push(`/dashboard/financeiro/dashboard`);
      },
    });

    return () => source.cancel();
  };

  const confirmImport = () => {
    const source = request<Pageable<any>>({
      url: '/financeiro/importacao/confirmar',
      method: 'PUT',
      onError: () => {
        messageService.error(tx('importError'), {
          duration: 2000,
        });
      },
      onSuccess: () => {
        setVisible(true);
      },
    });

    return () => source.cancel();
  };

  const getTableConfig = (motherAccount: boolean): ColumnConfig<ListType> => {
    return [
      {
        weight: 0.7,
        title: tx('fields.arquivo'),
        field: 'arquivo',
        align: 'left',
        search: true,
      },
      {
        weight: 0.7,
        title: tx('fields.fonte'),
        field: motherAccount ? 'fonteContaMae' : 'fonteRecurso',
        align: 'center',
        search: true,
      },
      {
        weight: 0.7,
        title: tx('fields.periodo'),
        field: 'dataInicio',
        align: 'center',
        type: 'date',
        searchType: 'date',
        search: true,
        parse: (_, data: ImportacaoExtrato) =>
          `${formatDateToBrazilian(data.dataInicio, false)} a ${formatDateToBrazilian(
            data.dataFim,
            false
          )}`,
      },
      {
        weight: 0.7,
        title: tx('fields.contemplado'),
        field: 'periodoContemplado',
        align: 'center',
        type: 'date',
        searchType: 'date',
        search: true,
      },
      {
        weight: 0.7,
        title: tx('fields.naoContemplado'),
        field: 'periodoNaoContemplado',
        align: 'center',
        type: 'date',
        searchType: 'date',
        search: true,
      },
      {
        weight: 0.7,
        title: tx('fields.importados'),
        field: 'lancamentoValidos',
        align: 'right',
        searchType: 'range',
        search: true,
      },
      {
        weight: 0.7,
        title: tx('fields.naoImportados'),
        field: 'lancamentosNaoImportados',
        align: 'right',
        searchType: 'range',
        search: true,
      },
    ];
  };

  const isLoading = accounts === undefined;
  if (isLoading) {
    return <Loader />;
  }

  const isEmpty = accounts?.length === 0;

  return (
    <>
      {!isEmpty ? (
        accounts.map(account => {
          return (
            <TableList<ImportacaoExtrato>
              baseUrl={`/financeiro/importacao/importacoes`}
              listHeader={{
                title: !account.ehContaMae
                  ? tx2('identification', {
                      number: account?.numeroProjeto ?? '',
                      sigla: account?.sigla,
                    })
                  : account.nomeContaMae,
                subtitle: tx2('accountSubtitle', {
                  ...account,
                }),
                position: 'inside',
              }}
              config={getTableConfig(account.ehContaMae)}
              textSize="small"
              theme="light"
              editIcon={null}
              refreshesOn={95}
              notFoundProps={{}}
              filter
              filterFixed={{
                estado: ['PRO'],
                motivoFalha: ['PNC', 'IPPLS'],
                banco: removeLeadingZeros(account.banco),
                agencia: removeLeadingZeros(account.agencia),
                conta: removeLeadingZeros(account.conta),
              }}
              infinityScroll
              key={account.id}
            />
          );
        })
      ) : (
        <NotFoundMessage
          title={tx('notFound.title')}
          description={tx('notFound.description')}
        />
      )}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button type="secondary" onClick={cancelImport}>
          {tx('actions.cancel')}
        </Button>
        <Button onClick={confirmImport}>{tx('actions.confirm')}</Button>
      </div>

      <SuccessDialog
        visible={visible}
        buttonLabel={tx('actions.goToDashboard')}
        onClose={() => history.push(`/dashboard/financeiro/dashboard`)}
      />
    </>
  );
};

export default ImportAlerts;
