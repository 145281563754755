import React, { useCallback, useState } from 'react';
import { BiListCheck } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';

import { useTranslationX, useTranslation } from 'i18n';
import { Dialog, Modal, ScrollPanel } from 'components/containers';
import { RequestConfig, useFetch, useRequest } from 'apis';
import {
  BadgeMessage,
  Button,
  FileUploader,
  IconButton,
  Loader,
  WrapperCard,
} from 'components/elements';
import { XRect, PDF, XAlt, DocumentFile, Replay } from 'components/icons';
import { getMasked } from 'utils/inputMask';
import { messageService } from 'services';
import { appendDots } from 'utils/stringUtils';
import { classes } from 'utils/components';
import { Pageable } from 'components/form/types';

import styles from './Dashboard.module.scss';
import { fromSQLTimestampToBrazilian } from 'utils/calendarUtils';

export type Period = {
  conta: string;
  banco: string;
  agencia: string;
  dataInicio: Date;
  dataFim: Date;
  identificacao: string;
};

export type DashboardData = {
  qtdDespesasNaoConciliadas: number;
  totalDespesasNaoConciliadas: number;
  qtdLancamentosNaoConciliados: number;
  totalLancamentosNaoConciliados: number;
  qtdPeriodosNaoContemplados: number;
  periodosNaoContemplados: Period[];
  qtdAportesNecessarios: number;
  totalAportesNecessarios: number;
  aportesNecessarios: any[];
};

export type ImportedArchive = {
  file: File;
  type: 'success' | 'error';
  id?: number;
  estado: string;
  motivoFalha: string;
  erro?: string;
};

const ARCHIVES_LIMIT = 50;

const Dashboard: React.FC = () => {
  const { tx } = useTranslationX('dashboard', 'finance');
  const { t } = useTranslation();
  const history = useHistory();
  const request = useRequest();

  const { data } = useFetch<DashboardData>('/financeiro/dashboard');
  const [showImport, setShowImport] = useState<boolean>(false);
  const [showCheckImport, setShowCheckImport] = useState<boolean>(false);
  const [importedArchives, setImportedArchives] = useState<ImportedArchive[]>([]);
  const [loading, setLoading] = useState<boolean | number>(false);

  const {
    qtdDespesasNaoConciliadas = 0,
    totalDespesasNaoConciliadas = 0,
    qtdLancamentosNaoConciliados = 0,
    totalLancamentosNaoConciliados = 0,
    qtdPeriodosNaoContemplados = 0,
    periodosNaoContemplados = [],
    qtdAportesNecessarios = 0,
    totalAportesNecessarios = 0,
  } = data ?? {};

  const formatToMoney = (value: number): string =>
    getMasked(value.toFixed(2), { pattern: 'currency' });

  const openImport = (): void => setShowImport(true);

  const closeImport = (): void => {
    setShowImport(false);
    setImportedArchives([]);
  };

  const handleRequest = useCallback(
    <T,>(config: RequestConfig<T>): Promise<T> =>
      new Promise<T>((resolve, reject) => {
        const source = request<T>({
          ...config,
          onError: err => {
            messageService.error(tx('importError'), { duration: 2000 });
            config.onError?.(err);
            reject(new Error('Import error'));
          },
          onSuccess: (response: T) => {
            config.onSuccess?.(response);
            resolve(response);
          },
        });

        return () => source.cancel();
      }),
    [request, tx]
  );

  const cancelImport = useCallback(
    (open = false): (() => void) => {
      const source = request<Pageable<any>>({
        url: '/financeiro/importacao/cancelar',
        method: 'PUT',
        onError: () => {
          messageService.error(tx('importError'), { duration: 2000 });
        },
        onSuccess: () => {
          closeImport();
          if (open) {
            setShowCheckImport(false);
            openImport();
          }
        },
      });
      return () => source.cancel();
    },
    [request, tx]
  );

  const removeArchive = useCallback(
    (id: number): (() => void) => {
      const source = request({
        url: `/financeiro/importacao/${id}/cancelar`,
        method: 'PUT',
        headers: { 'Content-Type': 'multipart/form-data' },
        onError: () => messageService.error(tx('importError'), { duration: 2000 }),
        onSuccess: () => {
          setImportedArchives(archives => archives.filter(file => file.id !== id));
        },
      });
      return () => source.cancel();
    },
    [request, tx]
  );

  const uploadArchives = useCallback(
    (formData: FormData): (() => void) => {
      const files = formData.getAll('files') as File[];

      const source = request<
        { id: number; motivoFalha: string; estado: string; erro?: string }[]
      >({
        url: '/financeiro/importacao',
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
        onError: error => {
          const response = error.response?.data[0];
          messageService.error(tx('importError'), { duration: 2000 });
          files.forEach(file => {
            if (!importedArchives.find(archive => archive.file === file)) {
              setImportedArchives(archives => [
                ...archives,
                {
                  file,
                  type: 'error',
                  motivoFalha: 'response.motivoFalha',
                  erro: response?.erro,
                  estado: 'PRO',
                },
              ]);
            }
          });
          setLoading(false);
        },
        onSuccess: responses => {
          const hasError = responses.some(response => response.erro !== undefined);
          if (hasError) {
            messageService.warning(tx('warning'), { duration: 2000 });
          }
          setImportedArchives(archives =>
            archives.concat(
              responses.map((response, index) => ({
                file: files[index],
                type: response.id ? 'success' : 'error',
                id: response.id,
                erro: response.erro,
                motivoFalha: response.motivoFalha,
                estado: response.estado,
              }))
            )
          );
          setLoading(false);
        },
      });
      return () => source.cancel();
    },
    [importedArchives, request, tx]
  );

  const importArchives = useCallback(
    (files: File[]): void => {
      const isOverLimit = importedArchives.length + files.length > ARCHIVES_LIMIT;
      if (isOverLimit) {
        messageService.error(tx('maxLimitReach'));
        return;
      }

      const formData = new FormData();
      files
        .filter(f => !importedArchives.find(iA => iA.file === f))
        .forEach(file => formData.append('files', file));

      setLoading(true);

      uploadArchives(formData);
    },
    [importedArchives, uploadArchives, tx]
  );

  const getFileAction = useCallback(
    (file: ImportedArchive, index: number): void => {
      if (file.type === 'success' && file.id) {
        removeArchive(file.id);
        return;
      }
      const formData = new FormData();
      formData.append('files', file.file);
      setLoading(index);
      uploadArchives(formData);
    },
    [removeArchive, uploadArchives]
  );

  const onConfirm = useCallback((): void => {
    history.push('/dashboard/financeiro/revisao');
  }, [history]);

  const checkImport = useCallback((): Promise<void> => {
    return handleRequest<Pageable<any>>({
      url: '/financeiro/importacao/importacoes',
      method: 'POST',
      data: { estado: ['UPD', 'PRO'] },
      onSuccess: response => {
        if (response?.content?.length === 0) {
          openImport();
        } else {
          setImportedArchives(
            response.content.map((i: any) => ({
              ...i,
              type: 'success',
              file: { name: i.arquivo } as unknown as File,
            }))
          );
          setShowCheckImport(true);
        }
      },
    }).then(() => void 0);
  }, [handleRequest]);

  const fiftyGBSize = 5242880 * 10;
  const Modals: React.FC = () => (
    <>
      <Dialog
        title={tx('import')}
        visible={showCheckImport}
        onClose={() => setShowCheckImport(false)}
        closeButton
        width={800}
        actions={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              icon={XRect}
              iconProps={{ size: 32 }}
              theme="light"
              onClick={() => cancelImport(true)}
            >
              {tx('checkRegisterCancel')}
            </Button>
            <Button
              icon={BiListCheck}
              iconProps={{ size: 32 }}
              theme="light"
              type="secondary"
              onClick={() => {
                setShowCheckImport(false);
                openImport();
              }}
            >
              {tx('checkRegisterContinue')}
            </Button>
          </div>
        }
      >
        <WrapperCard maxWidth="100%" padding="30px 32px">
          <p className={styles.dialogText}>{tx('checkRegister')}</p>
        </WrapperCard>
      </Dialog>
    </>
  );

  if (!data) {
    return <Loader />;
  }

  return (
    <>
      <Dialog
        title={tx('import')}
        visible={showImport}
        onClose={closeImport}
        closeButton
        width={800}
        actions={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="secondary" onClick={() => cancelImport()}>
              {tx('cancel')}
            </Button>
            <Button
              type={importedArchives.length > 0 ? undefined : 'disabled'}
              onClick={onConfirm}
            >
              {tx('confirm')}
            </Button>
          </div>
        }
      >
        <WrapperCard maxWidth="100%" padding="30px 32px">
          <Modal visible={loading === true} minWidth={false} delay={500}>
            <Loader
              type="grid"
              delay={false}
              message={t(`components.form.async.submitting`, { defaultValue: ' ' })}
            />
          </Modal>

          {importedArchives.length < ARCHIVES_LIMIT && (
            <>
              <FileUploader
                message={tx('linkArchive')}
                label={tx('importLabel')}
                metadata={{
                  rules: {
                    accept: ['ofx'],
                    maxfiles: ARCHIVES_LIMIT,
                    filesize: fiftyGBSize,
                  },
                }}
                onFileUpload={importArchives}
                type="sneaky"
                showUploaded={false}
                clearAfterUpload
              />
              <span className={styles.importLimit}>{tx('importLimit')}</span>
            </>
          )}

          <div className={styles.importList}>
            <span className={styles.listTitle}>
              {tx('archives')}
              <span className={styles.listEnum}>
                {tx('currentArchives', { current: importedArchives.length })}
              </span>
            </span>
            <ScrollPanel
              style={{
                content: {
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '17px',
                },
              }}
              hBar={{ visible: false }}
            >
              {importedArchives.map((file, index) => (
                <div key={index} className={styles.uploadedItem}>
                  <div className={styles.uploadedItem}>
                    <Tippy
                      content={file.erro}
                      className={styles.popup}
                      animation="perspective"
                      touch={false}
                      followCursor="horizontal"
                      disabled={file.erro === undefined}
                    >
                      <div className={classes(styles.icon, styles[file.type])}>
                        <DocumentFile />
                      </div>
                    </Tippy>

                    <Tippy
                      content={file.file.name}
                      className={styles.popup}
                      animation="perspective"
                      touch={false}
                      followCursor="horizontal"
                      plugins={[followCursor]}
                    >
                      <span>{appendDots(file.file.name, 30)}</span>
                    </Tippy>

                    <IconButton
                      icon={file.type === 'success' ? XAlt : Replay}
                      color="#BF0003"
                      size={25}
                      onClick={() => getFileAction(file, index)}
                    />
                  </div>
                </div>
              ))}
            </ScrollPanel>
          </div>
        </WrapperCard>
      </Dialog>
      <Modals />
      <ScrollPanel vBar={{ overlay: true }}>
        <div className={styles.container}>
          <div className={styles.buttons}>
            <Button
              icon={PDF}
              iconProps={{ size: 1.2 }}
              theme="light"
              onClick={checkImport}
            >
              {tx('actionButtons.import')}
            </Button>

            <Button
              icon={BiListCheck}
              iconProps={{ size: 32 }}
              theme="light"
              onClick={() => history.push('/dashboard/financeiro/prestacao-contas')}
            >
              {tx('actionButtons.generateAccountability')}
            </Button>
            <Button
              icon={BiListCheck}
              theme="light"
              iconProps={{ size: 32 }}
              onClick={() => history.push('/dashboard/financeiro/conciliacao')}
            >
              {tx('actionButtons.reconciliation')}
            </Button>
            <Button
              icon={BiListCheck}
              iconProps={{ size: 32 }}
              theme="light"
              onClick={() => history.push('/dashboard/financeiro/contas-mae')}
            >
              {tx('actionButtons.motherAccounts')}
            </Button>
          </div>
          <div className={styles.dataContainer}>
            <div className={styles.cards}>
              <div className={styles.card}>
                <h3 className={styles.cardTitle}>{tx('cards.expenses')}</h3>
                <p className={styles.cardText}>
                  <span className={styles.cardTextMain}>{qtdDespesasNaoConciliadas}</span>
                  <span className={styles.cardTextSecondary}>
                    {`${t('currency')} ${formatToMoney(totalDespesasNaoConciliadas)}`}
                  </span>
                </p>
              </div>
              <div className={styles.card}>
                <h3 className={styles.cardTitle}>{tx('cards.launch')}</h3>
                <p className={styles.cardText}>
                  <span className={styles.cardTextMain}>
                    {qtdLancamentosNaoConciliados}
                  </span>
                  <span className={styles.cardTextSecondary}>
                    {`${t('currency')} ${formatToMoney(totalLancamentosNaoConciliados)}`}
                  </span>
                </p>
              </div>

              <div className={styles.card}>
                <h3 className={styles.cardTitle}>{tx('cards.nonCovered')}</h3>
                <p className={styles.cardText}>
                  <span className={styles.cardTextMain}>
                    {qtdPeriodosNaoContemplados}
                  </span>
                </p>
              </div>

              <div className={styles.card}>
                <h3 className={styles.cardTitle}>{tx('cards.necessaryContribution')}</h3>
                <p className={styles.cardText}>
                  <span className={styles.cardTextMain}>{qtdAportesNecessarios}</span>
                  <span className={styles.cardTextSecondary}>
                    {`${t('currency')} ${formatToMoney(totalAportesNecessarios)}`}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className={styles.alerts}>
            <div className={styles.alertContainer}>
              <div className={styles.alertContainerTitle}>
                <p>{tx('necessaryContribution.title')}</p>
              </div>
            </div>
            <div className={styles.alertContainer}>
              <div className={styles.alertContainerTitle}>
                <p>{tx('nonCovered.title')}</p>
              </div>
              <ScrollPanel
                hBar={{
                  visible: false,
                }}
              >
                <div className={styles.alertContainerList}>
                  {periodosNaoContemplados.map((periodo, idx) => {
                    const bank = tx('nonCovered.bank', {
                      agencia: periodo.agencia,
                      banco: periodo.banco,
                      conta: periodo.conta,
                    });
                    const period = tx('nonCovered.period', {
                      inicio: fromSQLTimestampToBrazilian(periodo.dataInicio.toString()),
                      fim: fromSQLTimestampToBrazilian(periodo.dataFim.toString()),
                    });

                    return (
                      <BadgeMessage
                        key={idx}
                        type="warning"
                        text={`${periodo.identificacao}<br><br>${bank}<br><br>${period}`}
                      />
                    );
                  })}
                </div>
              </ScrollPanel>
            </div>
          </div>
        </div>
      </ScrollPanel>
    </>
  );
};

export default Dashboard;
