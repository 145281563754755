import React from 'react';

import { Grid, Row } from 'components/containers';
import { WrapperCard } from 'components/elements';
import {
  Form,
  RadioGroup,
  Select,
  TextArea,
  TextField,
  useFormFieldObserver,
} from 'components/form';
import { FormInstance } from 'components/form/types';
import { useTranslationX } from 'i18n';
import { useParams } from 'react-router-dom';

type Props = {
  onDone: VoidFunction;
  formRef: React.RefObject<FormInstance>;
};

const CreditObjectForm = ({ formRef, onDone }: Props) => {
  const { id } = useParams<{ id: string }>();

  const { tx } = useTranslationX('expenses', 'finance');

  const Content = () => {
    const tipoObserver = useFormFieldObserver('tipo');
    return (
      <Grid spacing={0}>
        <Row>
          <Select name="tipo" label={tx('type')} />
        </Row>
        <Row>
          <TextArea name="descricao" label={tx('description')} minRows={8} />
        </Row>
        {tipoObserver.value === 'MEX' ? (
          <Row>
            <RadioGroup
              name="origem"
              label={tx('origin')}
              columns={2}
              metadata={{
                rules: {
                  required: true,
                },
              }}
            />
          </Row>
        ) : null}
        <Row width={[0.5, 0.5]}>
          <TextField name="valor" label={tx('value')} />
        </Row>
      </Grid>
    );
  };

  return (
    <>
      <WrapperCard maxWidth="100%" padding="30px 32px">
        <Form
          baseUrl={`/financeiro/conciliacao/despesas/contas-projeto/${id}/credito-adicional`}
          ref={formRef}
          onDone={onDone}
          submit={{
            method: 'post',
          }}
        >
          <Content />
        </Form>
      </WrapperCard>
    </>
  );
};

export default CreditObjectForm;
