import React, {
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { TableList } from 'components/containers';
import { Instance, ListType } from 'components/containers/TableList/TableList';
import { ColumnConfig } from 'components/containers/TableList/types';
import { useTranslationX } from 'i18n';
import {
  FormInstance,
  FormObserverResult,
  PropObserverResult,
} from 'components/form/types';
import { messageService } from 'services';
import { RequestConfig, useRequest } from 'apis';
import { Form } from 'components/form';
import { useAsyncQueue } from 'components/hooks/useAsyncQueue';
import { BalanceStatus, balanceStatusColor } from 'pages/finance/types';

export type TransactionType = 'credito-adicional' | 'despesas-dr' | 'despesas-rh';

type TransactionListProps = {
  onRefresh: () => void;
  config: ColumnConfig<ListType>;
  formRef: RefObject<FormInstance>;
  baseUrl: string;
  tableRef: MutableRefObject<Instance | undefined>;
  listHeaderTitle: string;
  listHeaderSubTitle?: string;
  extraButtons?: any[];
  extraRender?: React.ReactNode;
  lancamento_id: number;
  tipo: TransactionType;
};

const TransactionList = (props: TransactionListProps) => {
  const {
    onRefresh,
    config,
    baseUrl,
    listHeaderTitle,
    listHeaderSubTitle,
    extraButtons,
    extraRender,
    formRef,
    tableRef,
    lancamento_id: id,
    tipo,
  } = props;

  const { tx } = useTranslationX('expenses', 'finance');

  const request = useRequest();

  const addToQueue = useAsyncQueue(onRefresh);

  const valueRef = useRef<{ [key: string]: number }>({});

  const handleRequest = useCallback(
    <T,>(config: RequestConfig<T>): Promise<T> =>
      new Promise<T>((resolve, reject) => {
        const source = request<T>({
          ...config,
          onError: err => {
            const { error } = err.response?.data as { error?: string };
            messageService.error(error ?? tx('error'), { duration: 2000 });
            config.onError?.(err);
            reject(new Error('Generic error'));
          },
          onSuccess: (response: T) => {
            config.onSuccess?.(response);
            resolve(response);
          },
        });
        return () => source.cancel();
      }),
    [request, tx]
  );

  const populateInRef = useCallback((data?: any[]) => {
    data?.forEach(item => {
      valueRef.current[item.id] = item.valor_conciliado;
    });
  }, []);

  const handleUpdateValorConciliado = useCallback(
    async (result: FormObserverResult) => {
      const tableInstance = tableRef.current;
      const extras = (result as PropObserverResult).value;
      const valor = extras.value as number;
      const match = extras.path.match(/\[(\d+)\]/);
      const index = Number(match ? match[1] : -1);
      if (tableInstance && index > -1 && valor > 0) {
        const data = tableInstance.getRowData(index) as any;
        const itemId = data.id;

        if (valor === valueRef.current[itemId]) return;

        const requestPath = data.eh_solicitacao
          ? `/financeiro/conciliacao/despesas/contas-projeto/solicitacao/${itemId}/lancamentos`
          : `/financeiro/conciliacao/despesas/contas-projeto/${tipo}/${itemId}/lancamentos`;

        addToQueue(async () => {
          try {
            valueRef.current[itemId] = valor;
            await handleRequest({
              url: requestPath,
              method: 'POST',
              data: {
                lancamentoId: Number(id),
                valor,
                tipo: tipo === 'despesas-rh' ? 'rh' : data.tipo,
              },
            });
          } catch (ignore) {}
        });
      }
    },
    [addToQueue, handleRequest, id, tableRef, tipo]
  );

  useEffect(() => {
    if (formRef && formRef.current) {
      const currentForm = formRef.current;
      currentForm.observer.subscribe(handleUpdateValorConciliado, { onSave: true });
      return () => currentForm.observer.unsubscribe(handleUpdateValorConciliado);
    }
  }, [formRef, handleUpdateValorConciliado]);

  const paintRow = (entry: any) => {
    if (entry.tem_vinculo) {
      return balanceStatusColor[entry.situacao as BalanceStatus];
    }
  };

  return (
    <>
      <Form autosave ref={formRef}>
        <TableList
          defaultSearchProps={{
            sort: 'tem_vinculo,desc',
            filters: {},
          }}
          onData={populateInRef}
          listHeader={{
            title: listHeaderTitle,
            subtitle: listHeaderSubTitle,
            position: 'inside',
          }}
          style={{
            root: {
              maxWidth: '100%',
              maxHeight: '70vh',
            },
          }}
          paintRow={paintRow}
          keyComposer={(item, index) => `${item.id}-${index}`}
          baseUrl={baseUrl.replace('{id}', String(id))}
          filter
          config={config}
          textSize="small"
          theme="light"
          infinityScroll
          refreshesOn={95}
          editIcon={null}
          notFoundProps={{}}
          reference={tableRef}
          extraButtons={extraButtons}
        />
      </Form>
      {extraRender}
    </>
  );
};

export default TransactionList;
