import React, { createRef, useEffect, useMemo, useState } from 'react';

import { useTranslationX } from 'i18n';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useFetch } from 'apis';
import { RouteParams, SourceDetail } from '../ConciliationSourceList/types';
import { Button, Loader } from 'components/elements';
import { useDynamicHeader } from 'components/containers/DynamicHeader';
import { ScrollPanel } from 'components/containers';

import { TopMenuItemIcon } from 'pages/dashboard/types';
import { ConfigTopPanel } from 'pages/dashboard';
import { Expenditure, OthersExpenditure } from 'components/icons';
import { DatePicker } from 'components/form';
import { Form } from 'components/form';
import { IntervalFilter } from 'components/inputs/types';
import {
  FieldObserverResult,
  FormInstance,
  FormObserverResult,
} from 'components/form/types';

import MotherCreditList from './MotherAccount/CreditList/CreditList';
import MotherDebitList from './MotherAccount/DebitList/DebitList';
import ProjectCreditList from './ProjectAccount/CreditList/CreditList';
import ProjectRHDebitList from './ProjectAccount/RHDebitList/RHDebitList';
import ProjectDRDebitList from './ProjectAccount/DRDebitList/DRDebitList';

import styles from './ConciliationExpensesReview.module.scss';

const ConciliationExpensesReview = () => {
  const { tx } = useTranslationX('expenses', 'finance');

  const history = useHistory();

  const location = useLocation();

  const { setBreadcrumbs, setFooter, setVisibility, clear } = useDynamicHeader();

  const { id, source } = useParams<RouteParams>();

  const detailUrl = useMemo(() => {
    if (source === 'contas-projeto') {
      return `/financeiro/conciliacao/fonte-recurso/${id}`;
    }
    return `/financeiro/conciliacao/contas-mae/${id}`;
  }, [id, source]);

  const handleBack = (): void => {
    const { pathname } = location;
    history.replace(pathname.split('/revisao')[0]);
  };

  const { data } = useFetch<SourceDetail>(detailUrl);

  useEffect(() => {
    if (!data) return;
    if (source === 'contas-projeto') {
      const { number, slug, sourceName, agency, account } = data;

      setBreadcrumbs([
        'finance',
        'expenses',
        `[${tx('identification', {
          sigla: slug,
          number,
        })}]`,
      ]);
      setFooter(
        `${sourceName} ${tx('agencyHeader', { value: agency })} ${tx('accountHeader', {
          value: account,
        })}`
      );
    } else if (source === 'contas-mae') {
      const { name, sourceName, agency, account } = data;
      setBreadcrumbs(['finance', 'expenses', `[${name}]`]);
      setFooter(
        `${sourceName} ${tx('agencyHeader', { value: agency })} ${tx('accountHeader', {
          value: account,
        })}`
      );
    }

    setVisibility(true);
    return () => {
      clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear, setBreadcrumbs, setVisibility, setFooter, data, source]);

  if (!data) {
    return <Loader />;
  }

  return (
    <ScrollPanel
      hBar={{
        visible: false,
      }}
      vBar={{
        visible: false,
      }}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.titles}>
            <h2>{tx('financial')}</h2>
            <h1>{tx('button.reviewConciliations')}</h1>
          </div>
          <Button onClick={handleBack}>{tx('button.conciliations')}</Button>
        </div>
        <div>
          <Sections
            items={
              source === 'contas-mae'
                ? [
                    {
                      key: 'creditos',
                      component: MotherCreditList,
                      icon: OthersExpenditure,
                      title: tx('expenseType.credito'),
                    },
                    {
                      key: 'debitos',
                      component: MotherDebitList,
                      icon: Expenditure,
                      title: tx('expenseType.debito'),
                    },
                  ]
                : [
                    {
                      key: 'recursos-humanos',
                      component: ProjectRHDebitList,
                      icon: Expenditure,
                      title: tx('rhExpense'),
                    },
                    {
                      key: 'demais-rubricas',
                      component: ProjectDRDebitList,
                      icon: Expenditure,
                      title: tx('drExpense'),
                    },
                    {
                      key: 'creditos',
                      component: ProjectCreditList,
                      icon: OthersExpenditure,
                      title: tx('expenseType.credito'),
                    },
                  ]
            }
          />
        </div>
      </div>
    </ScrollPanel>
  );
};

type SectionsProps = {
  items: TopMenuItemIcon[];
};

const Sections: React.FC<SectionsProps> = props => {
  const { items } = props;
  const { id } = useParams<RouteParams>();
  const { tx } = useTranslationX('expenses', 'finance');

  const [period, setPeriod] = useState<IntervalFilter | undefined>();

  const instance = createRef<FormInstance>();

  const handleChangeFilter = (result: FormObserverResult) => {
    const value = (result as FieldObserverResult).data?.value;
    setPeriod(value);
  };

  useEffect(() => {
    if (instance.current) {
      const currentForm = instance.current;
      currentForm?.observer.subscribe(handleChangeFilter, { field: 'data' });

      return () => {
        currentForm?.observer.unsubscribe(handleChangeFilter);
      };
    }
  }, [instance]);
  return (
    <>
      <ConfigTopPanel
        items={items}
        forwardProps={{
          data: period,
          id,
        }}
        extra={
          <div className={styles.filter}>
            <Form ref={instance}>
              <DatePicker name="data" range label={tx('period')} />
            </Form>
          </div>
        }
      />
    </>
  );
};

export default ConciliationExpensesReview;
