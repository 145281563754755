import React from 'react';

import { XRect, Check, WarningAlt } from 'components/icons';

import { classes } from 'utils/components';

import { Message } from 'store/message';
import styles from './BadgeMessage.module.scss';
import DOMPurify from 'dompurify';

const BadgeMessage: React.FC<Partial<Message>> = React.memo(({ type, text }) => {
  const renderIcon = (type: string) => {
    if (type === 'error') {
      return (
        <span className={styles.errorIcon}>
          <XRect />
        </span>
      );
    } else if (type === 'warning') {
      return (
        <span className={styles.warnIcon}>
          <WarningAlt />
        </span>
      );
    } else if (type === 'success') {
      return (
        <span className={styles.successIcon}>
          <Check />
        </span>
      );
    }
  };

  const classColor =
    type === 'warning'
      ? styles.warningColor
      : type === 'success'
      ? styles.successColor
      : styles.dangerColor;

  return (
    <div className={classes(styles.badgeMessage, classColor)}>
      {type !== undefined ? renderIcon(type) : null}
      {text ? (
        <p className={styles.content}>
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }} />
        </p>
      ) : null}
    </div>
  );
});

export default BadgeMessage;
