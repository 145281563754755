import React, { createRef, useCallback, useRef } from 'react';
import { useTranslation, useTranslationX } from 'i18n';
import { useParams } from 'react-router-dom';
import TransactionList from './TransactionList';
import { currencyDefault } from 'components/form/helpers';
import {
  renderColorizedStatus,
  renderColorizedValue,
  statusOptions,
} from 'pages/finance/utils';
import { BalanceStatus } from 'pages/finance/types';
import { useTransactionActions } from './useTransactionActions';
import { ColumnConfig } from 'components/containers/TableList/types';
import { Instance, ListType } from 'components/containers/TableList/TableList';
import { RouteParams } from 'pages/finance/ConciliationSourceList/types';
import { FormInstance } from 'components/form/types';

type Props = {
  onReload: () => void;
};

type Item = {
  id: number;
  origem: string;
  descricao: string;
  tipo: string;
  situacao: BalanceStatus;
  tem_vinculo: boolean;
  eh_solicitacao: boolean;
  valor: number;
  valor_conciliado: number;
  saldo_a_conciliar: number;
};

const DebitList: React.FC<Props> = props => {
  const { onReload } = props;
  const { lancamento_id: id } = useParams<RouteParams>();
  const { tx } = useTranslationX('expenses', 'finance');
  const { t } = useTranslation('translation');

  const table = useRef<Instance>();

  const formRef = createRef<FormInstance>();

  const handleRefresh = useCallback(() => {
    onReload();
    table.current?.resetAfterDelete();
  }, [onReload]);

  const transactionActions = useTransactionActions({
    onReload: handleRefresh,
    id: Number(id),
  });
  const getActions = useCallback(
    (data: Item, index: number) => transactionActions.getActions(data, index, formRef),
    [formRef, transactionActions]
  );

  const config: ColumnConfig<ListType> = [
    {
      title: tx('office'),
      field: 'num_oficio',
      align: 'center',
      weight: 0.13,
      search: true,
      appendDots: false,
    },
    {
      title: tx('projectIdentification'),
      field: 'identificacao_projeto',
      weight: 0.14,
      align: 'center',
      search: true,
      appendDots: false,
    },
    {
      title: tx('date'),
      field: 'data',
      weight: 0.09,
      align: 'center',
      search: true,
      type: 'date',
      sort: true,
      searchType: 'date',
    },
    {
      title: tx('projectAccountData'),
      field: 'conta_projeto',
      weight: 0.22,
      headerAlign: 'left',
      align: 'center',
      search: true,
      type: 'html',
      parse: data => `<strong>${data}d<strong/>`,
      appendDots: false,
    },
    {
      title: tx('situation'),
      field: 'situacao',
      weight: 0.09,
      type: 'html',
      search: true,
      searchType: 'status',
      metadata: {
        options: statusOptions(tx),
      },
      parse: renderColorizedStatus,
    },
    {
      title: tx('value'),
      field: 'valor',
      type: 'html',
      headerAlign: 'right',
      align: 'right',
      weight: 0.09,
      search: true,
      searchType: 'range',
      parse: (item: number, data: Item) => renderColorizedValue(item, data.situacao, t),
    },
    {
      title: tx('pendingBalance'),
      field: 'saldo_a_conciliar',
      search: true,
      weight: 0.1,
      appendDots: false,
      align: 'right',
      headerAlign: 'right',
      type: 'html',
      searchType: 'range',
      parse: (item: number, data: Item) => renderColorizedValue(item, data.situacao, t),
    },
    {
      title: tx('conciliatedValue'),
      field: 'valor_conciliado',
      search: true,
      weight: 0.1,
      type: 'input',
      appendDots: false,
      align: 'right',
      headerAlign: 'right',
      searchType: 'range',
      fieldProps: (item: Item) => {
        return {
          ...currencyDefault(
            Number(item.saldo_a_conciliar + item.valor_conciliado).toFixed(2)
          ),
          type: item.situacao === 'CCI' && !item.tem_vinculo ? 'readonly' : undefined,
        };
      },
    },
    {
      title: '',
      field: 'actions',
      weight: 0.05,
      type: 'actions',
      search: false,
      parse: (_, data: Item, index: number) => getActions(data, index),
    },
  ];

  return (
    <TransactionList
      onRefresh={handleRefresh}
      formRef={formRef}
      tableRef={table}
      config={config}
      baseUrl={`/financeiro/conciliacao/despesas/lancamentos/${id}/debitos`}
      listHeaderTitle={tx('expenseType.debito')}
    />
  );
};

export default DebitList;
