import Tippy from '@tippyjs/react';
import { Grid, Row } from 'components/containers';
import { KeyValueRow, WrapperCard } from 'components/elements';
import { CheckCircle } from 'components/icons';
import { useTranslation, useTranslationX } from 'i18n';
import { ProjectSourceItemDetail } from 'pages/finance/ConciliationSourceList/types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { classes, renderComponent } from 'utils/components';
import { getMasked } from 'utils/inputMask';

import styles from './SourceCard.module.scss';
import { appendDots } from 'utils/stringUtils';

type Props = {
  item: ProjectSourceItemDetail;
};
export const SourceCardItemDetail: React.FC<Props> = props => {
  const { item } = props;

  const { tx } = useTranslationX('expenses', 'finance');
  const { t } = useTranslation('translation');

  const history = useHistory();

  return (
    <WrapperCard minHeight="250px" minWidth="350px" padding="0px">
      <div className={styles.expensePart}>
        <p className={styles.title}>
          <Tippy
            content={item.name}
            animation="perspective"
            touch={false}
            className={styles.popup}
          >
            <span>{item.name}</span>
          </Tippy>
        </p>
        <Grid spacing={0}>
          <div className={styles.cardContent}>
            <Row>
              <KeyValueRow label={tx('gpf')} value={item.gpf} size="small" margin="0" />

              <Tippy
                content={item.bank}
                animation="perspective"
                touch={false}
                className={styles.popup}
              >
                <div>
                  <KeyValueRow
                    label={tx('bank')}
                    value={appendDots(item.bank, 12)}
                    margin="0"
                  />
                </div>
              </Tippy>
            </Row>
            <Row>
              <KeyValueRow
                label={tx('agency')}
                value={item.agency}
                size="small"
                margin="0"
              />

              <KeyValueRow label={tx('account')} value={item.account} margin="0" />
            </Row>

            <Row>
              <div className={classes(styles.tag, styles.credit)}>{tx('credit')}</div>
              <div className={classes(styles.tag, styles.debit)}>{tx('debit')}</div>
            </Row>

            <Row>
              <KeyValueRow
                label={tx('value')}
                value={`${t('currency')} ${getMasked((item.creditValue || 0).toFixed(2), {
                  pattern: 'currency',
                })}`}
                margin="0"
              />
              <KeyValueRow
                label={tx('value')}
                value={`${t('currency')} ${getMasked((item.debitValue || 0).toFixed(2), {
                  pattern: 'currency',
                })}`}
                margin="0"
              />
            </Row>
            <Row>
              <KeyValueRow
                label={tx('pending')}
                value={item.pendingCredit}
                size="small"
                margin="0"
              />
              <KeyValueRow label={tx('pending')} value={item.pendingDebit} margin="0" />
            </Row>
          </div>
        </Grid>
        <div className={styles.footer}>
          <div
            className={styles.order}
            onClick={() =>
              history.push(`/dashboard/financeiro/conciliacao/contas-projeto/${item.id}`)
            }
          >
            {renderComponent(CheckCircle, { size: 0.8 })}
            <span className={styles.label}>{tx('conciliar')}</span>
          </div>
        </div>
      </div>
    </WrapperCard>
  );
};

export default SourceCardItemDetail;
