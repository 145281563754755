import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useTranslationX } from 'i18n';
import { PlannerHeader, WrapperCard } from 'components/elements';
import { Form, Select } from 'components/form';
import { ActionFilter, StateFilter } from 'components/containers/TableList/types';
import { isNotDeepEquals, sanitizeObject } from 'utils/object';
import { ResponseExtras, useRequest } from 'apis';
import { FormInstance } from 'components/form/types';
import { Profile } from 'pages/register/profile';
import { useAuthState } from 'store/auth';
import { Instance } from '../ConclusionPanel/ConclusionPlanner/ConclusionPlanner';
import { ConclusionPlanner, DownloadCraftButton } from '../ConclusionPanel';
import { ActionButton, StateButton } from '../ConclusionPanel/types';
import { Declaration } from './Declaration';
import { ActionCounterpart, CounterpartState } from '../Dashboard/types';

import styles from './ScheduleList.module.scss';
import { downloadBlob } from 'utils/file';
import { useFirstRender } from 'components/hooks';

const reducer = (state: StateFilter, action: ActionFilter): StateFilter => {
  switch (action.type) {
    case 'FILTER_UPDATE':
      if (isNotDeepEquals(state.filters, action.payload)) {
        return state;
      }
      return {
        ...state,
        status: 'update',
        filters: sanitizeObject({ ...state.filters, ...action.payload }),
      };

    case 'FILTER_RESET':
      return { ...state, status: 'clean', filters: {} };
    case 'UPDATE_SORT':
      return { ...state, status: 'update', sort: action.payload };
    case 'FINISH_UPDATE':
      return { ...state, status: 'idle' };

    default:
      return state;
  }
};

const buttonsReducer = (state: StateButton, action: ActionButton): StateButton => {
  switch (action.type) {
    case 'ADD_CONCLUSION':
      return {
        ...state,
        conclusions: action.payload.conclusions,
        validDate: action.payload.validDate,
      };

    case 'REMOVE_CONCLUSION':
      return {
        ...state,
        conclusions: action.payload.conclusions,
        validDate: action.payload.validDate,
      };

    case 'SET_PENDING_CONCLUSIONS':
      return {
        ...state,
        pendingConclusions: action.payload.pendingConclusions,
      };

    default:
      return state;
  }
};

const ScheduleList = ({
  state,
  dispatch,
}: {
  state: CounterpartState;
  dispatch: React.Dispatch<ActionCounterpart>;
}) => {
  const { tx } = useTranslationX('counterpartPanel', 'project');
  const { profile } = useAuthState();
  const { id: projectId } = useParams<{ id: string; contrapartidaId: string }>();

  const request = useRequest();
  const form = React.createRef<FormInstance>();

  const table = useRef<Instance>();

  const [stateFilter, dispatchFilter] = useReducer(reducer, {
    status: 'init',
    filters: {},
    sort: 'name,asc',
  });

  const [buttonState, dispatchButton] = useReducer(buttonsReducer, {
    conclusions: [],
    validDate: [],
    pendingConclusions: [],
  });

  const [visible, setVisible] = useState(false);

  const firstRender = useFirstRender();

  const uniqueItem = buttonState.conclusions.length;
  const validDatesArray = buttonState.validDate.map(item => Object.values(item)[0]);
  const hideUploadButton = uniqueItem !== 1 || validDatesArray.includes(false);

  useEffect(() => {
    if (firstRender || !state.lastUpdate) return;
    table.current?.reload();
  }, [firstRender, state.lastUpdate]);

  const handleDownloadOficios = (isPending = false) => {
    const contracts = isPending
      ? buttonState.pendingConclusions
      : buttonState.conclusions;

    const params = {
      contratos: (contracts as any)?.reduce((acc: any, actual: any) => {
        const contratoId = actual.id.split('-')[0];
        if (acc.find((a: any) => a.id === contratoId)) {
          acc.find((a: any) => a.id === contratoId).pagamentos.push(actual);
        } else acc.push({ id: contratoId, pagamentos: [actual] });
        return acc;
      }, []),
    };

    request<Blob>({
      url: `/projeto/${projectId}/contrapartidas/oficios`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: params,
      responseType: 'blob',
      onSuccess: (blob: Blob, extras?: ResponseExtras) => {
        downloadBlob(blob, 'declaracoes.zip', extras);
      },
      onError: data => console.error(data),
    });
  };

  function handleDeclaration() {
    dispatch({ type: 'UPDATE' });
    setVisible(false);
    dispatchButton({
      type: 'REMOVE_CONCLUSION',
      payload: {
        conclusions: [],
        validDate: [],
      },
    });
  }

  const emptyConclusions = buttonState.conclusions.length === 0;
  const emptyPendingConclusions = buttonState.pendingConclusions.length === 0;

  return (
    <WrapperCard maxWidth="1320px" minWidth="80%" padding="21px 0px">
      <div className={styles.conclusionPanel}>
        <Form ref={form} baseUrl="">
          <PlannerHeader
            subtitle={tx('subtitle')}
            legends={[
              {
                label: tx('status.com'),
                color: '#37a64a',
              },
              {
                label: tx('status.req'),
                color: '#BBEBC4',
              },
              {
                label: tx('status.lat'),
                color: '#E61616',
              },
            ]}
            style={{ padding: '0px 22px' }}
          />

          <div className={styles.actions}>
            {profile !== Profile.SUPERVISOR ? (
              <div>
                <DownloadCraftButton
                  onClick={() => handleDownloadOficios()}
                  disabled={emptyConclusions}
                >
                  {tx('buttonLabel')}
                </DownloadCraftButton>
                <DownloadCraftButton
                  onClick={() => handleDownloadOficios(true)}
                  disabled={emptyPendingConclusions}
                >
                  {tx('downloadPending')}
                </DownloadCraftButton>
                <DownloadCraftButton
                  onClick={() => setVisible(true)}
                  disabled={hideUploadButton}
                >
                  {tx('fileUpload')}
                </DownloadCraftButton>
              </div>
            ) : (
              <div />
            )}

            <Select
              name="range"
              theme="compact"
              metadata={{
                options: [
                  {
                    value: '3',
                    text: tx('range.3'),
                  },
                  {
                    value: '6',
                    text: tx('range.6'),
                  },
                  {
                    label: 'Anual',
                    value: '12',
                    text: tx('range.12'),
                  },
                ],
              }}
              value="12"
            />
          </div>
          <ConclusionPlanner
            dispatchFilter={dispatchFilter}
            stateFilter={stateFilter}
            reference={table}
            dispatchButton={dispatchButton}
            stateButton={buttonState}
          />
        </Form>
      </div>
      <Declaration
        visible={visible}
        onClose={() => setVisible(false)}
        state={buttonState}
        onSuccess={handleDeclaration}
      />
    </WrapperCard>
  );
};

export default ScheduleList;
