import React from 'react';

import { useTranslation } from 'i18n';
import { Grid, Row } from 'components/containers';
import { PlainField } from 'components/elements';
import { toDateDDMMYYY } from 'utils/calendarUtils';

export type Data = {
  nome: string;
  cpf: string;
  dataNascimento: Date;
  genero: string;
  racaCor: string;
  rg: string;
  rgEmissor: string;
  rgUf: string;
  status: boolean;
  endereco: string;
  email: string;
  telefone: string;
};

const Person = ({ data }: { data: Data }) => {
  const { t } = useTranslation('register');
  const {
    nome,
    cpf,
    status,
    dataNascimento,
    genero,
    racaCor = 'não-informado',
    rg,
    rgEmissor,
    rgUf,
    endereco,
    email,
    telefone,
  } = data;

  return (
    <Grid spacing={0}>
      <Row width={[40, 27, 33]} align="top">
        <PlainField label={t('personal.name')} value={nome} />
        <PlainField label={t('personal.id')} value={cpf} />
        <PlainField
          label={t('personal.dataNascimento')}
          value={toDateDDMMYYY(dataNascimento)}
        />
        <PlainField label={t('personal.genero.label')} value={genero} />
        <PlainField
          label={t('personal.racaCor.title')}
          value={t('personal.racaCor.' + racaCor)}
        />
        <PlainField
          label={t('status.label')}
          value={t(status ? 'status.enabled' : 'status.disabled')}
        />
      </Row>

      <Row width={[40, 27, 33]} align="top">
        <PlainField label={t('personal.rg')} value={rg} />
        <PlainField label={t('personal.orgao')} value={rgEmissor} />
        <PlainField label={t('personal.uf')} value={rgUf} />
      </Row>

      <Row width={[40, 27, 33]} align="top">
        <PlainField label={t('address.title')} value={endereco} />
        <PlainField label={t('personal.email')} value={email} />
        <PlainField label={t('personal.phone')} value={t(telefone)} />
      </Row>
    </Grid>
  );
};

export default Person;
