import { useCallback, useState } from 'react';
import { RequestConfig, useRequest } from 'apis';
import { messageService } from 'services';
import { useTranslationX } from 'i18n';
import { X, Restore, CheckCircleAlt, Config } from 'components/icons';
import { TransactionType } from './TransactionList';

type TransactionActionsProps = {
  onReload: () => void;
  id: string | number;
  tipo: TransactionType;
};

export const useTransactionActions = <T>({
  onReload,
  id,
  tipo,
}: TransactionActionsProps) => {
  const request = useRequest();
  const { tx } = useTranslationX('expenses', 'finance');

  const [selectedRow, setSelectedRow] = useState<T>();

  const [modal, setModal] = useState<string>();

  const handleCloseModal = () => {
    setModal(undefined);
  };

  const handleRequest = useCallback(
    async <T>(config: RequestConfig<T>): Promise<T> =>
      new Promise<T>((resolve, reject) => {
        const source = request<T>({
          ...config,
          onError: err => {
            const { error } = err.response?.data as { error?: string };
            messageService.error(error ?? tx('error'), { duration: 2000 });
            config.onError?.(err);
            reject(new Error('Generic error'));
          },
          onSuccess: response => {
            config.onSuccess?.(response);
            resolve(response);
          },
        });
        return () => source.cancel();
      }),
    [request, tx]
  );

  const handleRemove = useCallback(
    async (record: any) => {
      try {
        let type = '';
        if (tipo === 'despesas-rh') {
          type = 'despesa-rh';
        } else if (tipo === 'despesas-dr') {
          type = 'despesa-dr';
        } else if (tipo === 'credito-adicional') {
          type = tipo;
        }
        await handleRequest({
          url: `/financeiro/conciliacao/despesas/contas-projeto/${type}/${record.id}`,
          method: 'DELETE',
        });
        onReload();
      } catch (ignore) {}
    },
    [handleRequest, onReload, tipo]
  );

  const handleFinishSolicitation = useCallback(async (record: T) => {
    try {
      setSelectedRow(record);
      setModal('finishSolicitation');
    } catch (ignore) {}
  }, []);

  const handleFillSolicitation = useCallback(async (record: T) => {
    try {
      setSelectedRow(record);
      setModal('fillSolicitation');
    } catch (ignore) {}
  }, []);
  const handleResetConciliation = useCallback(
    async (record: any, index: number, formRef: any) => {
      const itemId = record.id;
      const path = record.eh_solicitacao
        ? `/financeiro/conciliacao/despesas/contas-projeto/solicitacao/${itemId}/lancamentos`
        : `/financeiro/conciliacao/despesas/contas-projeto/${tipo}/${itemId}/lancamentos`;

      try {
        await handleRequest({
          url: path,
          method: 'POST',
          data: {
            lancamentoId: id,
            valor: null,
            tipo: tipo === 'despesas-rh' ? 'rh' : record.tipo,
          },
        });
        formRef?.current?.context.setFieldValue(`row[${index}].valor_conciliado`, 0);
        onReload();
      } catch (ignore) {}
    },
    [tipo, handleRequest, id, onReload]
  );

  const getActions = useCallback(
    (data: any, index: number, formRef?: any) => {
      const actions: any[] = [];
      if (!data.eh_solicitacao) {
        actions.push({
          color: '#E51616',
          icon: X,
          onClick: async () => await handleRemove(data),
        });
      }
      if (data.valor_conciliado > 0 && !data.finalizado) {
        actions.push({
          color: '#686868',
          icon: Restore,
          onClick: async () => await handleResetConciliation(data, index, formRef),
        });
      }

      const fillableSolicitations = ['servico', 'material', 'equipamentos', 'passagem'];

      if (
        data.eh_solicitacao &&
        tipo === 'despesas-dr' &&
        data.tem_vinculo &&
        fillableSolicitations.includes(data.tipo)
      ) {
        actions.push({
          color: '#686868',
          icon: Config,
          onClick: async () => await handleFillSolicitation(data),
        });
      }
      if (
        data.eh_solicitacao &&
        (tipo === 'despesas-dr' || data.tipo === 'Estágio') &&
        data.situacao === 'PCI'
      ) {
        actions.push({
          color: '#44CF5C',
          icon: CheckCircleAlt,
          onClick: async () => await handleFinishSolicitation(data),
        });
      }
      return actions;
    },
    [
      handleFillSolicitation,
      handleFinishSolicitation,
      handleRemove,
      handleResetConciliation,
      tipo,
    ]
  );

  return {
    handleRequest,
    handleRemove,
    handleResetConciliation,
    getActions,
    modal,
    handleCloseModal,
    selectedRow,
  };
};
