import { useRef, useCallback } from 'react';
import _ from 'lodash';
import i18n from 'i18n';
import { CancelTokenSource, useRequest } from 'apis';
import { FormContextValue, Issue, IssueType } from './types';
import { array2Object, isNil } from 'utils/index';
import { validateMask } from 'utils/inputMask';
import { useFieldSet, useForm, useFormSection } from '.';
import { removeIndices } from './helpers';
import moment from 'moment';
import { toSQLTimestamp } from 'utils/calendarUtils';

type AsyncResponse = { status: 'success' | 'failed'; rule?: string };

type Extra = { form: FormContextValue; fieldSet?: string; formSection?: string };

export const useValidation = (url?: string) => {
  const form = useForm();
  const fieldSet = useFieldSet();
  const formSection = useFormSection();
  const request = useRequest();
  const source = useRef<CancelTokenSource>();
  const cache = useRef<{ value: any; issue?: Issue; time: number }>();

  const validate = useCallback(
    (value: any, rules?: { [key: string]: any }): Issue | undefined => {
      source.current?.cancel();

      const extra = { form, fieldSet: fieldSet.prefix, formSection: formSection.name };

      const customMessage = rules?.customMessage;

      const formattedValue =
        typeof value === 'string' ? value.replaceAll(/<[^>]*>/g, '') : value;

      if (!rules) {
        return;
      }

      if (rules.required && !methods.required(formattedValue)) {
        return makeIssue('required');
      }

      for (const rule in rules) {
        if (methods[rule]) {
          const args = rules[rule];
          if (!methods[rule](formattedValue, args, extra)) {
            return makeIssue(
              rule,
              Array.isArray(args) ? args : [args],
              extra,
              undefined,
              customMessage
            );
          }
        }
      }

      if (rules.async) {
        return makeIssue('async', undefined, extra, 'pending');
      }
    },
    [fieldSet, form, formSection]
  );

  const asyncValidate = useCallback(
    (
      name: string,
      value: any,
      rules: { [key: string]: any } | undefined,
      onStart: () => void,
      onComplete: (issue?: Issue) => void,
      onCancel: () => void
    ): boolean => {
      if (rules && rules.async && url && !isNil(value)) {
        const issue = validate(value, rules);

        if (!issue || issue.type !== 'pending') {
          return true;
        }
      } else {
        source.current?.cancel();
        return false;
      }

      if (
        cache.current &&
        cache.current.value === value &&
        new Date().getTime() - cache.current.time < 10000
      ) {
        onComplete(cache.current.issue);
        return true;
      }

      onStart();

      const id = form.getFieldValue(`${name.substring(0, name.lastIndexOf('.') + 1)}id`);

      source.current = request<AsyncResponse>({
        url,
        params: { property: rules.async, value, field: removeIndices(name), id },
        onSuccess: ({ status, rule = 'async', ...args }) => {
          cache.current = {
            value,
            issue: status === 'success' ? undefined : makeIssue(rule, [args]),
            time: new Date().getTime(),
          };

          onComplete(cache.current.issue);
        },
        onCancel: mounted => {
          if (mounted) {
            onCancel();
          }
        },
        onError: onCancel,
        onComplete: () => {
          source.current = undefined;
        },
      });

      return true;
    },
    [form, request, url, validate]
  );

  return { validate, asyncValidate };
};

export const makeIssue = (
  rule: string,
  args?: any[],
  extra?: Extra,
  type?: IssueType,
  customMessage?: string
): Issue => {
  const message =
    customMessage ?? (messages[rule] && args ? messages[rule](...args, extra) : null);

  const issue: Issue = {
    type: type || 'error',
    rule,
    message: message || i18n.t(`validation.${rule}`, array2Object(args)),
  };

  if (imperativeRules.includes(rule)) {
    issue.imperative = true;
  }

  return issue;
};

const getValueFromExpression = (expression: string, { form, fieldSet }: Extra) => {
  const elements = expression.trim().split(' ') as string[];

  // Get elements from example field2 * field2 + field3

  const values = elements.map(element => {
    if (['*', '+', '-', '/'].includes(element)) {
      return element;
    }

    const value = form?.getFieldValue(`${fieldSet}.${element}`);

    return value;
  });

  try {
    // eslint-disable-next-line no-eval
    const calculatedValue = eval(values.join(' '));
    return calculatedValue;
  } catch (e) {
    return undefined;
  }
};
const methods: { [key: string]: (...args: any[]) => boolean } = {
  required: (value, r) => {
    if (r === false) return true;
    if (typeof value === 'object') {
      // objects or arrays
      for (const _key in value) {
        return true;
      }

      return false;
    }

    // number, boolean, string
    return value !== null && value !== undefined && value !== '';
  },

  maxlength: (value, max: number) => {
    if (max > 0 && (Array.isArray(value) || typeof value === 'string')) {
      return value.length <= max;
    }

    return true;
  },

  minlength: (value, min) => {
    if (optional(value)) {
      return true;
    }

    if (min > 0 && (Array.isArray(value) || typeof value === 'string')) {
      return value.length >= min;
    }

    return true;
  },

  // https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
  email: value =>
    optional(value) ||
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
      value
    ),

  mask: (value, mask) => optional(value) || validateMask(value, mask),
  pattern: (value, regex) => optional(value) || regex.test(value),

  // https://gist.github.com/dperini/729294
  url: value =>
    optional(value) ||
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    ),

  rejected: (value, rejected) => {
    return normalized(value) !== normalized(rejected.value);
  },
  lattes: value => optional(value) || /^https?:\/\/lattes\.cnpq\.br\/\d+$/.test(value),

  // https://www.geradorcpf.com/javascript-validar-cpf.htm
  cpf: value => {
    value = value.replace(/[^\d]+/g, '');

    if (value === '') {
      return false;
    }

    // Elimina CPFs invalidos conhecidos
    if (
      value.length !== 11 ||
      value === '00000000000' ||
      value === '11111111111' ||
      value === '22222222222' ||
      value === '33333333333' ||
      value === '44444444444' ||
      value === '55555555555' ||
      value === '66666666666' ||
      value === '77777777777' ||
      value === '88888888888' ||
      value === '99999999999'
    ) {
      return false;
    }

    // Valida 1o digito
    let add = 0;

    for (let i = 0; i < 9; ++i) {
      add += parseInt(value.charAt(i)) * (10 - i);
    }

    let rev = 11 - (add % 11);

    if (rev === 10 || rev === 11) {
      rev = 0;
    }

    if (rev !== parseInt(value.charAt(9))) {
      return false;
    }

    // Valida 2o digito
    add = 0;

    for (let i = 0; i < 10; ++i) {
      add += parseInt(value.charAt(i)) * (11 - i);
    }

    rev = 11 - (add % 11);

    if (rev === 10 || rev === 11) {
      rev = 0;
    }

    return rev === parseInt(value.charAt(10));
  },
  cnpj: value => {
    value = value.replace(/[^\d]+/g, '');

    if (value === '' || value.length !== 14) {
      return false;
    }

    // Elimina CNPJs inválidos conhecidos
    if (
      value === '00000000000000' ||
      value === '11111111111111' ||
      value === '22222222222222' ||
      value === '33333333333333' ||
      value === '44444444444444' ||
      value === '55555555555555' ||
      value === '66666666666666' ||
      value === '77777777777777' ||
      value === '88888888888888' ||
      value === '99999999999999'
    ) {
      return false;
    }

    // Valida os dois dígitos verificadores
    const validateDigit = (size: number) => {
      let add = 0;
      let pos = size - 7;

      for (let i = 0; i < size; i++) {
        add += parseInt(value.charAt(i)) * pos--;
        if (pos < 2) pos = 9;
      }

      let rev = add % 11;
      return (rev < 2 ? 0 : 11 - rev) === parseInt(value.charAt(size));
    };

    return validateDigit(12) && validateDigit(13);
  },
  cnpjCpf: value => {
    value = value.replace(/[^\d]+/g, '');

    if (value.length === 11) {
      // Validação de CPF
      if (/^(\d)\1{10}$/.test(value)) return false;

      let add = 0;
      for (let i = 0; i < 9; i++) add += parseInt(value.charAt(i)) * (10 - i);
      let rev = 11 - (add % 11);
      if (rev === 10 || rev === 11) rev = 0;
      if (rev !== parseInt(value.charAt(9))) return false;

      add = 0;
      for (let i = 0; i < 10; i++) add += parseInt(value.charAt(i)) * (11 - i);
      rev = 11 - (add % 11);
      if (rev === 10 || rev === 11) rev = 0;
      return rev === parseInt(value.charAt(10));
    }

    if (value.length === 14) {
      // Validação de CNPJ
      if (/^(\d)\1{13}$/.test(value)) return false;

      const validateDigit = (size: number) => {
        let add = 0,
          pos = size - 7;
        for (let i = 0; i < size; i++) {
          add += parseInt(value.charAt(i)) * pos--;
          if (pos < 2) pos = 9;
        }
        let rev = add % 11;
        return (rev < 2 ? 0 : 11 - rev) === parseInt(value.charAt(size));
      };

      return validateDigit(12) && validateDigit(13);
    }

    return false;
  },

  equalTo: (value, { target }, { form }) => {
    const dependency = form!.getFieldValue(target);
    return optional(dependency) || optional(value) || value === dependency;
  },

  notEqualTo: (value, { target }, { form }) => {
    const dependency = form!.getFieldValue(target);
    return optional(dependency) || optional(value) || value !== dependency;
  },

  equalInSection: (value, { section, field }, { form }) => {
    const values = form?.getSectionFieldValues(section, field);
    const amount = _.countBy(values)[value] || 0;

    return optional(value) || amount >= 1;
  },

  requiredIfFieldSetValueExists: (value, { field }, { form, fieldSet }) => {
    const dependentValue = form?.getFieldValue(fieldSet + field);

    return dependentValue !== null &&
      dependentValue !== undefined &&
      dependentValue !== ''
      ? value !== null && value !== undefined && value !== ''
      : true;
  },

  uniqueInSection: (value, { section, field }, { form }) => {
    const values = form?.getSectionFieldValues(section, field);
    const amount = _.countBy(values)[value] || 0;

    return optional(value) || amount <= 1;
  },

  maxWithExpression: (value, expression, extra) => {
    const calculatedValue = getValueFromExpression(expression, extra) || 0;

    return optional(value) || value <= calculatedValue;
  },
  minWithExpression: (value, expression, extra) => {
    const calculatedValue = getValueFromExpression(expression, extra) || 0;

    return optional(value) || value >= calculatedValue;
  },

  min: (value, param) => {
    const val =
      typeof value === 'number'
        ? value
        : typeof value === 'string'
        ? Number(value)
        : param;

    return optional(value) || val >= param;
  },

  max: (value, param) => {
    const val =
      typeof value === 'number'
        ? value
        : typeof value === 'string'
        ? Number(value)
        : param;

    return optional(value) || val <= param;
  },
  htmlmax: (value, max: number) => {
    if (max > 0 && typeof value === 'string') {
      return stripTags(value).length <= max;
    }

    return true;
  },
  beforeToday: (value, { inclusive }) => {
    if (optional(value)) {
      return true;
    }

    const date = moment(value);
    const today = moment();

    if (inclusive) {
      return date.isSameOrBefore(today);
    }
    return date.isBefore(today);
  },
  maxDateDay: (value, max: number) => {
    if (max > 0 && typeof value === 'string') {
      return moment(value).get('date') <= max;
    }
    return true;
  },
  htmlmin: (value, min) => {
    if (optional(value)) {
      return true;
    }

    if (min > 0 && typeof value === 'string') {
      return stripTags(value).length >= min;
    }

    return true;
  },
};

const messages: { [key: string]: (...args: any[]) => string } = {
  async: ({ message }) => {
    return i18n.t(`validation.${message || 'default'}`);
  },
  rejected: ({ message, rule, params, key }) => {
    if (message) {
      return message;
    }

    if (key) {
      if (params) {
        return i18n.t(`validation.invalid.${key}`, array2Object(params));
      }
      return i18n.t(`validation.invalid.${key}`);
    }

    if (rule) {
      return i18n.t(`validation.${rule}`, array2Object(params));
    }
  },
  beforeToday: inclusive => {
    return i18n.t(`validation.beforeToday.${inclusive ? 'inclusive' : 'exclusive'}`);
  },
  equalTo: ({ message }) => i18n.t(`validation.equalTo.${message || 'default'}`),
  notEqualTo: ({ message }) => i18n.t(`validation.notEqualTo.${message || 'default'}`),
  maxWithExpression: (expression, extra) => {
    const calculatedValue = getValueFromExpression(expression, extra);
    return i18n.t(`validation.max`, array2Object([calculatedValue]));
  },
  minWithExpression: (expression, extra) => {
    const calculatedValue = getValueFromExpression(expression, extra);
    return i18n.t(`validation.min`, array2Object([calculatedValue]));
  },
};

const imperativeRules = ['rejected', 'async', 'unique', 'cpf', 'cnpj'];

function optional(value: any): boolean {
  return !methods.required(value);
}

function stripTags(html: string) {
  return html.replace(/(<([^>]+)>)/gi, '');
}

function normalized(value: any) {
  if (value === null || value === undefined || value === '') {
    return null;
  }

  if (typeof value === 'object') {
    return JSON.stringify(value);
  }

  if (typeof value === 'string' && !isNaN(Number(value))) {
    return Number(value);
  }

  if (typeof value === 'string' && moment(value).isValid()) {
    return toSQLTimestamp(value);
  }
  return value;
}
