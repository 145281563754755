import React from 'react';

import { useTranslationX } from 'i18n';
import Dialog, { DialogProps } from './Dialog';
import { useVisible } from 'components/hooks';
import { Button } from 'components/elements';
import { SuccessAlt } from 'components/icons';

import styles from './Dialog.module.scss';

type Props = {
  visible: boolean;
  buttonLabel?: string;
  title?: string;
  onClose: () => void;
  onClick?: () => void;
};

const SuccessDialog: React.FC<Props & DialogProps> = props => {
  const { buttonLabel, onClose, onClick, ...rest } = props;
  const { visible, setVisible } = useVisible(props.visible);
  const { tx } = useTranslationX('button');

  return (
    <Dialog
      {...rest}
      width={530}
      visible={visible}
      icon={SuccessAlt}
      iconProps={{ color: styles.iconColor }}
      onClose={onClose}
      onBlur={onClose}
      actions={
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => {
              if (onClick) {
                onClick?.();
              } else {
                setVisible(false);
              }
            }}
          >
            {buttonLabel || tx('ok')}
          </Button>
        </div>
      }
    />
  );
};

export default SuccessDialog;
