import React, { createRef } from 'react';

import { Dialog, Grid, Row } from 'components/containers';
import { useTranslationX } from 'i18n';

import { Button, WrapperCard } from 'components/elements';
import { BalanceStatus } from 'pages/finance/types';

import { FormInstance } from 'components/form/types';
import { DatePicker, Form, TextField, Select, HiddenField } from 'components/form';

import styles from './FillSolicitationModal.module.scss';

type Item = {
  id: number;
  tipo: string;
  item: string;
  situacao: BalanceStatus;
  valor: number;
  valor_conciliado: number;
  saldo_a_conciliar: number;
};

type Props = {
  uc: Item;
  lancamentoId: number;
  visible: boolean;
  onClose: () => void;
  onRefresh: () => void;
};

const FillSolicitationModal: React.FC<Props> = props => {
  const { uc, visible, onClose, onRefresh, lancamentoId } = props;

  const { tx } = useTranslationX('expenses', 'finance');

  const formRef = createRef<FormInstance>();

  const Content = () => {
    return (
      <Grid spacing={0}>
        <HiddenField name="lancamentoId" value={lancamentoId} />
        <Row>
          <Select
            name="identificacaoDespesaConciliacao"
            label={tx('identificationField')}
          />
        </Row>

        <Row width={[0.5, 0.5]}>
          <TextField name="credor" label={tx('creditor')} />
        </Row>
        <Row width={[0.4, 0.6]} align="baseline">
          <TextField name="cnpjCpf" label={tx('cnpjCpf')} />
        </Row>
        <div className={styles.container}>
          <h1>{tx('invoice')}</h1>
          <Row>
            <TextField name="numeroNotaFiscal" label={tx('invoiceNumber')} />
          </Row>
          <Row width={[0.5, 0.5]}>
            <DatePicker name="dataNotaFiscal" label={tx('invoiceDate')} />
          </Row>
        </div>
      </Grid>
    );
  };

  return (
    <Dialog
      title={tx('fillSolicitation')}
      visible={visible}
      onClose={onClose}
      closeButton
      width={640}
      actions={
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type="secondary" onClick={onClose}>
            {tx('cancel')}
          </Button>
          <Button onClick={() => formRef.current?.context.submit()}>{tx('save')}</Button>
        </div>
      }
    >
      <WrapperCard maxWidth="100%" padding="30px 32px">
        <Form
          baseUrl={`/financeiro/conciliacao/despesas/contas-projeto/solicitacao/${uc.id}/preencher`}
          ref={formRef}
          onDone={() => {
            onClose();
            onRefresh?.();
          }}
          populate={{
            lancamentoId: String(lancamentoId),
          }}
          submit={{
            method: 'post',
          }}
        >
          <Content />
        </Form>
      </WrapperCard>
    </Dialog>
  );
};

export default FillSolicitationModal;
