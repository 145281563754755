import React from 'react';

import { ScrollPanel, Tab, Tabs } from 'components/containers';
import CardPurchaseOrder from '../CardPurchaseOrder/CardPurchaseOrder';

import { useHistory } from 'react-router';
import { NotFoundMessage, WrapperCard } from 'components/elements';
import { useAuthState } from 'store/auth';
import { PartData, CostUnitItem, RequestItem } from 'pages/project/types';
import { useProjectEditor } from 'pages/project/ProjectEditor/contexts';
import { useTranslationX } from 'i18n';
import { Profile } from 'pages/register/profile';

import styles from './CardsTabs.module.scss';

type Props = {
  area: PartData[];
  maxWidth?: string;
  customAction?(item: RequestItem): void;
};

const CardsTabs: React.FC<Props> = props => {
  const history = useHistory();
  const { profile } = useAuthState();
  const { dispatch, state } = useProjectEditor();
  const { maxWidth = undefined } = props;
  const emptyAreas = props.area.length === 0;

  const { tx } = useTranslationX('request.not_found_areas', 'project');

  const isResponsible = profile !== Profile.SUPERVISOR;

  const showPurchaseButton = isResponsible && state.section === 'outros';

  const lastTab = localStorage.getItem('@sippi-keep-tab') ?? 0;

  return (
    <WrapperCard
      padding="5px 15px 15px"
      maxWidth={emptyAreas ? '100%' : maxWidth}
      width={emptyAreas ? '100%' : 'fit-content'}
    >
      {!emptyAreas ? (
        <Tabs selectedIndex={parseInt(lastTab as string)} keepState>
          {props.area.map((item: PartData, indexArea: number) => {
            return (
              <Tab title={item.nome} key={indexArea}>
                <ScrollPanel
                  vBar={{ overlay: true }}
                  style={{ content: { maxHeight: '460px' } }}
                >
                  <div className={styles.cardsContainer}>
                    {(item.ucs as CostUnitItem[]).map((card, indexItem) => (
                      <CardPurchaseOrder
                        showPurchaseOrder={!showPurchaseButton}
                        key={indexItem}
                        data={card}
                        onItemSelected={() => {
                          dispatch({ type: 'SET_ITEM', payload: card });
                          history.push(history.location.pathname + '/solicitacao');
                        }}
                      />
                    ))}
                  </div>
                </ScrollPanel>
              </Tab>
            );
          })}
        </Tabs>
      ) : (
        <NotFoundMessage title={tx('title')} description={tx('description')} />
      )}
    </WrapperCard>
  );
};

export default CardsTabs;
