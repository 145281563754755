import { useCallback } from 'react';
import { RequestConfig, useRequest } from 'apis';
import { messageService } from 'services';
import { useTranslationX } from 'i18n';
import { X, Restore } from 'components/icons';

type TransactionActionsProps = {
  onReload: () => void;
  id: string | number;
};

export const useTransactionActions = ({ onReload, id }: TransactionActionsProps) => {
  const request = useRequest();
  const { tx } = useTranslationX('expenses', 'finance');

  const handleRequest = useCallback(
    async <T>(config: RequestConfig<T>): Promise<T> =>
      new Promise<T>((resolve, reject) => {
        const source = request<T>({
          ...config,
          onError: err => {
            const { error } = err.response?.data as { error?: string };
            messageService.error(error ?? tx('error'), { duration: 2000 });
            config.onError?.(err);
            reject(new Error('Generic error'));
          },
          onSuccess: response => {
            config.onSuccess?.(response);
            resolve(response);
          },
        });
        return () => source.cancel();
      }),
    [request, tx]
  );

  const handleRemoveAdditionalCredit = useCallback(
    async (record: any) => {
      try {
        await handleRequest({
          url: `/financeiro/conciliacao/despesas/contas-mae/credito-adicional/${record.id}`,
          method: 'DELETE',
        });
        onReload();
      } catch (ignore) {}
    },
    [handleRequest, onReload]
  );

  const handleResetConciliation = useCallback(
    async (record: any, index: number, formRef: any) => {
      const itemId = record.id;
      const path = record.eh_solicitacao
        ? `/financeiro/conciliacao/despesas/contas-mae/solicitacao/${itemId}/lancamentos`
        : `/financeiro/conciliacao/despesas/contas-mae/credito-adicional/${itemId}/lancamentos`;
      try {
        await handleRequest({
          url: path,
          method: 'POST',
          data: {
            lancamentoId: id,
            valor: null,
          },
        });
        formRef?.current?.context.setFieldValue(`row[${index}].valor_conciliado`, 0);
        onReload();
      } catch (ignore) {}
    },
    [handleRequest, onReload, id]
  );

  const getActions = useCallback(
    (data: any, index: number, formRef?: any) => {
      const actions: any[] = [];
      if (!data.eh_solicitacao) {
        actions.push({
          color: '#E51616',
          icon: X,
          onClick: async () => await handleRemoveAdditionalCredit(data),
        });
      }
      actions.push({
        color: '#686868',
        icon: Restore,
        onClick: async () => await handleResetConciliation(data, index, formRef),
      });
      return actions;
    },
    [handleRemoveAdditionalCredit, handleResetConciliation]
  );

  return {
    handleRequest,
    handleRemoveAdditionalCredit,
    handleResetConciliation,
    getActions,
  };
};
