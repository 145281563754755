import React, { ComponentType, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  Button,
  Loader,
  PieGraph,
  PieGraphData,
  Spacer,
  WrapperCard,
} from 'components/elements';
import { useTranslation, useTranslationX } from 'i18n';

import {
  MenuChecked,
  Warning,
  Info,
  AirportShuttle,
  DocumentFile,
  Expenditure,
  Circle,
  Project,
  Home as HomeIcon,
  Home,
  Users,
  Bell,
  PDF,
  Sei,
  Supervisor,
  Handshake,
  Evaluation,
} from 'components/icons';
import { Grid, Modal, ModalPDFViewer, Row, ScrollPanel } from 'components/containers';

import { DashboardData, ProjectStatus } from '../types';
import { useDynamicHeader } from 'components/containers/DynamicHeader';
import { useFetch, useRequest } from 'apis';

import { Counterpart, Milestones, RequestTable } from '../index';
import { messageService } from 'services';
import { MilestonesEvolution, ProjectDetails } from '.';

import { useDispatch } from 'react-redux';
import { MenuItemIcon } from 'pages/dashboard/types';
import { editSideMenu } from 'store/profile';

import ProjetoPesquisador from 'pages/pesquisador/menu/Projeto';
import ProjetoSupervisor from 'pages/supervisor/menu/Projeto';

import ProjetoAluno from 'pages/aluno/menu/Projeto';
import ProjetoColaborador from 'pages/colaborador/menu/Projeto';

import PessoalPesquisador from 'pages/pesquisador/menu/Pessoal';
import PessoalSupervisor from 'pages/supervisor/menu/Pessoal';

import PropostaPesquisador from 'pages/pesquisador/menu/Proposta';
import PropostaSupervisor from 'pages/supervisor/menu/Proposta';

import { useAuthState } from 'store/auth';
import { Profile, RemappedProfile } from 'pages/register/profile';
import Homologacao from 'pages/supervisor/homologacao/Homologacao';
import { getMasked } from 'utils/inputMask';
import { API_SERVER_URL } from '../../../consts';

import { UpdateWPInEdition } from '../Sections/Milestones/types';
import { Form, Select, SubmitButton, TableSelect } from 'components/form';

import styles from './ProjectDashboard.module.scss';
import Financeiro from 'pages/supervisor/menu/Financeiro';

export type Props = {
  titulo: string;
  id: number;
  sigla: string;
  status: ProjectStatus;
};

export type Fonte = {
  nome: string;
  id: number;
  gpf: string;
  conta: {
    id: number;
    banco: string;
    agencia: string;
    numero: string;
  };
};

export type ProjectDetailsType = {
  titulo: string;
  sigla: string;
  duracao: string;
  maturidade: string;
  subarea: string;
  publicacaoDOU: string;
  finalizacao: string;
  coordenador: string;
  coordenadorId: number;
  emailCoordenador: string;
  telefoneCoordenador: string;
  fontes: Fonte[];
  id: number;
  mesInicioExecucao: number;
  gerente?: {
    id: number;
    nome: string;
  };
};

const PARTS_COLORS: string[][] = [
  ['#FB8832'],
  ['#9B51E0'],
  ['#007AFF'],
  ['#E6E5E6', '#595959'],
];

const ProjectDashboard = (props: Props) => {
  const { id: projectId, titulo, sigla } = props;
  const { tx, t } = useTranslationX('dashboard', 'project');
  const { t: t2 } = useTranslation();

  const { data } = useFetch<DashboardData>(`/projetos/${projectId}/dashboard`);
  const { data: edition } = useFetch<UpdateWPInEdition>(
    `/projetos/${projectId}/em-edicao`
  );
  const { data: projectDetailsData, reload } = useFetch<ProjectDetailsType>(
    `/projetos/${projectId}/projeto`
  );

  const [pdfData, setPdfData] = useState<string | ArrayBuffer | null>();
  const [showPDF, setShowPDF] = useState<boolean>(false);
  const [showManagerModal, setShowManagerModal] = useState<boolean>(false);
  const [showVersions, setShowVersions] = useState<boolean>(false);

  const { setBreadcrumbs, setFooter, setVisibility, clear } = useDynamicHeader();
  const dispatch = useDispatch();

  const history = useHistory();
  const request = useRequest();
  const { personId, profile, userId } = useAuthState();

  const coordinatorId = projectDetailsData?.coordenadorId;

  const [loadingAdditive, setLoadingAdditive] = useState(false);
  const [showProjectDetails, setShowProjectDetails] = useState(false);

  const { url } = useRouteMatch();

  const remappedProfile = RemappedProfile[profile!];

  const isSuperior =
    remappedProfile === Profile.SUPERVISOR ||
    (remappedProfile === Profile.RESEARCHER && coordinatorId === personId);

  const sideMenu: MenuItemIcon[] = useMemo(() => {
    const isSupervisor = remappedProfile === Profile.SUPERVISOR;

    const ProjectMenu: { [key: string]: ComponentType<any> } = {
      [Profile.SUPERVISOR]: ProjetoSupervisor,
      [Profile.RESEARCHER]: ProjetoPesquisador,
      [Profile.STUDENT]: ProjetoAluno,
      [Profile.CONTRIBUTOR]: ProjetoColaborador,
    };

    const ProposalMenu: { [key: string]: ComponentType<any> } = {
      [Profile.SUPERVISOR]: PropostaSupervisor,
      [Profile.RESEARCHER]: PropostaPesquisador,
    };

    const PersonMenu: { [key: string]: ComponentType<any> } = {
      [Profile.SUPERVISOR]: PessoalSupervisor,
      [Profile.RESEARCHER]: PessoalPesquisador,
    };

    const sideMenu: MenuItemIcon[] = [
      {
        key: 'home',
        icon: HomeIcon,
        component: Home,
      },
      {
        key: 'propostas',
        path: 'propostas',
        icon: Handshake,
        component: ProposalMenu[remappedProfile],
      },
      {
        key: 'projetos',
        home: 'projetos/resumo',
        icon: Project,
        component: ProjectMenu[remappedProfile],
      },
      {
        key: 'dashboard',
        home: `projetos/${projectId}`,
        icon: Circle,
        helper: t('editor.footer', {
          sigla,
          titulo,
        }),
        component: ProjectMenu[remappedProfile],
        subMenu: true,
      },
      {
        key: 'execution',
        home: `projetos/${projectId}/recursos-humanos`,
        icon: DocumentFile,
        component: ProjectMenu[remappedProfile],
        subMenu: true,
      },
      {
        key: 'macroentregas',
        home: `projetos/${projectId}/macroentregas`,
        icon: AirportShuttle,
        component: Milestones,
        subMenu: true,
      },
      {
        key: 'contrapartida',
        home: `projetos/${projectId}/contrapartida`,
        icon: Expenditure,
        component: Counterpart,
        subMenu: true,
      },
    ];
    if (isSupervisor) {
      sideMenu.push({
        key: 'financeiro',
        home: 'financeiro/dashboard',
        icon: Evaluation,
        component: Financeiro,
      });
    }
    if (remappedProfile in PersonMenu) {
      sideMenu.push({
        key: 'pessoal',
        icon: Users,
        component: PersonMenu[remappedProfile],
      });
    }
    if (isSupervisor) {
      sideMenu.push({
        key: 'homologacao',
        icon: Bell,
        component: Homologacao,
      });
    }
    return sideMenu;
  }, [projectId, remappedProfile, sigla, t, titulo]);

  const pieChartData: PieGraphData[] = useMemo(() => {
    if (data === undefined) {
      return [];
    }

    const total = Object.values(data.gastoPartes).reduce((acc, curr) => acc + curr, 0);

    return Object.entries(data?.gastoPartes).map(([key, value], index) => ({
      label: key,
      value: (value / total) * 100,
      color: PARTS_COLORS[index][0],
      legendColor: PARTS_COLORS[index][1],
      format: (percentage: number) =>
        `${percentage.toFixed(1)}% - ${t2('currency')}${getMasked(
          Number(value).toFixed(2),
          {
            pattern: 'currency',
          }
        )}`,
    }));
  }, [data, t2]);

  const handleWP = (additive: boolean) => {
    const projectEditorPath = additive ? 'aditivo' : 'alteracao-pt';

    const requestUrl = additive ? 'additive' : 'update';

    request<any>({
      url: `/projeto/${projectId}/alteracao-pt/action/create_${requestUrl}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: { aditivo: additive },
      onSuccess: (data: { error: boolean; message?: string }) => {
        if (data.error) {
          messageService.error(`${data.message}`, {
            duration: 3000,
          });
          setLoadingAdditive(false);
        }

        if (!data.error) {
          history.push(`/dashboard/projetos/${projectId}/${projectEditorPath}`, {
            titulo,
            sigla,
          });
          setLoadingAdditive(false);
        }
      },
      onError: () => {
        setLoadingAdditive(false);
        messageService.error(t('error.request-error'), {
          duration: 3000,
        });
      },
      onStarted: () => {
        setLoadingAdditive(true);
      },
    });
  };

  const handlePDF = useCallback((blob: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      let base64data = reader.result;
      setPdfData(base64data);
      setShowPDF(true);
    };
  }, []);

  const handleHTML = useCallback(() => {
    request({
      url: `${API_SERVER_URL}/projeto/${projectId}/alteracao-pt/html?version=${data?.version}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'text',
      onSuccess: async (text: any) => {
        await navigator.clipboard.writeText(text);
        messageService.success(tx('success-copied-message'), {
          duration: 3000,
        });
      },
      onError: () => {
        messageService.error(tx('error-copied-message'), {
          duration: 3000,
        });
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, data?.version, projectId]);

  const checkSelectedRoute = useCallback(
    (selected: string, key: string, pathname: string, path?: string) => {
      const projectDashboardRegex = /^\/dashboard\/projetos\/(\d+)$/;

      return (
        (key !== 'dashboard'
          ? selected?.startsWith(pathname)
          : selected === url ||
            selected?.startsWith(`${url}/${path || key}`) ||
            !!selected?.match(projectDashboardRegex)) || false
      );
    },
    [url]
  );

  const changeSideMenu = useCallback(() => {
    if (projectId === undefined) {
      return;
    }
    dispatch(editSideMenu({ sideMenu, checkSelectedRoute }));
  }, [dispatch, projectId, sideMenu, checkSelectedRoute]);

  useEffect(() => {
    setBreadcrumbs(['all-projects', 'project']);
    setFooter(
      t('editor.footer', {
        sigla: projectDetailsData?.sigla,
        titulo: projectDetailsData?.titulo,
      })
    );
    setVisibility(true);
    changeSideMenu();
    return () => {
      clear();
    };
  }, [
    setBreadcrumbs,
    setFooter,
    setVisibility,
    clear,
    sigla,
    t,
    titulo,
    changeSideMenu,
    history.location.pathname,
    projectDetailsData?.sigla,
    projectDetailsData?.titulo,
  ]);

  function onShowManagerModal() {
    setShowManagerModal(true);
  }

  function onHideManagerModal() {
    setShowManagerModal(false);
  }

  function onShowVersions() {
    setShowVersions(true);
  }

  function onHideVersions() {
    setShowVersions(false);
  }

  function onCloseProjectDetails() {
    setShowProjectDetails(false);
  }

  function onChangeManager(data: {
    requestBody?: any;
    responseBody?: any;
    section?: string | undefined;
  }) {
    if (data.responseBody) {
      onHideManagerModal();
      messageService.success(tx('successChangeManager'), {
        duration: 3000,
      });
      reload();
    }
  }

  const isAdditive = edition?.solicitacao?.aditivo;

  const isAdditiveInEdition = edition?.solicitacao !== null && isAdditive;
  const isUpdateWpInEdition = edition?.solicitacao !== null && !isAdditive;

  const canShowButtons = useMemo(() => {
    if (edition?.solicitacao !== null) {
      if (edition?.solicitacao?.estado !== 'REG') {
        return false;
      }

      if (
        RemappedProfile[profile!] === Profile.SUPERVISOR &&
        edition?.solicitacao?.perfil === Profile.SUPERVISOR &&
        edition.solicitacao.usuario.id !== userId
      ) {
        return true;
      }

      if (
        edition?.solicitacao?.perfil === Profile.SUPERVISOR &&
        RemappedProfile[profile!] !== Profile.SUPERVISOR
      ) {
        return false;
      }

      if (
        edition?.solicitacao?.perfil !== Profile.SUPERVISOR &&
        RemappedProfile[profile!] === Profile.SUPERVISOR
      ) {
        return false;
      }
    }
    return true;
  }, [edition?.solicitacao, profile, userId]);

  if (!data || !projectDetailsData) {
    return <Loader />;
  }

  const handleClose = () => {
    setShowPDF(false);
  };

  return (
    <ScrollPanel vBar={{ overlay: true }}>
      <div className={styles.dashboard}>
        <div className={styles.headers}>
          <div>
            {isSuperior && (
              <Button theme="light" icon={Supervisor} onClick={onShowManagerModal}>
                {tx('projectManager')}
              </Button>
            )}
            <Button theme="light" icon={Info} onClick={() => setShowProjectDetails(true)}>
              {tx('projectDetails.title')}
            </Button>
            <Button theme="light" icon={PDF} onClick={onShowVersions}>
              {tx('projectDetails.pdf')}
            </Button>
            {canShowButtons && (
              <>
                <Button
                  theme="light"
                  icon={Warning}
                  loading={loadingAdditive}
                  active={isUpdateWpInEdition}
                  onClick={() => handleWP(false)}
                >
                  {isUpdateWpInEdition ? tx('editUpdateWorkPlan') : tx('updateWorkPlan')}
                </Button>
                <Button
                  theme="light"
                  icon={MenuChecked}
                  loading={loadingAdditive}
                  active={isAdditiveInEdition}
                  onClick={() => handleWP(true)}
                >
                  {isAdditiveInEdition ? tx('updateAdditive') : tx('createAdditive')}
                </Button>
              </>
            )}
          </div>
        </div>
        <Grid>
          <Row align="top" width={[1, 1, 0]} space={18}>
            <MilestonesEvolution {...props} />
            <WrapperCard padding="38px 73px" maxWidth="100%">
              <ScrollPanel
                vBar={{ overlay: true }}
                hBar={{
                  visible: false,
                }}
                style={{
                  content: {
                    alignItems: 'center',
                  },
                }}
              >
                <PieGraph
                  title={{
                    show: true,
                    text: tx('spendingsByPart'),
                  }}
                  data={pieChartData}
                  legend={{
                    show: true,
                  }}
                  graph={{
                    size: 280,
                  }}
                />
              </ScrollPanel>
            </WrapperCard>
          </Row>
          <Spacer direction="vertical" length={20} />
          <Row align="top">
            <RequestTable
              filter={{ projeto_id: Number(projectId) }}
              hideFields={['gpf', 'titulo', 'numero']}
            />
          </Row>
          <Spacer direction="vertical" length={20} />
        </Grid>
      </div>
      <Modal visible={showPDF} width="auto" onBlur={handleClose}>
        <div className={styles.popup}>
          <ModalPDFViewer
            pdfData={pdfData}
            title={tx('projectDetails.pdf')}
            onClose={handleClose}
          />
        </div>
      </Modal>

      <Modal
        visible={showManagerModal}
        width={550}
        onBlur={onHideManagerModal}
        onClose={onHideManagerModal}
      >
        <div className={styles.selectManagerModal}>
          <Form
            baseUrl={`/projetos/${projectId}/gerente`}
            onDone={onChangeManager}
            onError={onHideManagerModal}
          >
            <TableSelect
              name="managerId"
              label={tx('selectManager')}
              title={tx('projectManager')}
              placeholder={tx('selectManager')}
              formatOption={option => option.nome}
              value={
                projectDetailsData.gerente
                  ? {
                      id: projectDetailsData.gerente?.id,
                      nome: projectDetailsData.gerente?.nome,
                    }
                  : undefined
              }
              fetchUrl="/projetos/gerente/candidatos"
              tableConfig={[
                {
                  title: tx('managerName'),
                  field: 'nome',
                  observerField: 'select.nome',
                  align: 'left',
                  search: true,
                  weight: 0.8,
                },
                {
                  title: tx('managerEmail'),
                  field: 'email',
                  align: 'center',
                  weight: 0.6,
                },
              ]}
              style={{
                root: {
                  margin: 0,
                },
              }}
            />

            <SubmitButton
              style={{
                root: {
                  marginTop: 20,
                },
              }}
            >
              {tx('saveChanges')}
            </SubmitButton>
          </Form>
        </div>
      </Modal>

      <Modal
        visible={showVersions}
        onBlur={onHideVersions}
        onClose={onHideVersions}
        width={550}
      >
        <div className={styles.selectManagerModal}>
          <Form
            baseUrl={`/projeto/${projectId}/alteracao-pt/versions`}
            onError={onHideVersions}
            submit={{
              responseType: 'blob',
            }}
            onDone={({ responseBody }) => handlePDF(responseBody)}
          >
            <Select name="version" label={tx('selectVersion')} />
            <SubmitButton
              style={{
                root: {
                  marginTop: 20,
                },
              }}
            >
              {tx('viewPDF')}
            </SubmitButton>
            {RemappedProfile[profile!] === Profile.SUPERVISOR && (
                <Button theme="light" icon={Sei} onClick={handleHTML}>
                  {tx('projectDetails.html')}
                </Button>
            )}
          </Form>
        </div>
      </Modal>

      <ProjectDetails
        handleClose={onCloseProjectDetails}
        visible={showProjectDetails}
        data={projectDetailsData}
      />
    </ScrollPanel>
  );
};

export default ProjectDashboard;
