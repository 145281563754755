import React from 'react';

const DownloadDocument = ({ size = 1 }: { size?: number }) => (
  <svg
    width={`${size}em`}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0306 8.05922L14.7806 2.80922C14.7109 2.73959 14.6282 2.68438 14.5371 2.64674C14.4461 2.6091 14.3485 2.58977 14.25 2.58984H5.25C4.85218 2.58984 4.47064 2.74788 4.18934 3.02918C3.90804 3.31049 3.75 3.69202 3.75 4.08984V20.5898C3.75 20.9877 3.90804 21.3692 4.18934 21.6505C4.47064 21.9318 4.85218 22.0898 5.25 22.0898H18.75C19.1478 22.0898 19.5294 21.9318 19.8107 21.6505C20.092 21.3692 20.25 20.9877 20.25 20.5898V8.58984C20.2501 8.49132 20.2307 8.39375 20.1931 8.30271C20.1555 8.21166 20.1003 8.12892 20.0306 8.05922ZM15 5.15016L17.6897 7.83984H15V5.15016ZM18.75 20.5898H5.25V4.08984H13.5V8.58984C13.5 8.78876 13.579 8.97952 13.7197 9.12017C13.8603 9.26083 14.0511 9.33984 14.25 9.33984H18.75V20.5898ZM14.7806 14.8092C14.8504 14.8789 14.9057 14.9616 14.9434 15.0526C14.9812 15.1437 15.0006 15.2413 15.0006 15.3398C15.0006 15.4384 14.9812 15.536 14.9434 15.627C14.9057 15.7181 14.8504 15.8008 14.7806 15.8705L12.5306 18.1205C12.461 18.1902 12.3783 18.2455 12.2872 18.2833C12.1962 18.321 12.0986 18.3404 12 18.3404C11.9014 18.3404 11.8038 18.321 11.7128 18.2833C11.6217 18.2455 11.539 18.1902 11.4694 18.1205L9.21937 15.8705C9.07864 15.7297 8.99958 15.5389 8.99958 15.3398C8.99958 15.1408 9.07864 14.9499 9.21937 14.8092C9.36011 14.6685 9.55098 14.5894 9.75 14.5894C9.94902 14.5894 10.1399 14.6685 10.2806 14.8092L11.25 15.7795V11.5898C11.25 11.3909 11.329 11.2002 11.4697 11.0595C11.6103 10.9189 11.8011 10.8398 12 10.8398C12.1989 10.8398 12.3897 10.9189 12.5303 11.0595C12.671 11.2002 12.75 11.3909 12.75 11.5898V15.7795L13.7194 14.8092C13.789 14.7395 13.8717 14.6842 13.9628 14.6464C14.0538 14.6087 14.1514 14.5893 14.25 14.5893C14.3486 14.5893 14.4462 14.6087 14.5372 14.6464C14.6283 14.6842 14.711 14.7395 14.7806 14.8092Z"
      fill="currentColor"
    />
  </svg>
);

export default DownloadDocument;
