import React from 'react';
import { classes } from 'utils/components';

import styles from './KeyValueRow.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  label?: string;
  value: string | number;
  margin?: 'small' | 'normal' | string;
  size?: 'small' | 'medium' | 'large';
  theme?: 'default' | 'white';
  customStyles?: React.CSSProperties;
};

const KeyValueRow: React.FC<Props> = ({
  label,
  value,
  size = 'small',
  margin = 'normal',
  theme = 'default',
  customStyles,
}) => {
  const { t } = useTranslation();

  const containerMargin = () => {
    if (margin === 'small') return '0.1em 0';
    if (margin === 'normal') return '0.5em 0';
    return '0';
  };

  return (
    <div
      className={styles.container}
      style={{
        margin: containerMargin(),
        fontSize: size === 'small' ? '12px' : size === 'medium' ? '15px' : '16px',
        ...customStyles,
      }}
    >
      {label !== undefined && (
        <span
          className={
            theme === 'white' ? classes(styles.whiteTheme, styles.label) : styles.label
          }
        >
          {label}
          {t('colon')}
        </span>
      )}
      <div
        className={
          theme === 'white' ? classes(styles.whiteThemeText, styles.text) : styles.text
        }
      >
        {value}
      </div>
    </div>
  );
};

export default KeyValueRow;
