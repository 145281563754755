import React from 'react';
import { useTranslation, useTranslationX } from 'i18n';

import { classes, renderComponent } from 'utils/components';
import { Grid, Modal, Row, ScrollPanel } from 'components/containers';
import { KeyValueRow, Loader, WrapperCard } from 'components/elements';
import { appendDots } from 'utils/stringUtils';
import { CheckCircle, X } from 'components/icons';
import { getMasked } from 'utils/inputMask';

import Tippy from '@tippyjs/react';
import { useRequest } from 'apis';
import {
  ProjectSourceItem,
  ProjectSourceItemDetail,
} from 'pages/finance/ConciliationSourceList/types';
import { ProjectAccountsSourceItemDetail } from 'pages/finance/ConciliationSourceList';

import styles from './SourceCard.module.scss';

type Props = {
  item: ProjectSourceItem;
};

export const SourceCard: React.FC<Props> = ({ item }) => {
  const { number, slug, debitValue, creditValue, pendingCredit, pendingDebit, id } = item;
  const { tx } = useTranslationX('expenses', 'finance');
  const { t } = useTranslation('translation');

  const request = useRequest();

  const [showDetails, setShowDetails] = React.useState(false);
  const [detailsData, setDetailsData] = React.useState<ProjectSourceItemDetail[]>();

  function onCloseDetails() {
    setShowDetails(false);
  }

  function onClick() {
    setShowDetails(true);
    fetchPartsDetails();
  }

  function fetchPartsDetails() {
    const source = request<ProjectSourceItemDetail[]>({
      url: `/financeiro/conciliacao/contas/projetos/${id}`,
      method: 'GET',
      onSuccess: setDetailsData,
    });

    return () => source.cancel();
  }

  return (
    <>
      <WrapperCard maxHeight="160px" padding="16px 18px">
        <div className={styles.expenseCard}>
          <p className={styles.title}>
            <Tippy
              content={tx('identification', {
                number: number,
                sigla: slug,
              })}
              animation="perspective"
              touch={false}
              className={styles.popup}
            >
              <span>
                {appendDots(
                  tx('identification', {
                    number: number,
                    sigla: slug,
                  }),
                  30
                )}
              </span>
            </Tippy>
          </p>
          <Grid spacing={0} gap={8}>
            <Row>
              <div className={classes(styles.tag, styles.credit)}>{tx('credit')}</div>
              <div className={classes(styles.tag, styles.debit)}>{tx('debit')}</div>
            </Row>

            <Row>
              <KeyValueRow
                label={tx('pending')}
                value={pendingCredit}
                size="small"
                margin="0"
              />
              <KeyValueRow label={tx('pending')} value={pendingDebit} margin="0" />
            </Row>
            <Row>
              <KeyValueRow
                label={tx('value')}
                value={`${t('currency')} ${getMasked((creditValue || 0).toFixed(2), {
                  pattern: 'currency',
                })}`}
                margin="0"
              />
              <KeyValueRow
                label={tx('value')}
                value={`${t('currency')} ${getMasked((debitValue || 0).toFixed(2), {
                  pattern: 'currency',
                })}`}
                margin="0"
              />
            </Row>
          </Grid>
          <div className={styles.footer} onClick={onClick}>
            <div className={styles.order}>
              {renderComponent(CheckCircle, { size: 0.8 })}
              <span className={styles.label}>{tx('view')}</span>
            </div>
          </div>
        </div>
      </WrapperCard>

      <Modal
        visible={showDetails}
        onClose={onCloseDetails}
        onBlur={onCloseDetails}
        width={1200}
        minHeight={250}
      >
        {detailsData ? (
          <div className={styles.modalContent}>
            <div className={styles.modalHeader}>
              <h2>
                {tx('identification', {
                  number: number,
                  sigla: slug,
                })}
              </h2>
              <span onClick={onCloseDetails}>
                <X size={2} />
              </span>
            </div>

            <ScrollPanel>
              <div className={styles.grid}>
                {detailsData?.map(item => (
                  <ProjectAccountsSourceItemDetail key={item.id} item={item} />
                ))}
              </div>
            </ScrollPanel>
          </div>
        ) : (
          <Loader />
        )}
      </Modal>
    </>
  );
};

export default SourceCard;
