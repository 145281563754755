import React, { useCallback } from 'react';

import { Dialog, Grid, Row } from 'components/containers';
import { useTranslationX, useTranslation } from 'i18n';

import { Button, PlainField } from 'components/elements';
import { BalanceStatus } from 'pages/finance/types';
import { getMasked } from 'utils/inputMask';

import { RequestConfig, useRequest } from 'apis';
import { messageService } from 'services';

type Item = {
  id: number;
  beneficiario: string;
  tipo: string;
  situacao: BalanceStatus;
  valor: number;
  valor_conciliado: number;
  saldo_a_conciliar: number;
};

type Props = {
  uc: Item;
  visible: boolean;
  onClose: () => void;
  onRefresh: () => void;
  lancamentoId: number;
};

const FinishRHSolicitationModal: React.FC<Props> = props => {
  const { uc, visible, onClose, lancamentoId, onRefresh } = props;

  const { tx } = useTranslationX('expenses', 'finance');
  const { t } = useTranslation('translation');

  const parseValueToCurrency = (value: number) => {
    const parsedValue =
      t('currency') +
      ' ' +
      getMasked(Number(value).toFixed(2), {
        pattern: 'currency',
      });
    return parsedValue;
  };

  const request = useRequest();

  const handleRequest = useCallback(
    <T,>(config: RequestConfig<T>): Promise<T> =>
      new Promise<T>((resolve, reject) => {
        const source = request<T>({
          ...config,
          onError: err => {
            const { error } = err.response?.data as { error?: string };
            messageService.error(error ?? tx('error'), { duration: 2000 });
            config.onError?.(err);
            reject(new Error('Generic error'));
          },
          onSuccess: (response: T) => {
            config.onSuccess?.(response);
            resolve(response);
          },
        });
        return () => source.cancel();
      }),
    [request, tx]
  );

  const handleConfirm = async () => {
    const itemId = uc.id;
    const path = `/financeiro/conciliacao/despesas/contas-projeto/solicitacao/${itemId}/finalizar`;
    try {
      await handleRequest({
        url: path,
        method: 'POST',
        data: {
          lancamentoId,
          tipo: 'rh',
        },
      });
      onClose();
      onRefresh();
    } catch (ignore) {}
  };

  return (
    <Dialog
      title={tx('finishSolicitation.title')}
      visible={visible}
      onClose={onClose}
      closeButton
      width={787}
      titleAlign="center"
      actions={
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type="secondary" onClick={onClose}>
            {tx('cancel')}
          </Button>
          <Button onClick={handleConfirm}>{tx('confirm')}</Button>
        </div>
      }
    >
      <Grid spacing={8}>
        <Row width={[0.1, 0.9]}>
          <div />
          <PlainField
            label={tx('finishSolicitation.question')}
            value={tx('finishSolicitation.consequence')}
          />
        </Row>
        <Row width={[0.05, 0.5, 0.25, 0.2]}>
          <div />
          <PlainField label={tx('beneficiary')} value={uc.beneficiario} />
          <PlainField
            label={tx('value')}
            type="html"
            value={`<strong>${parseValueToCurrency(uc.valor)}</strong>`}
          />
          <PlainField
            label={tx('conciliatedValue')}
            type="html"
            value={`<strong>${parseValueToCurrency(
              uc.valor - uc.saldo_a_conciliar
            )}</strong>`}
          />
        </Row>
      </Grid>
    </Dialog>
  );
};

export default FinishRHSolicitationModal;
