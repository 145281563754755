import React from 'react';

import { Grid, Row } from 'components/containers';
import { WrapperCard } from 'components/elements';
import { Form, TextField } from 'components/form';
import { FormInstance } from 'components/form/types';
import { useTranslationX } from 'i18n';
import { useParams } from 'react-router-dom';

type Props = {
  onDone: VoidFunction;
  formRef: React.RefObject<FormInstance>;
  estimativaCurta?: number;
  estimativaLonga?: number;
};

const EstimativeForm = ({ formRef, onDone, estimativaCurta, estimativaLonga }: Props) => {
  const { id } = useParams<{ id: string }>();

  const { tx } = useTranslationX('accounts', 'finance');

  return (
    <>
      <WrapperCard maxWidth="100%" padding="30px 32px">
        <Form
          baseUrl={`financeiro/contas-mae/${id}/estimativas`}
          ref={formRef}
          onDone={onDone}
          submit={{
            method: 'put',
          }}
        >
          <Grid spacing={0}>
            <Row>
              <TextField
                name="estimativaCurta"
                label={tx('curtoPrazo')}
                value={estimativaCurta}
              />
              <TextField
                name="estimativaLonga"
                label={tx('longoPrazo')}
                value={estimativaLonga}
              />
            </Row>
          </Grid>
        </Form>
      </WrapperCard>
    </>
  );
};

export default EstimativeForm;
