import React, { createRef, useCallback, useMemo, useRef, useState } from 'react';
import { Dialog } from 'components/containers';
import { Button } from 'components/elements';
import { useTranslation, useTranslationX } from 'i18n';
import { currencyDefault } from 'components/form/helpers';
import {
  renderColorizedStatus,
  renderColorizedValue,
  statusOptions,
} from 'pages/finance/utils';
import { BalanceStatus } from 'pages/finance/types';
import { useTransactionActions } from './useTransactionActions';
import { ColumnConfig } from 'components/containers/TableList/types';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'pages/finance/ConciliationSourceList/types';
import TransactionList from './TransactionList';
import { messageService } from 'services';

import { FormInstance } from 'components/form/types';
import { Instance } from 'components/containers/TableList/TableList';
import AdditionalCreditForm from './AdditionalCreditForm/AdditionalCreditForm';

type Props = {
  onReload: () => void;
};

type Item = {
  id: number;
  origem: string;
  descricao: string;
  tipo: string;
  situacao: BalanceStatus;
  tem_vinculo: boolean;
  eh_solicitacao: boolean;
  valor: number;
  valor_conciliado: number;
  saldo_a_conciliar: number;
};

const CreditList: React.FC<Props> = props => {
  const { onReload } = props;
  const { lancamento_id: id } = useParams<RouteParams>();
  const { tx } = useTranslationX('expenses', 'finance');
  const { t } = useTranslation('translation');

  const table = useRef<Instance>();
  const formRef = createRef<FormInstance>();
  const createAccountFormRef = createRef<any>();
  const [showForm, setShowForm] = useState(false);

  const handleRefresh = useCallback(() => {
    onReload();
    table.current?.resetAfterDelete();
  }, [onReload]);

  const closeForm = () => {
    setShowForm(false);
  };
  const openForm = () => {
    setShowForm(true);
  };

  const onSubmitNewAdditionalCredit = () => {
    messageService.success(tx('additionalCreditSuccess'), { duration: 2000 });
    closeForm();
    handleRefresh();
  };

  const transactionActions = useTransactionActions({
    onReload: handleRefresh,
    id: Number(id),
  });
  const getActions = useCallback(
    (data: Item, index: number) => transactionActions.getActions(data, index, formRef),
    [transactionActions, formRef]
  );

  const config: ColumnConfig<any> = [
    {
      title: tx('type'),
      field: 'tipo',
      align: 'center',
      weight: 0.1,
      search: true,
    },
    {
      title: tx('description'),
      field: 'descricao',
      weight: 0.26,
      align: 'left',
      search: true,
      type: 'html',
      parse: value => `<strong>${value}<strong/>`,
    },
    {
      title: tx('origin'),
      field: 'origem',
      weight: 0.12,
      headerAlign: 'left',
      align: 'center',
      search: true,
    },
    {
      title: tx('situation'),
      field: 'situacao',
      weight: 0.09,
      type: 'html',
      search: true,
      searchType: 'status',
      metadata: {
        options: statusOptions(tx),
      },
      parse: renderColorizedStatus,
    },
    {
      title: tx('value'),
      field: 'valor',
      type: 'html',
      headerAlign: 'right',
      align: 'right',
      weight: 0.09,
      search: true,
      searchType: 'range',
      parse: (value: number, data: Item) => renderColorizedValue(value, data.situacao, t),
    },
    {
      title: tx('pendingBalance'),
      field: 'saldo_a_conciliar',
      search: true,
      weight: 0.1,
      appendDots: false,
      align: 'right',
      headerAlign: 'right',
      type: 'html',
      searchType: 'range',
      parse: (value: number, data: Item) => renderColorizedValue(value, data.situacao, t),
    },
    {
      title: tx('conciliatedValue'),
      field: 'valor_conciliado',
      search: true,
      weight: 0.1,
      type: 'input',
      appendDots: false,
      align: 'right',
      headerAlign: 'right',
      searchType: 'range',
      fieldProps: (item: Item) => {
        return {
          ...currencyDefault(
            Number(item.saldo_a_conciliar + item.valor_conciliado).toFixed(2)
          ),
          type: item.situacao === 'CCI' && !item.tem_vinculo ? 'readonly' : undefined,
        };
      },
    },
    {
      title: '',
      field: 'actions',
      weight: 0.05,
      type: 'actions',
      search: false,
      parse: (_, data: Item, index: number) => getActions(data, index),
    },
  ];

  const extraButtons = useMemo(() => {
    return [
      {
        label: tx('button.newCredit'),
        type: 'green',
        action: openForm,
      },
    ];
  }, [tx]);

  return (
    <>
      <TransactionList
        onRefresh={handleRefresh}
        formRef={formRef}
        tableRef={table}
        config={config}
        baseUrl={`/financeiro/conciliacao/despesas/lancamentos/${id}/creditos`}
        listHeaderTitle={tx('expenseType.credito')}
        extraButtons={extraButtons}
      />
      <Dialog
        title={tx('newCredit')}
        visible={showForm}
        onClose={closeForm}
        closeButton
        width={800}
        actions={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="secondary" onClick={closeForm}>
              {tx('cancel')}
            </Button>
            <Button onClick={() => createAccountFormRef.current?.context.submit()}>
              {tx('save')}
            </Button>
          </div>
        }
      >
        <AdditionalCreditForm
          formRef={createAccountFormRef}
          onDone={onSubmitNewAdditionalCredit}
        />
      </Dialog>
    </>
  );
};

export default CreditList;
