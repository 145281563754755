import { ScrollPanel } from 'components/containers';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import ItemTopSubMenu from './ItemTopSubMenu/ItemTopSubMenu';

import styles from './TopSubMenu.module.scss';

export type TopSubMenuNode = {
  label?: string;
  path: string;
  adornment?: JSX.Element;
  icon: React.FC<{ size: number }>;
  hasChanges?: boolean;
};

type Props = {
  blocked?: boolean;
  child: TopSubMenuNode[];
  extra?: React.ReactNode;
};

const TopSubMenu: React.FC<Props> = ({ child, blocked = false, extra }) => {
  const { url } = useRouteMatch();

  const { pathname } = useLocation();
  const [selected, setSelected] = useState<string>(child[0].path);

  useEffect(() => {
    const lastPath = pathname.split('/')[pathname.split('/').length - 1];

    const existsPath = child.find(item => item.path === lastPath);
    if (existsPath) {
      setSelected(lastPath);
    }
  }, [child, pathname]);

  return (
    <div className={styles.subMenuHorizontal}>
      <div className={styles.wrapper}>
        <ScrollPanel>
          <ul>
            {child.map(({ label, path, adornment, icon, hasChanges }, index) => {
              const blockedNode = selected !== path && blocked;
              const Item = (
                <ItemTopSubMenu
                  key={index}
                  onClick={() => setSelected(path)}
                  icon={icon}
                  blocked={blockedNode}
                  label={label || ''}
                  selected={selected === path}
                  adornment={adornment}
                  hasChanges={hasChanges}
                />
              );
              return (
                <Fragment key={index}>
                  {blockedNode && <div style={{ cursor: 'not-allowed' }}>{Item}</div>}
                  {!blockedNode && <Link to={`${url}/${path}`}>{Item}</Link>}
                </Fragment>
              );
            })}
          </ul>
        </ScrollPanel>

        <div>{extra}</div>
      </div>
    </div>
  );
};
export default TopSubMenu;
