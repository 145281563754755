import React, { useEffect } from 'react';
import { useFetch, useRequest } from 'apis';
import { useDynamicHeader } from 'components/containers/DynamicHeader';
import { Button, Loader, WrapperCard } from 'components/elements';
import { useTranslationX } from 'i18n';
import { useLocation, useParams } from 'react-router-dom';
import {
  Dialog,
  Modal,
  ModalPDFViewer,
  ScrollPanel,
  TableList,
} from 'components/containers';
import { ColumnConfig } from 'components/containers/TableList/types';
import { Instance, ListType } from 'components/containers/TableList/TableList';

import { getMasked } from 'utils/inputMask';
import { Config, Eye, PDF, X } from 'components/icons';

import { FormInstance } from 'components/form/types';
import { messageService } from 'services';
import {
  CancelMotherAccountRequest,
  EstimativeCard,
  EstimativeForm,
  TransferForm,
  AporteForm,
} from '.';

import styles from './MotherAccountView.module.scss';
import {
  ProjectAccount,
  ProjectAccountItem,
} from '../MotherAccountsForm/MotherAccountsForm';

type MotherAccountViewProps = {
  id: number;
  nomeContaMae: string;
  banco: string;
  agencia: string;
  numero: string;
  fonteContaMae: string;
  estimativaCurta?: number;
  estimativaLonga?: number;
  status: string;
  saldo: number;
  projetoContas: ProjectAccountItem[];
};

export type RequestStatus = 'SOL' | 'EXE' | 'CAN';

export const requestStatusColor: { [key in RequestStatus]: string } = {
  SOL: '#C98CFF',
  EXE: '#3fc75cd1',
  CAN: '#ca1a14e6',
};

const MotherAccountView = () => {
  const { id } = useParams<{ id: string }>();
  const { tx } = useTranslationX('accounts', 'finance');
  const { t } = useTranslationX('translation');

  const table = React.useRef<Instance>();
  const request = useRequest();
  const location = useLocation();

  const createAccountFormRef = React.createRef<FormInstance>();

  const [pdfData, setPdfData] = React.useState<string | ArrayBuffer | null>(null);
  const [showPdfModal, setShowPdfModal] = React.useState(false);
  const [showCancelModal, setShowCancelModal] = React.useState(false);

  const [showEstimativesModal, setShowEstimativesModal] = React.useState(false);
  const [showProjectAccounts, setShowProjectAccounts] = React.useState(false);
  const [showSolicitationModal, setShowSolicitationModal] = React.useState<{
    open: boolean;
    type: 'aporte' | 'transferência';
  }>({
    open: false,
    type: 'aporte',
  });

  const { data, reload } = useFetch<MotherAccountViewProps>(
    `/financeiro/contas-mae/${id}`
  );

  const {
    status,
    numero,
    nomeContaMae,
    agencia,
    fonteContaMae,
    estimativaCurta = 30,
    estimativaLonga = 60,
    saldo = 0,
    projetoContas,
  } = data ?? {};

  const { setBreadcrumbs, setFooter, setVisibility, clear } = useDynamicHeader();

  useEffect(() => {
    if (!data) {
      return;
    }
    setBreadcrumbs(['finance', 'motherAccounts']);
    setFooter(
      `${nomeContaMae} ${tx('agencyHeader', { value: agencia })} ${tx('accountHeader', {
        value: numero,
      })} ${tx('sourceHeader', { value: fonteContaMae })}`
    );

    setVisibility(true);
    return () => {
      clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear, setBreadcrumbs, setVisibility, setFooter, data]);

  if (!data) return <Loader />;

  function downloadDocument(id: number) {
    const source = request({
      url: `/financeiro/contas-mae/solicitacao/${id}/pdf`,
      method: 'GET',
      responseType: 'blob',
      onSuccess: (blob: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          let base64data = reader.result;
          setPdfData(base64data);
          openPDFModal();
        };
      },
      onError: data => console.error(data),
    });

    return () => source.cancel();
  }

  let config: ColumnConfig<ListType> = [
    {
      title: tx('type'),
      field: 'tipo',
      align: 'left',
      width: 0,
      capitalize: true,
      search: true,
    },
    {
      title: tx('officeNumber'),
      field: 'num_oficio',
      weight: 0.6,
      search: true,
    },
    {
      title: tx('projectNumber'),
      field: 'projeto',
      weight: 0.8,
      search: true,
    },
    {
      title: tx('date'),
      field: 'data',
      type: 'date',
      weight: 0.6,
      search: true,
      searchType: 'date',
    },
    {
      title: tx('projectAccountData'),
      field: 'dados_conta',
      weight: 1.2,
      search: true,
    },
    {
      title: tx('status'),
      field: 'estado',
      type: 'tag',
      weight: 0.8,
      search: true,
      searchType: 'status',
      metadata: {
        options: Object.keys(requestStatusColor).map(status => {
          return {
            value: status,
            text: tx(`requestStatus.${status}`),
          };
        }),
      },
      parse: status => {
        return {
          value: tx(`requestStatus.${status}`),
          bgColor: requestStatusColor[status as RequestStatus],
        };
      },
    },
    {
      title: tx('value'),
      field: 'valor',
      weight: 0.8,
      search: true,
      type: 'html',
      searchType: 'range',
      parse: value =>
        value
          ? `<strong>${t('currency')}  ${getMasked(value.toFixed(2), {
              pattern: 'currency',
            })}</strong>`
          : '',
    },
    {
      title: tx('actions.title'),
      weight: 0.8,
      align: 'left',
      field: 'actions',
      type: 'actions',
      sort: false,
      parse(_, data) {
        const actions = [];
        if (data.num_oficio) {
          actions.push({
            icon: PDF,
            color: '#333333',
            identifier: status,
            label: tx('downloadOffice'),
            onClick: () => downloadDocument(data.id),
          });
        }
        if (data.estado === 'SOL') {
          actions.push({
            icon: X,
            color: '#E61616',
            label: tx('cancelRequest'),
            identifier: 'cancelRequest',
            onClick: () => {
              openCancelModal();
              location.state = {
                id: data.id,
                documentId: data.num_oficio,
                requestType: data.tipo,
              };
            },
          });
        }
        return actions;
      },
    },
  ];

  function openCancelModal() {
    setShowCancelModal(true);
  }

  function openEstimativesModal() {
    setShowEstimativesModal(true);
  }

  function closeEstimativesModal() {
    setShowEstimativesModal(false);
  }

  function showProjectAccountsModal() {
    setShowProjectAccounts(true);
  }

  function hideProjectaccountsModal() {
    setShowProjectAccounts(false);
  }

  function openSolicitationModal(type: 'aporte' | 'transferência') {
    setShowSolicitationModal({
      open: true,
      type,
    });
  }

  function closeSolicitationModal() {
    setShowSolicitationModal(value => ({
      open: false,
      type: value.type,
    }));
  }

  function closeCancelModal() {
    setShowCancelModal(false);
  }

  function openPDFModal() {
    setShowPdfModal(true);
  }

  function closePDFModal() {
    setShowPdfModal(false);
  }

  function onDoneSolicitation() {
    messageService.success(tx('requestCreated'), { duration: 2000 });
    closeSolicitationModal();
    table.current?.resetAfterDelete();
    reload();
  }

  return (
    <ScrollPanel vBar={{ visible: false }}>
      <div className={styles.accountDetail}>
        <div className={styles.header}>
          <div className={styles.totalCard}>
            <div>
              <span>{tx('currentBalance')}</span>
              <p>{`${t('currency')} ${getMasked(Number(saldo).toFixed(2), {
                pattern: 'currency',
              })}`}</p>
            </div>
          </div>

          <EstimativeCard
            title={tx('basedApprovedSolicitations', {
              days: estimativaCurta,
            })}
            expensesLabel={tx('approvedExpenses')}
            balanceLabel={tx('futureBalance')}
            balance={0}
            incomes={0}
          />
          <EstimativeCard
            title={tx('basedDisbursementSchedule', {
              days: estimativaLonga,
            })}
            expensesLabel={tx('plannedExpenses')}
            balanceLabel={tx('futureBalance')}
            balance={0}
            incomes={0}
          />
          <EstimativeCard
            title={tx('basedReconciledRequests', {
              days: estimativaLonga,
            })}
            expensesLabel={tx('conciliatedExpenses')}
            balanceLabel={tx('futureBalance')}
            balance={-500000}
            incomes={50000}
          />
          <div className={styles.configButton}>
            <Button
              icon={Config}
              type="secondary"
              style={{
                root: {
                  background: 'transparent',
                  border: '1px solid #37A64A',
                  width: 170,
                },
                text: { color: '#37A64A', paddingLeft: 15 },
              }}
              onClick={openEstimativesModal}
            >
              {tx('estimatives')}
            </Button>
            <Button
              icon={Eye}
              type="secondary"
              style={{
                root: {
                  background: 'transparent',
                  border: '1px solid #37A64A',
                  width: 170,
                },
                text: { color: '#37A64A', paddingLeft: 15 },
              }}
              onClick={showProjectAccountsModal}
            >
              {tx('projectAccounts')}
            </Button>
          </div>
        </div>
        <Dialog
          title={tx('projectAccounts')}
          visible={showProjectAccounts}
          onClose={hideProjectaccountsModal}
          closeButton
          width={800}
        >
          <WrapperCard padding="15px 0px 20px 20px" maxWidth="calc(100% - 40px)">
            <ScrollPanel
              vBar={{ overlay: true }}
              style={{ content: { maxHeight: '400px' } }}
            >
              <div className={styles.grid}>
                {projetoContas?.map(conta => (
                  <ProjectAccount account={conta} />
                ))}
              </div>
            </ScrollPanel>
          </WrapperCard>
        </Dialog>
        <TableList
          insideHeader={
            <div className={styles.insideHeader}>
              <h2>{tx('solicitationList')}</h2>
              <div>
                <Button onClick={() => openSolicitationModal('transferência')}>
                  {tx('requestTransfer')}
                </Button>
                <Button onClick={() => openSolicitationModal('aporte')}>
                  {tx('requestValue')}
                </Button>
              </div>
            </div>
          }
          baseUrl={`/financeiro/contas-mae/${id}/solicitacoes`}
          style={{
            root: {
              maxWidth: 'calc(100% - 20px)',
              maxHeight: 'calc(100vh - 150px)',
            },
          }}
          notFoundProps={{}}
          textSize="small"
          theme="light"
          config={config}
          reference={table}
          editIcon={null}
          refreshesOn={95}
          pageSize={20}
          filter
          infinityScroll
        />
      </div>

      <CancelMotherAccountRequest
        visible={showCancelModal}
        onClose={closeCancelModal}
        onSubmit={() => table.current?.resetAfterDelete()}
      />
      <Dialog
        title={tx('configureEstimativas')}
        visible={showEstimativesModal}
        onClose={closeEstimativesModal}
        closeButton
        width={800}
        actions={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="secondary" onClick={closeEstimativesModal}>
              {tx('cancel')}
            </Button>
            <Button onClick={() => createAccountFormRef.current?.context.submit()}>
              {tx('save')}
            </Button>
          </div>
        }
      >
        <EstimativeForm
          formRef={createAccountFormRef}
          onDone={() => {
            messageService.success(tx('estimativeUpdated'), { duration: 2000 });
            closeEstimativesModal();
            table.current?.resetAfterDelete();
            reload();
          }}
          estimativaCurta={data.estimativaCurta}
          estimativaLonga={data.estimativaLonga}
        />
      </Dialog>
      <Dialog
        title={tx('request', { value: showSolicitationModal.type })}
        visible={showSolicitationModal.open}
        onClose={closeSolicitationModal}
        closeButton
        width={800}
        actions={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="secondary" onClick={closeSolicitationModal}>
              {tx('cancel')}
            </Button>
            <Button onClick={() => createAccountFormRef.current?.context.submit()}>
              {tx('save')}
            </Button>
          </div>
        }
      >
        {showSolicitationModal.type === 'aporte' && (
          <AporteForm formRef={createAccountFormRef} onDone={onDoneSolicitation} />
        )}
        {showSolicitationModal.type === 'transferência' && (
          <TransferForm
            formRef={createAccountFormRef}
            onDone={onDoneSolicitation}
            projetoContas={data.projetoContas}
          />
        )}
      </Dialog>
      <Modal visible={showPdfModal} onBlur={closePDFModal} onClose={closePDFModal}>
        <ModalPDFViewer
          pdfData={pdfData}
          onClose={closePDFModal}
          title={tx('oficioDocument')}
        />
      </Modal>
    </ScrollPanel>
  );
};

export default MotherAccountView;
