import React from 'react';
import { useTranslationX } from 'i18n';
import { useFetch } from 'apis';
import { Loader, NotFoundMessage } from 'components/elements';
import { ProjectMotherAccountItem } from '../../types';
import { FaMoneyBill } from 'react-icons/fa';
import { ScrollPanel } from 'components/containers';

import styles from './MotherAccounts.module.scss';
import { ProjectMotherAccountCard } from '../..';

const MotherAccountsTab: React.FC = () => {
  const { tx } = useTranslationX('expenses', 'finance');

  const { data } = useFetch<ProjectMotherAccountItem[]>(
    '/financeiro/conciliacao/contas/contas-mae'
  );

  if (data === undefined) {
    return <Loader size={60} />;
  }

  if (data.length === 0) {
    return (
      <NotFoundMessage
        title={tx('notFound.title')}
        description={tx('notFound.description')}
        icon={FaMoneyBill}
      />
    );
  }

  return (
    <ScrollPanel vBar={{ overlay: true }} style={{}}>
      <div className={styles.grid}>
        {data?.map((item, index) => (
          <ProjectMotherAccountCard key={index} item={item} />
        ))}
      </div>
    </ScrollPanel>
  );
};

export default MotherAccountsTab;
