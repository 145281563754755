import React, { useMemo } from 'react';

import { useTranslationX } from 'i18n';
import { TableData } from 'components/containers';
import { FieldOption, FormContextValue } from 'components/form/types';
import { TotalLabel } from 'pages/proposal';

import styles from './Financial.module.scss';

// Vinculo de instituição = Outros.
const VALUE_DEFAULT_VINCULO_INSTITUICAO = 2;

type Props = {
  parteId: number;
  fonteRecursoStatus: number;
};

const HumanResources = (props: Props) => {
  const { parteId, fonteRecursoStatus } = props;
  const { tx } = useTranslationX('hr', 'update_wp');

  const groupBy = useMemo(
    (): (string | number)[][] => [
      ['fonteRecurso', Number(parteId)],
      ['fonteRecursoStatus', Number(fonteRecursoStatus)],
    ],
    [parteId, fonteRecursoStatus]
  );

  const onObservedFieldChange = (
    id: string,
    value: any,
    form: FormContextValue,
    params: string[] | undefined,
    ...stateFunctions: React.Dispatch<React.SetStateAction<any>>[]
  ) => {
    const strings = id.split('.');

    if (strings.length > 0) {
      const vinculoTrabalhoFieldName =
        strings.slice(0, strings.length - 1).join('.') + '.vinculoTrabalho';
      const metadata = form.getFieldMetadata(vinculoTrabalhoFieldName);
      const options = metadata.options as FieldOption[];

      if (
        options?.find(
          (o: FieldOption) =>
            o.value === value &&
            (o.value === 2 || o.value === 3 || o.value === 4) /* CLTs e Estagio */
        )
      ) {
        if (
          strings &&
          strings.length > 0 &&
          strings[strings.length - 1] === 'categoriaBolsa'
        ) {
          stateFunctions[0](true);
          form.setFieldValue(id, undefined, true);
        }

        if (
          strings &&
          strings.length > 0 &&
          strings[strings.length - 1] === 'vinculoInstituicao'
        ) {
          stateFunctions[0](true);
          form.setFieldValue(id, VALUE_DEFAULT_VINCULO_INSTITUICAO, true);
        }
      } else {
        stateFunctions[0](false);
      }
    }
  };

  return (
    <div
      className={styles.financial}
      style={{
        minWidth: '88vw',
      }}
    >
      <TotalLabel tableName="rh" columnName="total" groupBy={groupBy} />

      <div className={styles.table}>
        <TableData
          name="rh"
          groupBy={groupBy}
          hiddenFields={[
            'mesesExecutados',
            'duracaoBruta',
            'mesInicio',
            'mesExecucao',
            'fonteRecursoStatus',
          ]}
          textSize="small"
          config={[
            {
              title: tx('institution'),
              field: 'vinculoInstituicao',
              align: 'left',
              type: 'select',
              weight: 0.8,
              fieldProps: {
                onObservedFieldChange,
                observeFields: ['vinculoTrabalho'],
              },
            },
            { title: tx('position'), field: 'cargo', weight: 1.2 },
            {
              title: tx('relationship'),
              field: 'vinculoTrabalho',
              type: 'select',
              weight: 0.8,
            },
            {
              title: tx('category'),
              field: 'categoriaBolsa',
              type: 'select',
              weight: 1.2,
              fieldProps: {
                onObservedFieldChange,
                observeFields: ['vinculoTrabalho'],
              },
            },
            {
              title: tx('salary'),
              field: 'remuneracao',
              fieldProps: { align: 'right' },
              weight: 0.7,
              align: 'right',
            },
            {
              title: tx('taxes'),
              field: 'encargos',
              type: 'computed',
              align: 'center',
              fieldProps: {
                align: 'right',
                expression: 'remuneracao * vinculoTrabalho[0] / 100 + vinculoTrabalho[1]',
              },
              weight: 0.7,
            },
            {
              title: tx('duration'),
              field: 'duracao',
              type: 'computed',
              align: 'center',
              fieldProps: {
                align: 'center',
                expression: 'mesesDisponiveis + mesesSolicitados + mesesExecutados',
              },
              metadata: {
                rules: {
                  min: 1,
                  required: true,
                  customMessage: tx('emptyDuration'),
                },
              },
              weight: 0.5,
            },
            {
              title: tx('available-duration'),
              field: 'mesesDisponiveis',
              forceMutability: true,
              fieldProps: { align: 'center' },
              metadata: {
                rules: {
                  min: 0,
                },
              },
              align: 'right',
              weight: 0.5,
            },
            {
              title: tx('total'),
              field: 'total',
              type: 'computed',
              align: 'right',
              metadata: { rules: { mask: { pattern: 'currency' } } },
              fieldProps: {
                expression: '(remuneracao + encargos) * duracao',
                align: 'right',
                omit: true,
              },
              weight: 0.7,
            },
          ]}
          style={{
            table: {
              maxWidth: '95%',
            },
          }}
        />
      </div>
    </div>
  );
};

export default HumanResources;
