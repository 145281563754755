import React from 'react';

import styles from './PercentCardWithIcon.module.scss';
import { classes } from 'utils/components';

interface PercentProps {
  label: string;
  value?: number | string;
  percent?: number;
  icon: React.ElementType;
  onClick?: () => void;
  theme?: 'thin' | 'normal';
}

const PercentCardWithIcon: React.FC<PercentProps> = ({
  label,
  value,
  percent,
  icon: Icon,
  onClick,
  theme = 'normal',
}) => {
  return (
    <div
      className={classes(styles.percentProposalContainer, styles[theme])}
      onClick={onClick}
    >
      <div className={styles.wrapper}>
        <p className={styles.label}>{label}</p>
        <div>
          <p>{value}</p>
          {percent && <span className={styles.percent}>+ {percent}% </span>}
        </div>
      </div>
      <div className={styles.percentLogo}>
        <Icon size={2.5} />
      </div>
    </div>
  );
};

export default PercentCardWithIcon;
