import { useCallback, useEffect, useRef } from 'react';

import { useRequest } from 'apis';
import { FormValues, Patch, PatchResponse } from './types';
import { useTimer } from 'components/hooks';
import { call, isNil } from 'utils';
import { Queue } from 'utils/Queue';

const useAutoSave = (
  interval: number,
  url: string | undefined,
  data: FormValues,
  onError?: (path: string, value: any) => void,
  onSuccess?: (
    path: string,
    value: any,
    needsRefresh?: boolean,
    type?: Patch['type']
  ) => void
) => {
  const enabled = Boolean(interval);
  const { timeout, startTimer } = useTimer(enabled ? interval : 0);

  const request = useRequest();
  const queue = useRef(new Queue<Patch>());
  const sync = useRef(false);

  const updateSync = useCallback(() => {
    if (queue.current.isEmpty()) {
      sync.current = false;
      startTimer();
      return;
    }

    sync.current = true;
    const patch = queue.current.dequeue();

    request<PatchResponse[]>({
      url,
      method: 'PATCH',
      data: [patch],
      onSuccess: res => {
        if (sync.current && patch?.callback) {
          patch.callback(res[0]);
          if (typeof onSuccess === 'function') {
            call(onSuccess, patch?.path, patch?.value, res[0].needsRefresh);
          }
        }
      },
      onError: () => {
        if (sync.current) {
          call(patch?.callback);
          if (typeof onError === 'function') {
            call(onError, patch?.path, patch?.value);
          }
        }
      },
      onComplete: () => {
        if (sync.current) {
          updateSync();
        }
      },
    });
  }, [request, startTimer, url, onSuccess, onError]);

  const updateAsync = useCallback(
    (handleResponse = true) => {
      if (!enabled) {
        return;
      }

      const patches: Patch[] = [];

      for (const key in data) {
        const field = data[key];

        if (field.valid && field.unsaved) {
          const index = key.lastIndexOf('.');
          const patch: Patch = { type: 'replace', path: key, value: field.value };

          if (index !== -1) {
            const id = data[`${key.substring(0, index)}.id`];

            if (id && !isNil(id.value)) {
              patch.query = { id: id.value as string | number };
            }
          }

          patches.push(patch);
        }
      }

      if (patches.length) {
        if (!url) {
          if (typeof onSuccess === 'function') {
            for (const patch of patches) {
              data[patch.path].unsaved = false;
              onSuccess(patch.path, patch.value, false, patch.type);
            }
          }
          return;
        }

        const source = request<PatchResponse[]>({
          url,
          method: 'PATCH',
          data: patches,
          onSuccess: handleResponse
            ? res => {
                for (const item of res) {
                  const field = data[item.path];

                  if (field) {
                    if (item.status === 'success') {
                      if (field.value === item.value) {
                        field.unsaved = false;
                      }
                      if (typeof onSuccess === 'function') {
                        onSuccess(item.path, item.value, item.needsRefresh, item.type);
                      }
                    } else if (typeof onError === 'function') {
                      onError(item.path, item.value);
                    }
                  }
                }
              }
            : undefined,
        });

        return () => source.cancel();
      }
    },
    [data, enabled, onError, onSuccess, request, url]
  );

  useEffect(() => {
    if (timeout) {
      return updateAsync();
    }
  }, [updateAsync, timeout]);

  useEffect(
    () => () => {
      sync.current = false;
      updateAsync(false);
    },
    [updateAsync]
  );

  const notify = useCallback(
    (patch?: Patch) => {
      if (patch) {
        queue.current.enqueue(patch);

        if (!sync.current) {
          updateSync();
        }
      } else if (!sync.current) {
        startTimer();
      }
    },
    [startTimer, updateSync]
  );

  return notify;
};

export default useAutoSave;
