import React, { useEffect, useState } from 'react';

import { Grid, Row } from 'components/containers';
import { WrapperCard } from 'components/elements';
import {
  AutoCompute,
  FieldSet,
  Form,
  HiddenField,
  Select,
  TextField,
  useFormObserver,
} from 'components/form';
import {
  FieldObserverResult,
  FormInstance,
  FormObserverResult,
} from 'components/form/types';
import { useTranslationX } from 'i18n';
import { useParams } from 'react-router-dom';

import { currencyDefaultNonZero } from 'components/form/helpers';
import styles from './TransferForm.module.scss';

type ProjectAccount = {
  titulo: string;
  identificacao: string;
  sigla: string;
  fonteNome: string;
  gpf: string;
  conta: {
    id: number;
    banco: string;
    agencia: string;
    numero: string;
  };
};

type Props = {
  onDone: VoidFunction;
  formRef: React.RefObject<FormInstance>;
  projetoContas: ProjectAccount[];
};

const TransferForm = ({ formRef, onDone, projetoContas }: Props) => {
  const { id } = useParams<{ id: string }>();

  const { tx } = useTranslationX('accounts', 'finance');

  const [selectedProjectAccountId, setSelectedProjectAccountId] = useState<number>();
  const [selectedProjectAccount, setSelectedProjectAccount] = useState<ProjectAccount>();

  const callback = (result: FormObserverResult) => {
    result = result as FieldObserverResult;
    if (result.data?.value) {
      setSelectedProjectAccountId(result.data?.value);
    }
  };

  useEffect(() => {
    if (formRef) {
      const currentForm = formRef?.current;
      currentForm?.observer.subscribe(callback, {
        field: 'contaProjetoId',
      });

      return () => currentForm?.observer.unsubscribe(callback);
    }
  }, [formRef]);

  useEffect(() => {
    if (selectedProjectAccountId) {
      setSelectedProjectAccount(
        projetoContas.find(p => p.conta.id === selectedProjectAccountId)
      );
    }
  }, [selectedProjectAccountId, projetoContas]);

  const rubrics = [
    'rh',
    'material',
    'equipamentos',
    'servicos',
    'passagens',
    'diarias',
    'outros',
    'suporte',
    'total',
  ];

  const Content = () => {
    const totalObserver = useFormObserver('dados.total', true);

    const totalValue = totalObserver?.data?.value ?? 0;

    return (
      <>
        <Grid spacing={0}>
          <Row>
            <Select
              name="contaProjetoId"
              label={tx('projectAccount')}
              metadata={{
                rules: {
                  required: true,
                },
                options: projetoContas.map(p => {
                  return {
                    text: `${tx('project')}: ${p.identificacao}-${p.sigla} ${tx(
                      'agency'
                    )}:${p.conta.agencia} ${tx('account')}:${p.conta.numero}`,
                    value: p.conta.id,
                  };
                }),
              }}
            />
          </Row>
          {selectedProjectAccount && (
            <Row>
              <div className={styles.selectedProjectAccount}>
                <div className={styles.detailItem}>
                  <span>{tx('project')}</span>
                  <span>
                    {selectedProjectAccount.identificacao +
                      '-' +
                      selectedProjectAccount.sigla}
                  </span>
                </div>
                <div className={styles.detailItem}>
                  <span>{tx('source')}</span>
                  <span>{selectedProjectAccount.fonteNome}</span>
                </div>
                <div className={styles.detailItem}>
                  <span>{tx('agency')}</span>
                  <span>{selectedProjectAccount.conta.agencia}</span>
                </div>
                <div className={styles.detailItem}>
                  <span>{tx('account')}</span>
                  <span>{selectedProjectAccount.conta.numero}</span>
                </div>
              </div>
            </Row>
          )}

          <FieldSet name="dados">
            <table className={styles.content}>
              <thead>
                <tr>
                  <th className={styles.title}></th>
                  <th colSpan={2} />
                </tr>
                <tr>
                  <td>{tx('rubric')}</td>
                  <td align="center">{tx('value')}</td>
                </tr>
              </thead>
              <tbody>
                {rubrics.map((label: string) => {
                  return (
                    <tr key={label}>
                      <td>{tx(`rubrics.${label}`)}</td>
                      <td>
                        {label === 'total' ? (
                          <AutoCompute
                            metadata={{
                              rules: {
                                min: '1.00',
                                required: true,
                                minlength: 1,
                                maxlength: 14,
                                mask: {
                                  pattern: 'currency',
                                },
                              },
                            }}
                            align="center"
                            type="disabled"
                            label={tx('value')}
                            name={label}
                            theme="platinum"
                            value={0}
                            expression={
                              '(rh + servicos + diarias + passagens + outros + material + equipamentos + suporte)'
                            }
                          />
                        ) : (
                          <TextField
                            {...currencyDefaultNonZero()}
                            align="center"
                            label={label}
                            name={label}
                            theme="platinum"
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </FieldSet>
        </Grid>
        <HiddenField name="valor" value={totalValue} />
        <HiddenField name="tipo" value="TRA" />
      </>
    );
  };

  return (
    <>
      <WrapperCard maxWidth="100%" padding="30px 32px">
        <Form
          baseUrl={`financeiro/contas-mae/${id}/solicitacao`}
          ref={formRef}
          onDone={onDone}
          submit={{
            method: 'post',
            bodyProcessor: data => {
              const { dados, ...rest } = data;
              return {
                ...rest,
                dados: JSON.stringify(dados),
              };
            },
          }}
        >
          <Content />
        </Form>
      </WrapperCard>
    </>
  );
};

export default TransferForm;
