import React, {
  forwardRef,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import Tippy from '@tippyjs/react';
import { InputProps, IntervalFilter } from '../types';
import { classes, renderComponent } from 'utils/components';

import { Calendar, X } from 'components/icons';
import { useTranslationX } from 'i18n';
import { formatDateToBrazilian, safeDate } from 'utils/stringUtils';

import DatePickerComponent, {
  DatePickerData,
} from './DatePickerComponent/DatePickerComponent';
import { call } from 'utils';
import {
  fromBrazilianToSQLTimestamp,
  fromSQLTimestampToBrazilian,
  toSQLTimestamp,
} from 'utils/calendarUtils';

import styles from './DatePicker.module.scss';

export type Props = {
  align?: 'left' | 'center' | 'right';
  adornment?: string | { left?: string; right?: string };
  icon?: React.ElementType;
  range?: boolean;
  showTime?: boolean;
};

type ModProps = Omit<InputProps, 'value'> & {
  value?: IntervalFilter | string;
};

const months = [
  'jan',
  'fev',
  'mar',
  'abr',
  'mai',
  'jun',
  'jul',
  'ago',
  'set',
  'out',
  'nov',
  'dez',
];

const DatePicker = forwardRef<HTMLDivElement, Props & ModProps>((props, ref) => {
  const { value, control, inputProps, range, name } = props;

  const { type } = control;
  const newValue = useMemo(() => {
    if (value && typeof value === 'string') {
      if (value !== toSQLTimestamp(value)) {
        return toSQLTimestamp(value);
      }
    }
    return value;
  }, [value]);

  const [year, month, day] =
    value && typeof value === 'string' ? value.split('-') : ['', '', ''];

  const [expanded, setExpanded] = useState(false);
  const [selected, setSelected] = useState<IntervalFilter | string | undefined>(newValue);
  const [inputDate, setInputDate] = useState({
    day,
    month,
    year,
  });

  const inputDateRef = useRef<{ day: string; month: string; year: string }>();
  const selectedRef = useRef<string>();

  const { tx } = useTranslationX('components', 'translation');

  const anchorRef = useRef<HTMLDivElement>();

  const popupRef = useRef<HTMLDivElement>();

  function attachRef(el: HTMLDivElement) {
    anchorRef.current = el;

    if (typeof ref === 'function') {
      ref(el);
    } else if (ref) {
      ref.current = el;
    }
  }

  function attachDropdownRef(el: HTMLDivElement) {
    popupRef.current = el;

    if (typeof ref === 'function') {
      ref(el);
    } else if (ref) {
      ref.current = el;
    }
  }

  const { onChange, onBlur, onFocus, ...rest } = inputProps;

  useEffect(() => {
    onChange(selected);
  }, [selected, onChange]);

  const handleNavigate = (
    field: 'date' | 'month' | 'year',
    value: string,
    maxLength = 2
  ) => {
    const positions = ['date', 'month', 'year'];

    if (value.length === maxLength && field !== 'year') {
      const nextFieldName = positions[positions.indexOf(field) + 1];
      const nextField = document.querySelector<HTMLInputElement>(
        `input[name="${name}_${nextFieldName}"]`
      );
      if (nextField) {
        nextField.focus();
      }
    } else if (value.length === 0 && field !== 'date') {
      const prevFieldName = positions[positions.indexOf(field) - 1];
      const prevField = document.querySelector<HTMLInputElement>(
        `input[name="${name}_${prevFieldName}"]`
      );
      if (prevField) {
        prevField.focus();
      }
    }
  };

  const handleBlur = () => {
    setExpanded(false);
    call(onBlur);
  };

  const handleChange = (data: DatePickerData) => {
    if (range) {
      setSelected({
        from: toSQLTimestamp(data.current[0].toISOString()),
        to: toSQLTimestamp(data.current[1].toISOString()),
      });
    } else {
      setSelected(toSQLTimestamp(data.current[0].toISOString()));
    }
  };

  const getCurrentValue = () => {
    if (typeof selected === 'string') {
      return [safeDate(selected)];
    } else {
      if (selected && selected.from && selected.to) {
        return [safeDate(`${selected.from}`), safeDate(`${selected.to}`)];
      }
    }
  };

  const renderPopupContent = (props: object) => {
    return (
      <DatePickerComponent
        tippyProps={props}
        range={range}
        value={getCurrentValue()}
        onChange={handleChange}
        onClose={handleBlur}
        attachDropdownRef={attachDropdownRef}
      />
    );
  };

  const renderPopup = () =>
    control.ref.current && expanded ? (
      <Tippy
        appendTo={() => document.body}
        placement="auto"
        offset={[0, 7]}
        render={renderPopupContent}
        interactive={true}
        visible={expanded}
        reference={control.ref}
        onClickOutside={(_, e) => {
          if (
            !popupRef.current ||
            (popupRef.current && !popupRef.current.contains(e.target as HTMLElement))
          ) {
            handleBlur();
          }
        }}
        popperOptions={{
          modifiers: [
            {
              name: 'preventOverflow',
              enabled: false,
            },
          ],
        }}
        aria={{ content: null, expanded: false }}
      />
    ) : null;

  function handleFocus(event: React.FocusEvent) {
    if (anchorRef.current?.contains(event.target) && !expanded) {
      call(event.type === 'blur' ? onBlur : onFocus);
    }
  }

  const handlers = {
    onClick: () => setExpanded(expanded => !expanded),
    onBlur: handleFocus,
    onFocus: handleFocus,
  };

  const getValueOrPlaceHolder = (value?: any) => {
    return value !== undefined ? formatDateToBrazilian(value) : 'dd/mm/yyyy';
  };

  const handleClear = useCallback(() => {
    setSelected(undefined);
    control.ref.current.value = '';
    const finalInput = {
      day: '',
      month: '',
      year: '',
    };
    inputDateRef.current = finalInput;
    setInputDate(finalInput);
  }, [control.ref]);

  useEffect(() => {
    if (newValue === undefined) {
      handleClear();
      return;
    }
    if (!range) {
      const [day, month, year] = fromSQLTimestampToBrazilian(
        toSQLTimestamp(newValue as string)
      ).split('/');

      const dateMonth = months.indexOf(month) + 1;
      const formattedDateMonth = `${dateMonth < 10 ? '0' : ''}${dateMonth}`;

      if (
        inputDateRef.current?.day !== day ||
        inputDateRef.current?.month !== formattedDateMonth ||
        inputDateRef.current?.year !== year
      ) {
        setSelected(newValue);
        setInputDate({
          day,
          month: formattedDateMonth,
          year,
        });
        inputDateRef.current = { day, month: formattedDateMonth, year };
      }
    }
  }, [handleClear, newValue, range]);

  useEffect(() => {
    const isInputDateValid =
      inputDate.day.length === 2 &&
      inputDate.month.length === 2 &&
      inputDate.year.length === 4;

    if (!range && isInputDateValid) {
      const formattedDate = `${inputDate.day}/${inputDate.month}/${inputDate.year}`;
      const newSelected = fromBrazilianToSQLTimestamp(formattedDate);

      if (newSelected !== 'Invalid date' && selectedRef.current !== newSelected) {
        setSelected(toSQLTimestamp(newSelected));

        selectedRef.current = newSelected;
      }
    }
  }, [inputDate, range]);

  return (
    <div className={classes(styles.datePicker, styles[control.type || ''])}>
      <div className={styles.icon}>
        {!selected || type === 'readonly' ? (
          renderComponent(Calendar, {
            size: 1.2,
          })
        ) : (
          <Tippy
            className={classes(styles.popup)}
            content={tx('date_picker.clear')}
            animation="perspective"
            touch={false}
          >
            <div className={styles.iconRemove} onClick={handleClear}>
              {renderComponent(X, {
                size: 1.4,
              })}
            </div>
          </Tippy>
        )}
      </div>

      <div
        {...rest}
        ref={attachRef}
        className={styles.input}
        role="button"
        aria-haspopup="listbox"
        aria-expanded={expanded}
        {...handlers}
      >
        <div
          className={classes(
            styles.content,
            selected === undefined ? styles.placeholder : null,
            range ? styles.range : null
          )}
        >
          {range ? (
            <>
              <span>{getValueOrPlaceHolder((selected as IntervalFilter)?.from)}</span>
              <span>{getValueOrPlaceHolder((selected as IntervalFilter)?.to)}</span>
            </>
          ) : (
            <div className={styles.dateInput}>
              <input
                type="number"
                maxLength={2}
                name={`${name}_date`}
                className={classes(
                  styles.inputDate,
                  type === 'readonly' ? styles.disabled : undefined
                )}
                placeholder="dd"
                value={inputDate.day}
                onChange={e => {
                  const value = e.target.value;
                  if (value <= '31') {
                    setInputDate({ ...inputDate, day: value.slice(0, 2) });
                    handleNavigate('date', value);
                  }
                }}
              />
              <span>{tx('forwardSlash')}</span>
              <input
                type="number"
                name={`${name}_month`}
                maxLength={2}
                className={classes(
                  styles.inputDate,
                  type === 'readonly' ? styles.disabled : undefined
                )}
                placeholder="mm"
                value={inputDate.month}
                onChange={e => {
                  const value = e.target.value;
                  if (value <= '12') {
                    setInputDate({ ...inputDate, month: value.slice(0, 2) });
                    handleNavigate('month', value);
                  }
                }}
              />
              <span>{tx('forwardSlash')}</span>
              <input
                type="number"
                name={`${name}_year`}
                maxLength={4}
                className={classes(
                  styles.inputYear,
                  type === 'readonly' ? styles.disabled : undefined
                )}
                value={inputDate.year}
                placeholder="yyyy"
                onChange={e => {
                  const value = e.target.value;
                  const isYearValid = Number(value) >= new Date().getFullYear() - 100;

                  if (isYearValid || value.length < 4) {
                    setInputDate({ ...inputDate, year: value.slice(0, 4) });
                    handleNavigate('year', value, 4);
                  }
                }}
              />
            </div>
          )}
        </div>
      </div>
      {renderPopup()}
    </div>
  );
});

DatePicker.displayName = 'DatePicker';
export default DatePicker;
