import React, { Fragment, useReducer, useState } from 'react';
import _ from 'lodash';
import { PlannerHeader, WrapperCard } from 'components/elements';
import { DatePicker, Form, SubmitButton } from 'components/form';
import Tippy from '@tippyjs/react';
import { PaymentButton, PaymentTable } from '.';
import { PaymentContextProvider, usePaymentContext } from './PaymentContext';
import { isNotDeepEquals, sanitizeObject } from 'utils/object';
import { ActionFilter, StateFilter } from 'components/containers/TableList/types';
import { useTranslationX } from 'i18n';
import { useRequest } from 'apis';
import { useProjectEditor } from 'pages/project/ProjectEditor/contexts';
import { PaymentContracts } from './types';
import {
  ErrorDialog,
  Grid,
  InfoDialog,
  Modal,
  ModalPDFViewer,
  Row,
} from 'components/containers';

import { readDate } from 'utils/calendarUtils';

import styles from './PaymentPanel.module.scss';
import { useDebouncedCallback } from 'components/hooks/useDebounce';

const reducer = (state: StateFilter, action: ActionFilter): StateFilter => {
  switch (action.type) {
    case 'FILTER_UPDATE':
      if (isNotDeepEquals(state.filters, action.payload)) {
        return state;
      }
      return {
        ...state,
        status: 'update',
        filters: sanitizeObject({ ...state.filters, ...action.payload }),
      };

    case 'FILTER_RESET':
      return { ...state, status: 'clean', filters: {} };

    case 'FINISH_UPDATE':
      return { ...state, status: 'idle' };

    default:
      return state;
  }
};

type Props = {
  fonte: string;
  data: PaymentContracts;
  reload: () => void;
};

const PaymentPanel: React.FC<Props> = props => {
  const { tx } = useTranslationX('paymentPanel', 'project');

  return (
    <WrapperCard maxWidth={'calc(100% - 125px)'} padding="15px 20px 20px">
      <div className={styles.paymentPanel}>
        <PlannerHeader
          title={tx('title')}
          subtitle={tx('subtitle')}
          legends={[
            {
              label: tx('status.exe'),
              color: '#37a64a',
            },
            {
              label: tx('status.req'),
              color: '#BBEBC4',
            },
            {
              label: tx('status.lat'),
              color: '#E61616',
            },
          ]}
        />
        <PaymentContextProvider reload={props.reload} contracts={props.data}>
          <Content {...props} />
        </PaymentContextProvider>
      </div>
    </WrapperCard>
  );
};

const Content = React.memo(({ fonte }: Props) => {
  const { payments, reset, currentMonthPayments, contracts } = usePaymentContext();
  const { state } = useProjectEditor();
  const [pdfData, setPdfData] = useState<string | ArrayBuffer | null>(null);
  const [executableModalShow, setExecutableModalShow] = useState<boolean>(false);

  const { tx } = useTranslationX('paymentPanel', 'project');

  const request = useRequest();

  const [stateFilter, dispatchFilter] = useReducer(reducer, {
    status: 'init',
    filters: {},
    sort: 'name,asc',
  });
  const [status, setStatus] = useState<'idle' | 'error' | 'success'>('idle');

  const handleClosePopup = () => {
    setStatus('idle');
  };
  const currentSourceContracts = _.filter(contracts.contratos, { fonte });

  const debouncedHandleRequestPayment = useDebouncedCallback(
    (isCurrentMonth?: boolean) => handleRequestPayment(isCurrentMonth),
    300
  );

  const handleRequestPayment = (isCurrentMonth = false) => {
    const selectedPayments = isCurrentMonth ? currentMonthPayments : payments;

    const payload = selectedPayments.map(payment => {
      const date = readDate(payment.pagamento);

      const anoPagamento = date.get('year');
      const mesPagamento = date.get('month') + 1;

      return {
        mesExecucao: payment.mes,
        prhId: Number((payment.id as string).split('-')[0]),
        anoPagamento,
        mesPagamento,
      };
    });
    request({
      url: `/projeto/${state.id}/${state.section}/pagamentos`,
      method: 'POST',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
      },
      onSuccess: (data: { id: number }) => {
        getOficio(data.id);
      },
      onComplete: () => {
        reset();
      },
      onError: _ => setStatus('error'),
    });
  };

  const handleInformPayment = (data: { requestBody: any; section: string }) => {
    const payload = payments.map(payment => {
      const date = readDate(payment.pagamento);

      const anoPagamento = date.get('year');
      const mesPagamento = date.get('month') + 1;

      return {
        mesExecucao: payment.mes,
        prhId: Number((payment.id as string).split('-')[0]),
        anoPagamento,
        mesPagamento,
        dataExecucao: data.requestBody.executedDate,
      };
    });
    request({
      url: `/projeto/${state.id}/${state.section}/pagamentos/clt`,
      method: 'POST',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
      },
      onSuccess: () => {
        setExecutableModalShow(false);
      },
      onComplete: () => {
        reset();
      },
      onError: _ => setStatus('error'),
    });
  };

  const getOficio = (id: number) => {
    request<any>({
      url: `/projeto/${state.id}/execucao/servicos/solicitacao/${id}/pdf`,

      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
      onSuccess: (blob: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          let base64data = reader.result;
          setPdfData(base64data);
        };
      },
      onError: data => console.error(data),
    });
  };

  const handleClose = () => {
    setPdfData(null);
    setStatus('success');
  };

  const hasEmptyPayments = payments.length === 0;
  const hasEmptyCurrentMonthPayments = currentMonthPayments.length === 0;

  const today = new Date();

  const isInvalidDate = today.getDate() <= 15 && today.getMonth() + 1 !== 12;

  const showReason =
    hasEmptyCurrentMonthPayments && isInvalidDate && currentSourceContracts.length > 0;

  return (
    <Fragment>
      <Modal
        visible={pdfData !== null}
        width="auto"
        onBlur={handleClose}
        onClose={handleClose}
      >
        <div>
          <ModalPDFViewer pdfData={pdfData} onClose={handleClose} title={tx('office')} />
        </div>
      </Modal>

      <InfoDialog
        visible={status === 'success'}
        title={tx('success.title')}
        message={tx('success.description')}
        volatile
        onClose={handleClosePopup}
        onBlur={handleClosePopup}
      />
      <ErrorDialog
        visible={status === 'error'}
        title={tx('error.title')}
        message={tx('error.description')}
        volatile
        onClose={handleClosePopup}
        onBlur={handleClosePopup}
      />
      <div className={styles.actions}>
        <div>
          <PaymentButton
            disabled={hasEmptyPayments}
            onClick={() => debouncedHandleRequestPayment()}
          >
            {tx('actions.request_payment')}
          </PaymentButton>
          <Tippy
            content={tx('reasons.month')}
            className={styles.popup}
            animation="perspective"
            touch={false}
            followCursor="horizontal"
            disabled={!showReason}
          >
            <div>
              <PaymentButton
                disabled={hasEmptyCurrentMonthPayments}
                onClick={() => debouncedHandleRequestPayment(true)}
              >
                {tx('actions.request_current_payments')}
              </PaymentButton>
            </div>
          </Tippy>
        </div>
      </div>
      <PaymentTable
        dispatchFilter={dispatchFilter}
        stateFilter={stateFilter}
        fonte={fonte}
      />

      <Modal
        visible={executableModalShow}
        onBlur={() => setExecutableModalShow(false)}
        onClose={() => setExecutableModalShow(false)}
        width={500}
      >
        <div className={styles.executedModal}>
          <span>{tx('executedValueLabel')}</span>
          <Form onSubmit={handleInformPayment}>
            <Grid>
              <Row width={[12]}>
                <DatePicker name="executedDate" label={tx('executionDate')} />
              </Row>
            </Grid>

            <div className={styles.buttonConfirm}>
              <SubmitButton>{tx('confirmExecutedValue')}</SubmitButton>
            </div>
          </Form>
        </div>
      </Modal>
    </Fragment>
  );
});

export default PaymentPanel;
