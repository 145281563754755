import React from 'react';

import { useTranslationX } from 'i18n';

import { ConfigTopPanel } from 'pages/dashboard';
import { TopMenuItemIcon } from 'pages/dashboard/types';
import { Evaluation, Bell } from 'components/icons';
import ImportReview from './ImportReview';
import ImportAlerts from './ImportAlerts';

export type ImportacaoExtrato = {
  id: number;
  codBanco: string;
  banco: string;
  conta: string;
  agencia: string;
  dataInicio: Date;
  dataFim: Date;
  idProjeto: number;
  gpf: string;
  fonteRecurso: string;
  lancamentos: number;
  lancamentoValidos: number;
  estado: string;
  motivoFalha: string;
  arquivo: string;
  nomeContaMae: string;
  ehContaMae: boolean;
  fonteContaMae: string;
};

export type Account = {
  id: number;
  codBanco: string;
  banco: string;
  conta: string;
  agencia: string;
  idProjeto: number;
  numeroProjeto: string;
  ehContaMae: boolean;
  sigla: string;
  nomeContaMae: string;
  fonteContaMae: string;
};

const ImportReviewAndAlerts = () => {
  const { tx } = useTranslationX('importReviewAndAlerts', 'finance');

  const items: TopMenuItemIcon[] = [
    {
      key: 'revisao',
      component: ImportReview,
      icon: Evaluation,
      title: tx('routes.review'),
    },
    {
      key: 'revisao-mae',
      component: ImportReview,
      itemProps: {
        variant: 'mother',
      },
      icon: Evaluation,
      title: tx('routes.review-mother'),
    },
    {
      key: 'alertas',
      component: ImportAlerts,
      icon: Bell,
      title: tx('routes.alerts'),
    },
  ];

  return (
    <>
      <ConfigTopPanel items={items} />
    </>
  );
};

export default ImportReviewAndAlerts;
