import React from 'react';
import { getMasked } from 'utils/inputMask';
import {
  AccountabilityFormatOption,
  accountabilityFormatOptionMapper,
  BalanceStatus,
  balanceStatusColor,
  balanceStatusMapper,
} from './types';

import ReactDomRender from 'react-dom/server';

export const statusOptions = (tx: (key: string) => string) => {
  return Object.keys(balanceStatusMapper).map(status => {
    return {
      value: status,
      text: tx(`status.${status}`),
    };
  });
};

export const renderColorizedStatus = (value: BalanceStatus) => {
  return `<span style="background-color: ${balanceStatusColor[value]}; color: #5D5A71; display: flex; align-items: center; justify-content: center;
padding: 17px;">${balanceStatusMapper[value]}</span>`;
};

export const parseMoney = (value: number, t: (key: string) => string) => {
  return (
    t('currency') +
    ' ' +
    getMasked(Number(value).toFixed(2), {
      pattern: 'currency',
    })
  );
};

export const renderInternalTitle = (title: string, subtitle: string) => {
  const Component = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 10,
      }}
    >
      <span
        style={{
          color: '#686868',
          fontWeight: 400,
          fontSize: 14,
        }}
      >
        {subtitle}
      </span>
      <strong
        style={{
          fontWeight: 600,
          fontSize: 18,
          color: '#33333',
        }}
      >
        {title}
      </strong>
    </div>
  );
  return ReactDomRender.renderToString(Component);
};

export const renderColorizedValue = (
  value: number,
  situacao: BalanceStatus,
  t: (key: string) => string
) => {
  return `<span style="background-color: ${
    balanceStatusColor[situacao]
  }; color: #5D5A71;font-weight: bold;font-size: 14px; display: flex; align-items: center; justify-content: flex-end;
 padding-block: 17px;padding-inline: 5px;">${parseMoney(value, t)}</span>`;
};

export const renderTdAsTh = (
  title: string,
  content: string,
  align?: 'left' | 'center' | 'right',
  headerAlign?: 'left' | 'center' | 'right',
  color: string = '#5D5A71',
  background?: string
) => {
  const Component = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 4,
        backgroundColor: background,
        padding: 7,
      }}
    >
      <span
        style={{
          color: '#5D5A71',
          fontWeight: 400,
          fontSize: 12,
          textAlign: headerAlign || 'left',
          textTransform: 'uppercase',
        }}
      >
        {title}
      </span>
      <strong
        style={{
          color,
          fontWeight: 700,
          fontSize: 14,
          textAlign: align || 'left',
        }}
      >
        {content}
      </strong>
    </div>
  );
  return ReactDomRender.renderToString(Component);
};

export const accountabilityNumberOptions = () => {
  return [
    {
      value: 'PMC',
      text: 'Preencher com mês de competência',
    },
    {
      value: '',
      text: 'Nenhuma',
    },
  ];
};

export const accountabilityDateOptions = () => {
  return [
    {
      value: 'SDN',
      text: 'Sem a data da nota fiscal',
    },
    {
      value: '',
      text: 'Nenhuma',
    },
  ];
};

export const accountabilityFormatOptions = () => {
  return Object.keys(accountabilityFormatOptionMapper).map(status => {
    return {
      value: status,
      text: accountabilityFormatOptionMapper[status as AccountabilityFormatOption],
    };
  });
};
