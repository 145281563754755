import React from 'react';
import { useTranslationX } from 'i18n';
import { ScrollPanel } from 'components/containers';
import { useFetch } from 'apis';
import { Loader, NotFoundMessage } from 'components/elements';
import { FaMoneyBill } from 'react-icons/fa';

import { ProjectSourceItem } from '../../types';
import { ProjectAccountsSourceCard } from '../..';

import styles from './ProjectAccounts.module.scss';

const ProjectAccountsTab = () => {
  const { tx } = useTranslationX('expenses', 'finance');

  const { data } = useFetch<ProjectSourceItem[]>(
    '/financeiro/conciliacao/contas/projetos'
  );

  if (data === undefined) {
    return <Loader size={60} />;
  }

  if (data.length === 0) {
    return (
      <NotFoundMessage
        title={tx('notFound.title')}
        description={tx('notFound.description')}
        icon={FaMoneyBill}
      />
    );
  }

  return (
    <ScrollPanel vBar={{ overlay: true }} style={{}}>
      <div className={styles.grid}>
        {data?.map((item, index) => (
          <ProjectAccountsSourceCard key={index} item={item} />
        ))}
      </div>
    </ScrollPanel>
  );
};

export default ProjectAccountsTab;
