import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';

import { InputProps } from '../types';
import { call, splitValueUnit } from 'utils';
import { classes } from 'utils/components';

import styles from './MonthYearPicker.module.scss';
import { AngleDown, Calendar } from 'components/icons';
import { useTranslationX } from 'i18n';

export type Props = {
  removeIcon?: boolean;
};

type ModProps = Omit<InputProps, 'value'> & {
  value?: Date;
};

type Date = {
  year: number;
  month: number;
};

const MonthAndYearPicker = forwardRef<HTMLDivElement, ModProps & Props>((props, ref) => {
  const { value, control, inputProps, removeIcon = false } = props;

  const [expanded, setExpanded] = useState(false);
  const [selected, setSelected] = useState<Date | undefined>();

  const { value: boxSize } = splitValueUnit(styles.boxSize);
  const { value: boxMargin } = splitValueUnit(styles.boxMargin);

  const { tx } = useTranslationX('components.month_year_picker');

  const anchorRef = useRef<HTMLDivElement>();

  function attachRef(el: HTMLDivElement) {
    anchorRef.current = el;

    if (typeof ref === 'function') {
      ref(el);
    } else if (ref) {
      ref.current = el;
    }
  }

  const { onChange, onBlur, onFocus, ...rest } = inputProps;

  useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value]);

  useEffect(() => {
    onChange(selected);
  }, [selected, onChange]);

  const getCurrentOrDefaultDate = () => {
    const now = new Date();
    return {
      year: selected?.year || now.getFullYear(),
      month: selected?.month || now.getMonth() + 1,
    };
  };

  const changeYear = (offset: number) => {
    const { year, month } = selected || getCurrentOrDefaultDate();
    const newYear =
      year + offset > 2099 ? 2099 : year + offset < 1900 ? 1900 : year + offset;
    setSelected({ year: newYear, month });
  };

  const changeMonth = (month: number) => {
    const { year } = selected || getCurrentOrDefaultDate();

    setSelected({ year, month });
  };

  const renderPopupContent = (props: object) => {
    const months = 12;
    const width = (months / 2) * (boxSize + boxMargin * 2);

    return (
      <div className={styles.picker} {...props} style={{ width }} tabIndex={0}>
        <div className={styles.yearSelector}>
          <div
            className={styles.arrow}
            onClick={() => changeYear(-1)}
            style={{
              transform: 'rotate(90deg)',
            }}
          >
            <AngleDown />
          </div>
          <div className={styles.year}>{getCurrentOrDefaultDate().year}</div>
          <div
            className={styles.arrow}
            onClick={() => changeYear(1)}
            style={{
              transform: 'rotate(-90deg)',
            }}
          >
            <AngleDown />
          </div>
        </div>
        <div className={styles.months}>
          {Array.from(Array(12).keys()).map(i => {
            const month = i + 1;
            return (
              <div
                key={month}
                className={classes(
                  styles.month,
                  selected && selected.month === Number(month) ? styles.selected : ''
                )}
                onClick={() => {
                  anchorRef.current?.focus();
                  changeMonth(Number(month));
                }}
              >
                {tx(`months.${month}`).substring(0, 3)}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderPopup = () =>
    control.ref.current && expanded ? (
      <Tippy
        appendTo={() => document.body}
        placement="bottom-start"
        offset={[0, 2]}
        render={renderPopupContent}
        interactive={true}
        visible={expanded}
        reference={control.ref}
        onClickOutside={() => {
          setExpanded(false);
          call(onBlur);
        }}
        popperOptions={{
          modifiers: [
            {
              name: 'preventOverflow',
              enabled: false,
            },
          ],
        }}
        aria={{ content: null, expanded: false }}
      />
    ) : null;

  function handleFocus(event: React.FocusEvent) {
    if (anchorRef.current?.contains(event.target) && !expanded) {
      call(event.type === 'blur' ? onBlur : onFocus);
    }
  }

  const handlers = {
    onClick: () => setExpanded(expanded => !expanded),
    onBlur: handleFocus,
    onFocus: handleFocus,
  };

  return (
    <div className={styles.monthYearPicker}>
      <div
        {...rest}
        ref={attachRef}
        className={classes(
          styles.anchor,
          control.type ? styles[control.type] : undefined
        )}
        role="button"
        aria-haspopup="listbox"
        aria-expanded={expanded}
        {...handlers}
      >
        <div className={styles.label}>
          {selected?.month && selected?.year
            ? `${tx(`months.${getCurrentOrDefaultDate().month}`)} ${
                getCurrentOrDefaultDate().year
              }`
            : 'mmm aaaa'}
        </div>
        {!removeIcon && (
          <Tippy
            className={classes(styles.popup)}
            content={tx('clear')}
            animation="perspective"
            touch={false}
          >
            <div
              className={styles.icon}
              onClick={() =>
                setSelected({
                  year: new Date().getFullYear(),
                  month: new Date().getMonth() + 1,
                })
              }
            >
              <Calendar size={1.5} />
            </div>
          </Tippy>
        )}
      </div>
      {renderPopup()}
    </div>
  );
});

export default MonthAndYearPicker;
