import React from 'react';

import { Grid, Row } from 'components/containers';
import { WrapperCard } from 'components/elements';
import { Form, Select, TextArea, TextField } from 'components/form';
import { FormInstance } from 'components/form/types';
import { useTranslationX } from 'i18n';
import { useParams } from 'react-router-dom';

type Props = {
  onDone: VoidFunction;
  formRef: React.RefObject<FormInstance>;
};

const AdditionalCreditForm = ({ formRef, onDone }: Props) => {
  const { id } = useParams<{ id: string }>();

  const { tx } = useTranslationX('expenses', 'finance');

  return (
    <>
      <WrapperCard maxWidth="100%" padding="30px 32px">
        <Form
          baseUrl={`/financeiro/conciliacao/despesas/contas-mae/${id}/credito-adicional`}
          ref={formRef}
          onDone={onDone}
          submit={{
            method: 'post',
          }}
        >
          <Grid spacing={0}>
            <Row>
              <Select name="tipo" label={tx('type')} />
              <Select name="origem" label={tx('origin')} />
            </Row>
            <Row>
              <TextArea name="descricao" label={tx('description')} minRows={8} />
            </Row>
            <Row width={[0.5, 0.5]}>
              <TextField name="valor" label={tx('value')} />
            </Row>
          </Grid>
        </Form>
      </WrapperCard>
    </>
  );
};

export default AdditionalCreditForm;
