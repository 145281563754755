import React, { useState } from 'react';
import { Modal } from 'components/containers';
import styles from './ScheduleList.module.scss';
import { FileUploader, Form, SubmitButton } from 'components/form';
import { useTranslationX } from 'i18n';
import { useRequest } from 'apis';
import { FormInstance } from 'components/form/types';
import { useParams } from 'react-router-dom';
import { StateButton } from '../ConclusionPanel/types';
import { Loader } from 'components/elements';

type Props = {
  visible: boolean;
  onClose: () => void;
  state: StateButton;
  onSuccess: () => void;
};

export function Declaration({ visible, onClose, state, onSuccess }: Props) {
  const { tx } = useTranslationX('conclusionPanel', 'project');
  const request = useRequest();
  const form = React.createRef<FormInstance>();
  const { id: projectId } = useParams<{ id: string; contrapartidaId: string }>();
  const [loading, setLoading] = useState(false);

  const sendDeclaracao = async () => {
    if (loading) return;
    let formData = new FormData();
    if (state.conclusions.length === 0) return;

    const firstConclusion = state.conclusions[0];

    const contratoId = firstConclusion.id.toString().split('-')[0];
    const mes = firstConclusion.mes;

    const file = (form.current?.context.getFieldValue('declaracao') as File[])[0];
    formData.append('declaracao', file);
    formData.append('mes', String(mes));
    setLoading(true);
    request<any>({
      url: `/projeto/${projectId}/contrapartidas/${contratoId}/pdf/mensal`,
      method: 'PUT',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',
      onSuccess,
      onError: data => console.error(data),
      onComplete: () => {
        setLoading(false);
      },
    });
  };

  return (
    <>
      <Modal visible={loading === true} minWidth={false} delay={500}>
        <Loader type="grid" delay={false} message={tx('submitting')} />
      </Modal>
      <Modal visible={visible} width={400} onBlur={onClose} onClose={onClose}>
        <div className={styles.popup}>
          <Form ref={form} onSubmit={sendDeclaracao}>
            <FileUploader
              metadata={{
                rules: {
                  accept: ['pdf'],
                  maxfiles: 1,
                  required: true,
                  filesize: 5242880,
                },
              }}
              name="declaracao"
              type="sneaky"
              label={tx('makeUpload')}
              message={tx('makeUpload')}
            />
            <div>
              <SubmitButton autoDisable>{tx('send')}</SubmitButton>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
}
