import { useMemo, useState } from 'react';

const usePromisePending = <T extends (...args: any[]) => Promise<any>>(
  handler?: T
): [(...args: Parameters<T>) => Promise<ReturnType<T>>, boolean, Error | null] => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const promiseHandler = useMemo(() => {
    if (!handler) {
      return async () => {
        return Promise.reject(new Error('No handler provided'));
      };
    }

    return async (...args: Parameters<T>): Promise<ReturnType<T>> => {
      setLoading(true);
      try {
        return await handler(...args);
      } catch (error) {
        setError(error as Error);
        throw error;
      } finally {
        setLoading(false);
      }
    };
  }, [handler]);

  return [promiseHandler, loading, error];
};

export default usePromisePending;
