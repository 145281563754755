import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { TableList } from 'components/containers';
import {
  ButtonType,
  Instance,
  ListType,
} from 'components/containers/TableList/TableList';
import { ColumnConfig } from 'components/containers/TableList/types';
import { useTranslation, useTranslationX } from 'i18n';
import { AlignCenterVertical } from 'components/icons';
import { TableAction } from 'components/containers/TableList/TableListItem';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getMasked } from 'utils/inputMask';
import { useFetch } from 'apis';
import { RouteParams, SourceDetail } from '../ConciliationSourceList/types';
import { Loader } from 'components/elements';
import { useDynamicHeader } from 'components/containers/DynamicHeader';
import { BalanceStatus } from '../types';
import { renderColorizedStatus, renderColorizedValue, statusOptions } from '../utils';

const ConciliationExpensesList = () => {
  const { tx } = useTranslationX('expenses', 'finance');
  const { t } = useTranslation('translation');

  const { setBreadcrumbs, setFooter, setVisibility, clear } = useDynamicHeader();

  const history = useHistory();
  const location = useLocation();

  const table = useRef<Instance>();

  const { id, source } = useParams<RouteParams>();

  const listUrl = useMemo(() => {
    if (source === 'contas-projeto') {
      return `/financeiro/conciliacao/fonte-recurso/${id}`;
    }
    return `/financeiro/conciliacao/contas-mae/${id}`;
  }, [id, source]);

  const detailUrl = useMemo(() => {
    if (source === 'contas-projeto') {
      return `/financeiro/conciliacao/fonte-recurso/${id}`;
    }
    return `/financeiro/conciliacao/contas-mae/${id}`;
  }, [id, source]);

  const { data } = useFetch<SourceDetail>(detailUrl);

  const handleClickConciliate = useCallback(
    (item: any) => {
      const { id: lancamento_id } = item;
      const previousPath = location.pathname;
      history.push(`${previousPath}/lancamento/${lancamento_id}`);
    },
    [history, location.pathname]
  );

  const handleClickReview = useCallback(() => {
    const previousPath = location.pathname;
    history.push(`${previousPath}/revisao`);
  }, [history, location.pathname]);

  const getActions = useCallback(
    (data: any) => {
      const actions: TableAction[] = [];

      actions.push({
        icon: AlignCenterVertical,
        onClick: () => handleClickConciliate(data),
        color: '#5D5A71',
        label: tx('conciliate'),
        identifier: 'conciliate',
      });

      return actions;
    },
    [handleClickConciliate, tx]
  );

  let config: ColumnConfig<ListType> = [
    {
      title: tx('id'),
      field: 'fit_id',
      align: 'left',
      weight: 0.5,
      capitalize: true,
      search: true,
    },
    {
      title: tx('transaction'),
      field: 'transacao',
      weight: 1.5,
      align: 'left',
      type: 'html',
      parse: (data: string) => `<strong>${data}<strong/>`,
      search: true,
    },
    {
      title: tx('date'),
      field: 'data',
      type: 'date',
      sortDir: 'desc',
      sort: true,
      weight: 0.5,
      searchType: 'date',
      search: true,
    },
    {
      title: tx('situation'),
      field: 'situacao',
      weight: 0.5,
      type: 'html',
      search: true,
      searchType: 'status',
      metadata: {
        options: statusOptions(tx),
      },
      parse: renderColorizedStatus,
    },
    {
      title: tx('pendingBalance'),
      field: 'saldo_conciliar',
      search: true,
      weight: 0.6,
      align: 'right',
      headerAlign: 'right',
      type: 'html',
      searchType: 'range',
      parse: (item: number, data: any) =>
        renderColorizedValue(item, data.situacao as BalanceStatus, t),
    },
    {
      title: tx('value'),
      field: 'valor',
      type: 'money',
      align: 'left',
      weight: 0.5,
      search: true,
      searchType: 'range',
      parse: value => {
        const isNegative = value < 0;
        const absValue = Math.abs(value);
        const signal = isNegative ? '-' : '+';
        return {
          color: isNegative ? '#EE0004' : ' #118F00',
          value: `${signal} ${
            t('currency') +
            ' ' +
            getMasked(Number(absValue).toFixed(2), {
              pattern: 'currency',
            })
          }`,
        };
      },
    },
    {
      title: tx('actions'),
      field: 'actions',
      weight: 0.4,
      type: 'actions',
      search: false,
      parse: (_, data) => getActions(data),
    },
  ];

  useEffect(() => {
    if (!data) return;
    if (source === 'contas-projeto') {
      const { number, slug, sourceName, agency, account } = data;

      setBreadcrumbs([
        'finance',
        'expenses',
        `[${tx('identification', {
          sigla: slug,
          number,
        })}]`,
      ]);
      setFooter(
        `${sourceName} ${tx('agencyHeader', { value: agency })} ${tx('accountHeader', {
          value: account,
        })}`
      );
    } else if (source === 'contas-mae') {
      const { name, sourceName, agency, account } = data;
      setBreadcrumbs(['finance', 'expenses', `[${name}]`]);
      setFooter(
        `${sourceName} ${tx('agencyHeader', { value: agency })} ${tx('accountHeader', {
          value: account,
        })}`
      );
    }

    setVisibility(true);
    return () => {
      clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear, setBreadcrumbs, setVisibility, setFooter, data, source]);

  const extraButtons = useMemo(() => {
    const buttons = [];
    if (source === 'contas-mae') {
      buttons.push({
        label: tx('button.autoConciliate'),
        type: 'green' as ButtonType,
        action: () => {},
      });
    }
    buttons.push({
      label: tx('button.reviewConciliations'),
      type: 'green' as ButtonType,
      action: handleClickReview,
    });
    return buttons;
  }, [handleClickReview, source, tx]);

  if (!data) {
    return <Loader />;
  }

  return (
    <>
      <TableList
        defaultSearchProps={{
          sort: 'data,desc',
          filters: {},
        }}
        style={{
          root: {
            maxWidth: '100%',
            maxHeight: 'calc(100vh - 150px)',
          },
        }}
        listHeader={{
          subtitle: tx('financial'),
          title: tx('bankExpenses'),
          position: 'inside',
        }}
        keyComposer={(item, index) => `${item.id}-${index}`}
        extraButtons={extraButtons}
        baseUrl={listUrl}
        filter
        config={config}
        textSize="small"
        theme="light"
        infinityScroll
        refreshesOn={95}
        editIcon={null}
        notFoundProps={{}}
        reference={table}
      />
    </>
  );
};

export default ConciliationExpensesList;
