import { useRequest } from 'apis';
import { Dialog, Grid, Row } from 'components/containers';
import { Button, WrapperCard } from 'components/elements';
import { useTranslationX } from 'i18n';
import { useLocation } from 'react-router-dom';
import { messageService } from 'services';

import styles from './CancelMotherAccountRequest.module.scss';

type Props = {
  visible: boolean;
  onClose: VoidFunction;
  onSubmit: VoidFunction;
};

const CancelMotherAccountRequest = ({ visible, onClose, onSubmit }: Props) => {
  const { tx } = useTranslationX('accounts', 'finance');
  const request = useRequest();

  const location = useLocation<{
    id: number;
    requestType: string;
    documentId: number;
  }>();

  const { documentId, requestType } = location.state ?? {};

  function cancelRequest() {
    const source = request({
      url: `financeiro/contas-mae/solicitacao/${location.state.id}/cancelar`,
      method: 'POST',
      onSuccess: () =>
        messageService.success(tx('requestCanceled'), {
          duration: 2000,
        }),
      onError: err => {
        const { error } = err.response?.data as { error?: string };
        messageService.error(error ?? tx('errorWhileCancelingRequest'), {
          duration: 2000,
        });
      },
      onComplete: () => {
        onSubmit();
        onClose();
      },
    });

    return () => source.cancel();
  }

  return (
    <Dialog
      title={tx('requestCancel')}
      titleAlign="center"
      visible={visible}
      onClose={onClose}
      closeButton
      width={600}
      actions={
        <>
          <h3 className={styles.cancelConfirm}>{tx('confirmTitle')}</h3>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="secondary" onClick={onClose}>
              {tx('no')}
            </Button>
            <Button onClick={cancelRequest}>{tx('yes')}</Button>
          </div>
        </>
      }
    >
      <WrapperCard padding="50px 50px" maxWidth="100%">
        <Grid>
          <Row space={20} align="stretch">
            <div className={styles.cancelModalItem}>
              {tx('requestType')}
              <span>{requestType}</span>
            </div>
            <div className={styles.cancelModalItem}>
              {tx('officeNumber')}
              <span>{documentId}</span>
            </div>
          </Row>
          <Row></Row>
        </Grid>
      </WrapperCard>
    </Dialog>
  );
};

export default CancelMotherAccountRequest;
