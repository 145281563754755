import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useDynamicHeader } from 'components/containers/DynamicHeader';
import { useTranslation, useTranslationX } from 'i18n';
import { useFetch } from 'apis';
import { Button, InfoCard, Loader, PercentCardWithIcon } from 'components/elements';
import {
  ExpenseDetail,
  RouteParams,
  SourceDetail,
} from 'pages/finance/ConciliationSourceList/types';

import { getMasked } from 'utils/inputMask';
import { CurrencyDollar } from 'components/icons';
import { fromSQLTimestampToBrazilian } from 'utils/calendarUtils';

import { ScrollPanel } from 'components/containers';

import MotherCreditList from './MotherAccount/TransactionList/CreditList';
import MotherDebitList from './MotherAccount/TransactionList/DebitList';

import ProjectCreditList from './ProjectAccount/TransactionList/CreditList';
import ProjectDebitList from './ProjectAccount/TransactionList/DebitList/DebitList';

import styles from './ConciliationExpenseDetail.module.scss';

const ConciliationExpenseDetail: React.FC = () => {
  const { id, lancamento_id, source } = useParams<RouteParams>();

  const location = useLocation();

  const history = useHistory();

  const sourceDetailUrl = useMemo(() => {
    if (source === 'contas-projeto') {
      return `/financeiro/conciliacao/fonte-recurso/${id}`;
    }
    return `/financeiro/conciliacao/contas-mae/${id}`;
  }, [id, source]);

  const { data: sourceDetailData } = useFetch<SourceDetail>(sourceDetailUrl);

  const { data, reload } = useFetch<ExpenseDetail>(
    `/financeiro/conciliacao/despesas/lancamentos/${lancamento_id}`
  );

  const { tx } = useTranslationX('expenses', 'finance');
  const { t } = useTranslation('translation');

  const { setBreadcrumbs, setFooter, setVisibility, clear } = useDynamicHeader();

  useEffect(() => {
    if (!sourceDetailData || !data) return;
    if (source === 'contas-mae') {
      const { name } = sourceDetailData;
      setBreadcrumbs(['finance', 'expenses', `[${name}]`]);
      setFooter(
        tx('conciliateItem', {
          id: data.transactionId,
        })
      );
    } else if (source === 'contas-projeto') {
      const { number, slug } = sourceDetailData;

      setBreadcrumbs([
        'finance',
        'expenses',
        `[${tx('identification', {
          sigla: slug,
          number,
        })}]`,
      ]);
      setFooter(
        tx('conciliateItem', {
          id: data.transactionId,
        })
      );
    }

    setVisibility(true);
    return () => {
      clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear, setBreadcrumbs, setVisibility, setFooter, sourceDetailData, source, data]);

  const handleBack = (): void => {
    const { pathname } = location;
    history.replace(pathname.split('/lancamento')[0]);
  };

  const parseValueToCurrency = (value: number) => {
    const parsedValue =
      t('currency') +
      ' ' +
      getMasked(Number(value).toFixed(2), {
        pattern: 'currency',
      });
    return parsedValue;
  };

  const { transactionId, description, transactionDate, transactionValue, type } =
    data ?? {};

  const renderList = useCallback(() => {
    if (source === 'contas-mae') {
      return type === 'credito' ? (
        <MotherCreditList onReload={reload} />
      ) : (
        <MotherDebitList onReload={reload} />
      );
    } else if (source === 'contas-projeto') {
      return type === 'credito' ? (
        <ProjectCreditList onReload={reload} />
      ) : (
        <ProjectDebitList onReload={reload} />
      );
    }
  }, [reload, source, type]);

  if (!sourceDetailData || !data) {
    return <Loader />;
  }

  return (
    <ScrollPanel>
      <div className={styles.expenseDetail}>
        <div className={styles.header}>
          <PercentCardWithIcon
            theme="thin"
            label={tx('pendingBalance')}
            icon={CurrencyDollar}
            value={parseValueToCurrency(data.availableValue)}
          />
          <InfoCard
            items={[
              {
                label: tx('id'),
                content: transactionId,
              },
              {
                label: tx('transaction'),
                content: <strong>{description}</strong>,
              },
              {
                label: tx('date'),
                content: fromSQLTimestampToBrazilian(transactionDate),
              },
              {
                label: tx('value'),
                align: 'right',
                content: <strong>{parseValueToCurrency(transactionValue!!)}</strong>,
              },
            ]}
          />
          <div className={styles.back}>
            <Button onClick={handleBack}>{tx('button.expenses')}</Button>
          </div>
        </div>

        {renderList()}
      </div>
    </ScrollPanel>
  );
};

export default ConciliationExpenseDetail;
