export type BalanceStatus = 'PEN' | 'CCI' | 'PCI' | 'CCM';
export type AccountabilityFormatOption = 'XLS' | 'CSV';

export const balanceStatusColor: { [key in BalanceStatus]: string } = {
  PEN: '#FFCFCF',
  PCI: '#F5FFAC',
  CCI: '#B5FFAA',
  CCM: '#B5FFAA',
};

export const balanceStatusMapper = {
  PEN: 'Pendente',
  PCI: 'Parcialmente conciliado',
  CCI: 'Conciliado',
  CCM: 'Conciliado e finalizado',
};

export const accountabilityFormatOptionMapper: {
  [key in AccountabilityFormatOption]: string;
} = {
  XLS: '.xls',
  CSV: '.csv',
};
