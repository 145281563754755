import React, { useEffect, useRef } from 'react';

import { TableList } from 'components/containers';
import { Instance, ListType } from 'components/containers/TableList/TableList';
import { ColumnConfig } from 'components/containers/TableList/types';
import { useTranslation, useTranslationX } from 'i18n';

import { getMasked } from 'utils/inputMask';

import { IntervalFilter } from 'components/inputs/types';
import {
  BalanceStatus,
  balanceStatusColor,
  balanceStatusMapper,
} from 'pages/finance/types';
import {
  parseMoney,
  renderColorizedStatus,
  renderColorizedValue,
  renderInternalTitle,
  renderTdAsTh,
} from 'pages/finance/utils';
import { fromSQLTimestampToBrazilian } from 'utils/calendarUtils';

type Props = {
  data: IntervalFilter;
  id: number;
};

type Transaction = {
  id: number;
  tipo: string;
  valor: number;
  origem: string;
  situacao: BalanceStatus;
  descricao: string;
  valor_conciliado: number;
};
type Item = {
  id: number;
  fit_id: string;
  memo: string;
  data: string;
  valor: number;
  situacao_lancamento: BalanceStatus;
  saldo_a_conciliar_lancamento: number;
  transacoes: Transaction[];
};

const DRDebitList: React.FC<Props> = props => {
  const { id, data } = props;

  const { tx } = useTranslationX('expenses', 'finance');
  const { t } = useTranslation('translation');

  const table = useRef<Instance>();

  let config: ColumnConfig<ListType> = [
    {
      field: 'description',
      align: 'left',
      weight: 0.6,
      type: 'html',
      parse: (_, item: Item) =>
        renderInternalTitle(
          tx('transaction'),
          tx('expenseDescription', {
            fit_id: item.fit_id,
            data: fromSQLTimestampToBrazilian(item.data),
          })
        ),
    },
    {
      field: 'situacao_lancamento',
      align: 'center',
      weight: 0.09,
      type: 'html',
      parse: (value: BalanceStatus) =>
        renderTdAsTh(
          tx('situation'),
          balanceStatusMapper[value],
          'center',
          'center',
          '#3B2821',
          balanceStatusColor[value]
        ),
    },
    {
      field: 'saldo_a_conciliar_lancamento',
      align: 'center',
      weight: 0.1,
      type: 'html',
      parse: (value: number, item: Item) =>
        renderTdAsTh(
          tx('pendingBalance'),
          parseMoney(value, t),
          'right',
          'right',
          '#3B2821',
          balanceStatusColor[item.situacao_lancamento]
        ),
    },
    {
      field: 'valor',
      align: 'center',
      weight: 0.1,
      type: 'html',
      parse: (value: number) =>
        renderTdAsTh(tx('value'), parseMoney(value, t), 'right', 'right'),
    },
  ];

  const subListConfig: ColumnConfig<ListType> = [
    {
      title: tx('rubric'),
      field: 'tipo',
      align: 'center',
      headerAlign: 'left',
      weight: 0.15,
      parse: value => tx(`rubrics.${value}`),
    },
    {
      title: tx('item'),
      field: 'item',
      align: 'left',
      weight: 0.28,
      type: 'html',
      parse: value => `<strong>${value}</strong>`,
    },
    {
      title: tx('solicitationDate'),
      field: 'solicitacao',
      weight: 0.19,
      headerAlign: 'left',
      align: 'center',
      type: 'date',
      searchType: 'date',
      search: true,
      appendDots: false,
    },
    {
      title: tx('creditor'),
      field: 'credor',
      align: 'left',
      weight: 0.15,
    },
    {
      title: tx('invoice'),
      field: 'nota_fiscal',
      align: 'left',
      weight: 0.15,
    },
    {
      title: tx('cnpjCpf'),
      field: 'cnpj_cpf',
      align: 'center',
      weight: 0.1,
    },
    {
      title: tx('situation'),
      field: 'situacao',
      align: 'center',
      weight: 0.17,
      type: 'html',
      parse: renderColorizedStatus,
    },
    {
      title: tx('conciliatedValue'),
      field: 'valor_conciliado',
      align: 'center',
      headerAlign: 'right',
      weight: 0.16,
      type: 'html',
      parse: (item: number, data: Transaction) =>
        renderColorizedValue(item, data.situacao, t),
    },
    {
      title: tx('value'),
      field: 'valor',
      weight: 0.15,
      search: true,
      type: 'html',
      parse: value =>
        value
          ? `<strong>${t('currency')}  ${getMasked(value.toFixed(2), {
              pattern: 'currency',
            })}</strong>`
          : '',
    },
  ];

  useEffect(() => {
    table.current?.reset();
  }, [data]);

  return (
    <TableList<Item>
      defaultSearchProps={{
        sort: 'data,desc',
        filters: {},
      }}
      style={{
        root: {
          maxWidth: '100%',
          maxHeight: 'calc(100vh - 150px)',
        },
      }}
      baseUrl={`/financeiro/conciliacao/despesas/contas-projeto/${id}/debitos-dr`}
      filter
      config={config}
      filterFixed={{
        data,
      }}
      subListConfig={subListConfig}
      subListField="transacoes"
      textSize="small"
      theme="light"
      infinityScroll
      refreshesOn={95}
      editIcon={null}
      notFoundProps={{}}
      reference={table}
    />
  );
};

export default DRDebitList;
