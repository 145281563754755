import TableList, { Instance, ListType } from 'components/containers/TableList/TableList';
import { ColumnConfig } from 'components/containers/TableList/types';
import { useTranslation, useTranslationX } from 'i18n';
import { capitalize } from 'lodash';
import { ProjectStatus, projectStatusColors } from 'pages/project/types';
import { useCallback, useEffect, useRef } from 'react';
import { getMasked } from 'utils/inputMask';

import { useDynamicHeader } from 'components/containers/DynamicHeader';
import { ProjectCard } from './ProjectCard/ProjectCard';
import { useHistory } from 'react-router-dom';

export type ProjectItem = {
  id: number;
  titulo: string;
  numero: string;
  sigla: string;
  coordenador: string;
  empresa: string;
  modalidade: string;
  data_publicacao: string;
  data_finalizacao: string;
  estado: ProjectStatus;
  valor_total: number;
};

const ProjectList = () => {
  const { tx, t } = useTranslationX('accountability.projectList', 'finance');
  const { t: t2 } = useTranslation();

  const history = useHistory();

  const { setBreadcrumbs, setFooter, setVisibility, clear } = useDynamicHeader();

  useEffect(() => {
    setBreadcrumbs(['finance']);
    setFooter(t('accountability.name'));

    setVisibility(true);
    return () => {
      clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear, setBreadcrumbs, setVisibility, setFooter]);

  const table = useRef<Instance>();

  const config: ColumnConfig<ListType> = [
    {
      weight: 0.8,
      title: tx('columns.title'),
      field: 'titulo',
      type: 'html',
      align: 'left',
      search: true,
      parse: value => {
        let title =
          value === null
            ? tx('fields.none_verbose')
            : value.length >= 80
            ? `${value.substring(0, 80)}...`
            : value;
        return `<strong>${capitalize(title)}</strong>`;
      },
    },
    {
      weight: 0.5,
      title: tx('columns.identification'),
      field: 'numero',
      search: true,
    },
    {
      weight: 0.45,
      title: tx('columns.acronym'),
      field: 'sigla',
      search: true,
    },
    {
      weight: 0.75,
      title: tx('columns.coordinator'),
      field: 'coordenador',
      search: true,
      capitalize: true,
    },
    {
      weight: 0.75,
      title: tx('columns.company'),
      field: 'empresa',
      search: true,
      parse: value => {
        return value === null
          ? tx('fields.none_verbose')
          : value.length >= 80
          ? `${value.substring(0, 80)}...`
          : value;
      },
    },
    {
      title: tx('columns.modality'),
      weight: 0.75,
      field: 'modalidade',
      search: true,
      parse: value => {
        return value === null ? tx('fields.none_verbose') : value;
      },
    },
    {
      weight: 0.5,
      title: tx('columns.start_date'),
      field: 'data_publicacao',
      search: true,
      type: 'date',
      searchType: 'date',
    },
    {
      weight: 0.5,
      title: tx('columns.end_date'),
      field: 'data_finalizacao',
      search: true,
      searchType: 'date',
      type: 'date',
    },
    {
      weight: 0.55,
      title: tx('columns.status'),
      field: 'estado',
      type: 'tag',
      search: true,
      metadata: {
        options: Object.keys(projectStatusColors).map(status => {
          return {
            value: status,
            text: t(`accountability.status.${status}`),
          };
        }),
      },
      searchType: 'status',
      parse: (status, _: ProjectItem) => {
        return {
          value: t(`accountability.status.${status}`),
          bgColor: projectStatusColors[status as ProjectStatus],
        };
      },
    },
    {
      weight: 0.5,
      title: tx('columns.total_value'),
      field: 'valor_total',
      align: 'right',
      fieldProps: {
        align: 'right',
      },
      search: true,
      searchType: 'range',
      type: 'html',
      parse: value =>
        value
          ? `<strong>${
              t2('currency') + ' ' + getMasked(value?.toFixed(2), { pattern: 'currency' })
            }</strong>`
          : '',
    },
  ];

  const redirectToDetail = useCallback(
    (item: ProjectItem) => {
      history.push(`/dashboard/financeiro/prestacao-contas/${item.id}`, {
        ...item,
      });
    },
    [history]
  );
  return (
    <TableList<ProjectItem>
      baseUrl="projetos"
      listHeader={{
        title: tx('title'),
        subtitle: tx('subtitle'),
      }}
      defaultSearchProps={{
        sort: 'data,desc',
        filters: {},
      }}
      config={config}
      style={{
        root: {
          maxWidth: 'calc(100% - 20px)',
          maxHeight: 'calc(100vh - 150px)',
          gap: '1.5rem',
        },
      }}
      textSize="small"
      theme="light"
      reference={table}
      keyComposer={(item, index) => `${item.id}-${index}`}
      editIcon={null}
      editAction={redirectToDetail}
      refreshesOn={95}
      pageSize={20}
      notFoundProps={{
        title: tx('error.not_found.title'),
        description: tx('error.not_found.description'),
      }}
      filter
      infinityScroll
      showModeSelector
      renderGrid={(item, edit) => <ProjectCard item={item} onPressView={edit} />}
    />
  );
};

export default ProjectList;
