import { useTranslationX } from 'i18n';

import { getMasked } from 'utils/inputMask';
import styles from './EstimativeCard.module.scss';

type Props = {
  title: string;
  expensesLabel: string;
  balanceLabel: string;

  balance: number;
  incomes: number;
};

const EstimativeCard = (props: Props) => {
  const { title, expensesLabel, balanceLabel, balance, incomes } = props;
  const { t } = useTranslationX('translation');

  const getStyledNumber = (value: number) => {
    const isPositive = value >= 0;
    const prefix = value === 0 ? '' : isPositive ? '+ ' : '- ';

    const formattedBalance = `${t('currency')} ${getMasked(
      Number(Math.abs(value)).toFixed(2),
      {
        pattern: 'currency',
      }
    )}`;
    return (
      <span className={styles[isPositive ? 'positive' : 'negative']}>
        {`${prefix}${formattedBalance}`}
      </span>
    );
  };

  return (
    <div className={styles.estimativeCard}>
      <div className={styles.title}>{title}</div>
      <div className={styles.content}>
        <div className={styles.contentItem}>
          <label>{expensesLabel}</label>
          {getStyledNumber(incomes)}
        </div>
        <div className={styles.contentItem}>
          <label> {balanceLabel}</label>
          {getStyledNumber(balance)}
        </div>
      </div>
    </div>
  );
};

export default EstimativeCard;
