import React from 'react';

import { useTranslationX } from 'i18n';
import { Grid, Row } from 'components/containers';
import { TextField, HiddenField, DatePicker, Select, Localidades } from 'components/form';
import { Message } from 'components/elements';
import { SectionProps } from '../types';
import useAvailability from '../useAvailability';

const Personal = ({ state, dispatch }: SectionProps) => {
  useAvailability('pessoa.cpf', 'cpf', state, dispatch);

  const { tx } = useTranslationX('personal', 'register');
  const {
    availability: { status, person },
    sections,
    step,
  } = state;

  const section = sections[step];
  const availableWithId = status === 'available' && !!person;
  const availableWithoutId = status === 'available' && !person;

  const type = availableWithoutId ? undefined : 'disabled';

  return (
    <Grid>
      <Row width={[2, 3]}>
        <TextField name="pessoa.cpf" label={tx('id')} />
        <TextField name="pessoa.nome" label={tx('name')} type={type} />
      </Row>

      <Row width={[2, 3]}>
        <TextField name="pessoa.telefone" label={tx('phone')} type={type} />
        <TextField name="pessoa.email" label={tx('email')} type={type} />
      </Row>

      <Row width={[4, 4, 4]}>
        <TextField
          name="pessoa.rg"
          label={tx('rg')}
          metadata={{
            rules: {
              required: true,
            },
          }}
          type={type}
        />
        <TextField
          name="pessoa.rgEmissor"
          label={tx('orgao')}
          metadata={{
            rules: {
              required: true,
            },
          }}
          type={type}
        />
        <Select
          name="pessoa.rgUf"
          label={tx('uf')}
          style={{ root: { minWidth: 55 } }}
          metadata={{
            rules: {
              required: true,
            },
          }}
          type={type}
        />
      </Row>

      <Row width={[3, 4, 3]}>
        <Select name="pessoa.genero" label={tx('genero.label')} type={type} />
        <Select name="pessoa.racaCor" label={tx('racaCor.title')} type={type} />

        <DatePicker
          name="pessoa.dataNascimento"
          label={tx('dataNascimento')}
          align="left"
          type={type}
          metadata={{
            rules: {
              required: true,
            },
          }}
          showTime={false}
        />
      </Row>

      <Localidades state="rgUf" city="cidade" prefix="pessoa" />

      <Message visible={availableWithId && !section.done}>
        {tx('confirm', { initials: person?.initials })}
      </Message>

      <HiddenField
        name="pessoa"
        value={person?.id}
        valid={availableWithId}
        type={availableWithoutId ? 'disabled' : undefined}
      />
    </Grid>
  );
};

export default Personal;
