import React, { useEffect } from 'react';

import { Expenditure, OthersExpenditure } from 'components/icons';
import { TopMenuItemIcon } from 'pages/dashboard/types';
import { ConfigTopPanel } from 'pages/dashboard';

import { MotherAccountsTab, ProjectAccountsTab } from '.';
import { useDynamicHeader } from 'components/containers/DynamicHeader';
import { useTranslationX } from 'i18n';

export type ExpenseItem = {
  number: string;
  slug: string;
  pendingCredit: number;
  pendingDebit: number;
  debitValue: number;
  creditValue: number;
  id: number;
};

const ConciliationSourceList = () => {
  const { tx } = useTranslationX('accounts', 'finance');
  const { tx: tx2 } = useTranslationX('expenses', 'finance');
  const { setBreadcrumbs, setFooter, setVisibility, clear } = useDynamicHeader();

  const items: TopMenuItemIcon[] = [
    {
      key: 'contas-projeto',
      component: ProjectAccountsTab,
      icon: OthersExpenditure,
      title: tx('projectAccounts'),
    },
    {
      key: 'contas-mae',
      component: MotherAccountsTab,
      icon: Expenditure,
      title: tx('motherAccounts'),
    },
  ];

  useEffect(() => {
    setBreadcrumbs(['finance']);
    setFooter(tx2('title'));

    setVisibility(true);
    return () => {
      clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear, setBreadcrumbs, setVisibility, setFooter]);

  return (
    <>
      <ConfigTopPanel items={items} />
    </>
  );
};

export default ConciliationSourceList;
