import React, { CSSProperties, ReactNode, useRef } from 'react';

import { renderComponent } from 'utils/components';
import { Loader, Ripple } from '..';
import { followCursor } from 'tippy.js';

import styles from './Button.module.scss';
import Tippy from '@tippyjs/react';
import { usePromisePending } from 'components/hooks';

export type ButtonProps = {
  type?: 'primary' | 'secondary' | 'danger' | 'disabled';
  icon?: React.ElementType;
  align?: 'left' | 'right';
  onClick?: () => void;
  style?: { root?: CSSProperties; text?: CSSProperties; container?: CSSProperties };
  theme?: 'classic' | 'light' | 'minimalist';
  active?: boolean;
  loading?: boolean;
  iconProps?: object;
  tooltip?: boolean;
  children?: ReactNode;
  hoverable?: boolean;
};

const Button: React.FC<ButtonProps> = props => {
  const {
    type = 'primary',
    icon,
    align = 'left',
    onClick,
    theme = 'classic',
    active = false,
    loading = false,
    iconProps,
    hoverable = false,
    tooltip = true,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const classes = [styles.button, styles[type]];

  const [handleClick, promiseLoading] = usePromisePending(async () => {
    await onClick?.();
  });

  if (icon) {
    classes.push(styles.icon, styles[align]);
  }

  if (['light', 'minimalist'].includes(theme)) {
    classes.push(styles[theme]);
  }

  if (active) {
    classes.push(styles.active);
  }

  if (loading) {
    classes.push(styles.loading);
  }

  if (hoverable) {
    classes.push(styles.hovereable);
  }

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && ref.current) {
      ref.current.click();
    }
  };

  const Content = (
    <div
      ref={ref}
      className={classes.join(' ')}
      onClick={handleClick}
      tabIndex={type !== 'disabled' ? 0 : -1}
      onKeyDown={onKeyDown}
      style={props.style?.root}
    >
      <div tabIndex={-1} style={props.style?.container}>
        {icon && (
          <div className={styles.icon}>
            {loading || promiseLoading ? (
              <Loader size={30} />
            ) : (
              renderComponent(icon, iconProps)
            )}
          </div>
        )}
        <div className={styles.text} style={props.style?.text} data-hoverable={hoverable}>
          {props.children}
        </div>
        {type !== 'disabled' && (
          <Ripple parentRef={ref} folowCursor={true} size="2w"></Ripple>
        )}
      </div>
    </div>
  );
  return tooltip ? (
    <Tippy
      content={props.children}
      className={styles.popup}
      animation="perspective"
      touch={false}
      followCursor="horizontal"
      plugins={[followCursor]}
      visible={false}
    >
      {Content}
    </Tippy>
  ) : (
    Content
  );
};

export default Button;
