import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  Fragment,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  AutoCompute,
  CEP,
  DatePicker,
  FieldSet,
  FileUploader as FileUploaderForm,
  Form,
  HiddenField,
  Localidades,
  RadioGroup,
  Select,
  SubmitButton,
  TableSelect,
  TextArea,
  TextEditor,
  TextField,
  useForm,
  useFormObserver,
} from 'components/form';
import { Button, Loader, Spacer, WrapperCard } from 'components/elements';

import {
  Grid,
  InfoDialog,
  Modal,
  ModalPDFViewer,
  Row,
  TableData,
} from 'components/containers';

import { useTranslation, useTranslationX } from 'i18n';
import {
  FieldObserverResult,
  FieldOption,
  FormObserverResult,
} from 'components/form/types';
import { useProjectEditor } from 'pages/project/ProjectEditor/contexts';
import {
  CostUnitItem,
  RequestItem,
  RoleItem,
  SolicitationFinality,
} from 'pages/project/types';
import { People, PeopleAddress } from 'pages/people/types';
import { useRequest } from 'apis';
import { GenericObject } from 'components/inputs/types';
import { messageService } from 'services';

import { Download } from 'components/icons';
import { FormObserverContext } from 'components/form/FormContext';
import { useFetchWithEnable } from 'apis/useFetch';
import { CardRoleHire } from 'pages/project';
import { capitalize, downloadFileFromData } from 'utils/stringUtils';
import { CLTForm } from './CLTForm';
import { HumanResourcesArchives } from './HumanResourcesArchives';
import { toDate, toSQLTimestamp } from 'utils/calendarUtils';

import styles from './RequestForm.module.scss';
import { isEqual } from 'lodash';

type Props = {
  type?: SolicitationFinality;
  step?: number;
  updateStep?: (step: number) => void;
};

type ProfileType = 'Estudante' | 'CLT' | 'Servidor' | 'archives';

type RhProps = {
  item?: RoleItem;
  type?: SolicitationFinality;
  projectId: number;
  step?: number;
  downloadTermo: (instantDownload: boolean) => void;
  handleRhArchives: (value?: boolean[]) => void;
};

export type ContractAndPeople = {
  contrato: {
    docRg: number | null;
    docComprovanteResidencia: number | null;
    docDeclaracaoMatricula: number | null;
    docDeclaracaoCiencia: number | null;
    docHistoricoEscolar: number | null;
    docComprovanteContaCorrente: number | null;
    docDescricaoProcessoSeletivo: number | null;
    docSolicitacaoDeContratacaoDeBolsa: number | null;
    docOutorgaEConcessao: number | null;
    docAutorizacaoPolo: number | null;
    docTermoConfidencialidade: number | null;
    docContracheque: number | null;
    docDeclaracaoVencimentos: number | null;
    docDeclaracaoPi: number | null;
    docCertidaoCasamento: number | null;
    docComprovanteEscolaridade: number | null;
    cargoCbo?: {
      id: string;
      titulo: string;
      codigo: string;
    };
  } & {
    [key: string]: number | string;
  };
  pessoa: {
    id: number;
    nome: string;
  };
  item: Partial<RoleItem>;
};

const RequestForm: React.FC<Props> = props => {
  const { tx } = useTranslationX('request.form', 'project');
  const history = useHistory<{ id?: number }>();
  const { state } = useProjectEditor();

  const isHrForm = history.location.pathname.includes('recursos-humanos');

  const [formStep, setFormStep] = useState<number | undefined>(isHrForm ? 0 : undefined);

  const handleFormSubmit = useCallback(
    (data: { requestBody?: any; responseBody?: any; section?: string }) => {
      const isHr = isHrForm && data.responseBody.status === 'success' && formStep === 0;

      if (isHr) {
        setFormStep(1);
        return;
      }
      if (!isHrForm) {
        history.goBack();
      }
      return;
    },
    [isHrForm, formStep, history]
  );

  function updateStep(step: number) {
    setFormStep(step);
  }

  return (
    <WrapperCard padding="10px 30px" minWidth="680px">
      <h2 className={styles.title}>{tx(`title.${state.section}`)}</h2>
      <Form
        baseUrl={`/projeto/${state.id}/execucao/${state.section}/solicitacoes/${props.type}`}
        submit={{ format: 'multipart', method: 'post' }}
        onDone={handleFormSubmit}
        display={{
          error: 'touched',
        }}
      >
        <Content {...props} step={formStep} updateStep={updateStep} />
      </Form>
    </WrapperCard>
  );
};

type showModals = {
  upload: boolean;
  pdf: boolean;
};

const Content: React.FC<Props> = ({ type, step, updateStep }) => {
  const { tx } = useTranslationX('request.form', 'project');
  const { state } = useProjectEditor();
  const history = useHistory<{
    profileType?: 'Estudante' | 'Servidor' | 'CLT';
    id?: number;
  }>();
  const location = useLocation();
  const form = useForm();
  const request = useRequest();

  const { id } = history.location.state || {};

  const [validForm, setValidForm] = useState(false);
  const [pdfData, setPdfData] = useState<string | ArrayBuffer | null>(null);
  const [visible, setVisible] = useState<showModals>({ upload: false, pdf: false });

  const pathnames = location.pathname.split('/');
  const projectId = pathnames[pathnames.length - 3];
  const item = state.item;

  const hasSteps = step !== undefined;

  const updateFormValidation = useCallback((archivesStatus?: boolean[]) => {
    setValidForm(!archivesStatus?.includes(false));
  }, []);

  const handleRhForm = () => {
    form.submit();
  };

  const downloadDeclaracao = useCallback(
    (instantDownload = false) => {
      const source = request<any>({
        url: `/projeto/${id}/recursos-humanos/${state.item?.id}/termo`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
        onSuccess: (blob: any) => {
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            let base64data = reader.result;

            if (!instantDownload) {
              setPdfData(base64data);
              setVisible({ pdf: true, upload: false });
            }

            if (instantDownload) {
              downloadFileFromData(base64data);
            }
          };
        },
        onError: data => console.error(data),
      });

      return () => source.cancel();
    },
    [id, state.item?.id, request]
  );

  const handleRhArchives = useCallback(() => {
    const source = request({
      url: `projeto/${projectId}/recursos-humanos/${id}/submit`,
      method: 'PUT',
      onSuccess: () => {
        history.goBack();
      },
    });

    return () => source.cancel();
  }, [request, history, id, projectId]);

  const isRhForm = type === 'rh' || hasSteps;

  const renderSubmitButtons = () => {
    if (isRhForm) {
      if (step === 0) {
        return <Button onClick={handleRhForm}>{tx('nextPage')}</Button>;
      }
      if (step === 1 && validForm) {
        return <Button onClick={handleRhArchives}>{tx('create')}</Button>;
      }
      return null;
    }

    return (
      <SubmitButton style={{ root: { width: 195 } }}>
        {type === 'diaria' ? tx('createDiaria') : tx('create')}
      </SubmitButton>
    );
  };

  const deleteContratoInfo = useCallback(() => {
    const source = request({
      url: `/projeto/${projectId}/recursos-humanos/${id}/contrato-info`,
      method: 'DELETE',
      onSuccess: () => {
        messageService.success(tx('successRemovedContract'), {
          duration: 2000,
          onClose: () => history.goBack(),
        });
      },
      onError: () => messageService.error(tx('errorRemovedContract'), { duration: 2000 }),
    });

    return () => source.cancel();
  }, [id, projectId, request, tx, history]);

  const returnFunction = () => {
    if (isRhForm && step === 0 && (item as RoleItem).status === 'EDT') {
      deleteContratoInfo();
      return;
    }

    if (isRhForm && step === 1) {
      updateStep && updateStep(0);
      return;
    }

    history.goBack();
  };

  const handleClose = () => {
    setVisible({ ...visible, pdf: false });
  };

  return (
    <>
      {(type === 'compra' || type === 'servico') && (
        <OrderOrService type={type} item={item as CostUnitItem} />
      )}
      {(type === 'diaria' || type === 'passagem') && (
        <DailyOrTravel projectId={state.id} type={type} item={item as CostUnitItem} />
      )}
      {type === 'rh' && (
        <>
          {step === 1 && state?.item?.tipo === 'Bolsa' && (
            <Button
              icon={Download}
              iconProps={{ color: '#fff' }}
              onClick={() => downloadDeclaracao()}
            >
              {tx('createTermo')}
            </Button>
          )}
          <Modal visible={visible?.pdf} onBlur={handleClose} onClose={handleClose}>
            <ModalPDFViewer
              pdfData={pdfData}
              title={tx('termoOutorga')}
              onClose={handleClose}
            />
          </Modal>
          <HumanResources
            item={item as RoleItem}
            projectId={state.id}
            step={step}
            handleRhArchives={updateFormValidation}
            downloadTermo={downloadDeclaracao}
          />
        </>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      >
        <Button type="secondary" onClick={returnFunction}>
          {isRhForm && step === 1 ? tx('return') : tx('cancel')}
        </Button>

        {renderSubmitButtons()}
      </div>
    </>
  );
};

const OrderOrService = ({
  type,
  item,
}: {
  type?: SolicitationFinality;
  item?: CostUnitItem;
}) => {
  const { tx } = useTranslationX('request.form', 'project');
  const { t } = useTranslation('translation');
  return (
    <Grid maxWidth="680px">
      <HiddenField name="id" value={item?.id} />
      <Row width={[0.55, 0.45]}>
        <TextField
          label={tx('itemName')}
          name="titulo"
          type="readonly"
          value={item?.descricao}
        />
      </Row>

      <Row width={[0.4, 0.4, 0.2]}>
        <TextField
          name="valorDisponivel"
          value={item?.disponivel}
          adornment={{
            left: t('currency'),
          }}
          metadata={{ rules: { mask: { pattern: 'currency' } } }}
          label={tx('valueAvailable')}
          type="readonly"
        />
        <TextField
          name="valor"
          value={0}
          adornment={{
            left: t('currency'),
          }}
          label={tx('valueRequested')}
          align="left"
        />
      </Row>

      {type === 'servico' ? (
        <>
          <RadioGroup name="tipoServico" columns={3} label={tx('tipoServico')} />
          <Spacer length={20} />
          <RadioGroup
            name="tipoPrestador"
            columns={2}
            parseOption={(value: FieldOption) => tx(`tipoPrestadorOption.${value.text}`)}
            label={tx('tipoPrestador')}
          />
        </>
      ) : (
        <div className={styles.container}>
          <h1>{tx('addressDelivery')}</h1>
          <FieldSet name="endereco">
            <Row width={[0.25, 0.25, 0.5]}>
              <TextField name="cep" label={tx('code')} />
              <Select name="uf" label={tx('state')} />
              <Select name="cidade" label={tx('city')} />
            </Row>
            <Row width={[0.3, 0.1, 0.4, 0.2, 0.0]}>
              <TextField name="logradouro" label={tx('street')} />
              <TextField name="numero" label={tx('number')} />
              <TextField name="complemento" label={tx('complement')} />
              <TextField name="bairro" label={tx('district')} />
            </Row>
            <Localidades city="cidade" prefix="endereco" state="uf" />
            <CEP
              cep="cep"
              uf="uf"
              logradouro="logradouro"
              bairro="bairro"
              cidade="cidade"
              prefix="endereco"
            />
          </FieldSet>
        </div>
      )}
      <Spacer length={20} />
      {type === 'servico' ? (
        <>
          <Row align="top">
            <WrapperCard height="300px" width="100%" maxWidth="100%" elevation={false}>
              <TextEditor name="descricao" width="100%" label={tx('itemDescription')} />
            </WrapperCard>
          </Row>
          <Row align="top">
            <WrapperCard height="300px" width="100%" maxWidth="100%" elevation={false}>
              <TextEditor name="justificativa" width="100%" label={tx('justification')} />
            </WrapperCard>
          </Row>
          <Row align="top">
            <WrapperCard height="300px" width="100%" maxWidth="100%" elevation={false}>
              <TextEditor
                name="criterioSelecao"
                width="100%"
                label={tx('selectionCriteria')}
              />
            </WrapperCard>
          </Row>
        </>
      ) : (
        <>
          <Row align="top">
            <WrapperCard height="300px" width="100%" maxWidth="100%" elevation={false}>
              <TextEditor name="descricao" width="100%" label={tx('itemDescription')} />
            </WrapperCard>
          </Row>
          <Row align="top">
            <WrapperCard height="300px" width="100%" maxWidth="100%" elevation={false}>
              <TextEditor name="justificativa" width="100%" label={tx('justification')} />
            </WrapperCard>
          </Row>
        </>
      )}
      <Spacer length={20} />
      <Row align="top">
        <FileUploaderForm
          name="termo"
          type="sneaky"
          label={tx('upload.term.label')}
          message={tx('upload.term.description')}
        />
        <FileUploaderForm
          name="propostas"
          type="sneaky"
          label={tx('upload.proposal.label')}
          message={tx('upload.proposal.description')}
        />
      </Row>
    </Grid>
  );
};

const EsferaInput = () => {
  const form = useForm();
  const formContext = useContext(FormObserverContext);
  const { tx } = useTranslationX('request.form', 'project');

  const [showEsfera, setShowEsfera] = useState<boolean>(false);

  useEffect(() => {
    const callback = (res: FormObserverResult) => {
      const hasValue = (res as FieldObserverResult)?.data?.value;
      setShowEsfera(hasValue);
    };

    formContext.subscribe(callback, { field: 'servidorPublico' });
    return () => formContext.unsubscribe(callback);
  }, [formContext, form]);

  return showEsfera ? (
    <div className={styles.dashedContainer}>
      <RadioGroup
        name="esfera"
        label={tx('sphere')}
        metadata={{
          options: [
            { value: 'federal', text: 'Federal' },
            { value: 'estadual', text: 'Estadual' },
            { value: 'municipal', text: 'Municipal' },
          ],
        }}
        columns={3}
      />
    </div>
  ) : null;
};

const DailyOrTravel = ({
  type,
  item,
  projectId,
}: {
  projectId: number;
  type?: SolicitationFinality;
  item?: CostUnitItem;
}) => {
  const form = useForm();
  const { tx } = useTranslationX('request.form', 'project');
  const { t } = useTranslation('translation');

  const onPopulateData = (data?: GenericObject) => {
    if (data !== undefined && Object.keys(data).length > 0) {
      form.setFormValues(
        {
          passageiro: data.nome,
          cpf: data.cpf,
          telefone: data.telefone,
          email: data.email,
        },
        true
      );
    }
  };

  return (
    <Grid maxWidth={type === 'diaria' ? '810px' : '1200px'}>
      <HiddenField name="titulo" value={item?.descricao} />
      <HiddenField name="id" value={item?.id} />
      <Row width={type === 'diaria' ? [0.65, 0.35] : [0.4, 0.3, 0.2]}>
        <Row width={[0.9, 0.7]}>
          <TableSelect
            name="passageiro_id"
            label={tx('passenger.search')}
            title={tx('passenger.search')}
            placeholder={tx('passenger.placeholder')}
            onPopulate={onPopulateData}
            formatOption={option => option.nome}
            fetchUrl={`/projeto/${projectId}/execucao/equipe`}
            tableConfig={[
              {
                title: tx('name.label'),
                field: 'nome',
                observerField: 'pessoa.select.nome',
                align: 'left',
                search: true,
                weight: 0.4,
              },
              {
                title: tx('cpf'),
                field: 'cpf',
                align: 'center',
                weight: 0.4,
              },
              {
                title: tx('email'),
                field: 'email',
                align: 'center',
                weight: 0.4,
              },
            ]}
          />
        </Row>
        {type === 'passagem' && (
          <Row>
            <TextField
              name="valorDisponivel"
              value={item?.disponivel}
              adornment={{
                left: t('currency'),
              }}
              metadata={{ rules: { mask: { pattern: 'currency' } } }}
              label={tx('valueAvailable')}
              type="readonly"
            />
            <TextField
              name="valor"
              label={tx('valueRequested')}
              align="left"
              value={0}
              metadata={{
                rules: {
                  required: true,
                },
              }}
            />
          </Row>
        )}
      </Row>
      <Spacer length={10} />
      <Row width={type === 'passagem' ? [0.4, 0.15, 0.35] : [0.6, 0.3, 0.05]}>
        <TextField
          name="passageiro"
          label={tx('passenger.name')}
          placeholder={tx('passenger.placeholder')}
          align="left"
        />
        <TextField name="cpf" label={tx('cpf')} align="left" />
      </Row>
      <Spacer length={10} />
      <Row width={type === 'passagem' ? [0.2, 0.3, 0.6] : [0.4, 0.4, 0.2]}>
        <TextField name="telefone" label={tx('telephone')} placeholder="(  )       -" />
        <TextField
          name="email"
          label={tx('email')}
          placeholder={tx('email-placeholder')}
        />
      </Row>
      <Spacer length={10} />
      {type === 'diaria' && (
        <>
          <FieldSet name="endereco">
            <Row width={[0.25, 0.25, 0.5]}>
              <TextField name="cep" label={tx('code')} />
              <Select name="uf" label={tx('state')} />
              <Select name="cidade" label={tx('city')} />
            </Row>
            <Row width={[0.3, 0.1, 0.4, 0.2, 0.0]}>
              <TextField name="logradouro" label={tx('street')} />
              <TextField name="numero" label={tx('number')} />
              <TextField name="complemento" label={tx('complement')} />
              <TextField name="bairro" label={tx('district')} />
            </Row>
            <Localidades city="cidade" prefix="endereco" state="uf" />
            <CEP
              cep="cep"
              uf="uf"
              logradouro="logradouro"
              bairro="bairro"
              cidade="cidade"
              prefix="endereco"
            />
          </FieldSet>
          <Spacer length={10} />
          <Row width={[0.3, 0.7]}>
            <RadioGroup
              name="clt"
              label={tx('contract')}
              metadata={{
                options: [
                  { value: true, text: t('components.dialog.confirm.yes') },
                  { value: false, text: t('components.dialog.confirm.no') },
                ],
              }}
              columns={2}
            />
          </Row>
          <Spacer length={20} />
          <Row align="center" width={[0.4, 0.4, 0.2]}>
            <RadioGroup
              name="servidorPublico"
              label={tx('publicServer')}
              metadata={{
                options: [
                  { value: true, text: t('components.dialog.confirm.yes') },
                  { value: false, text: t('components.dialog.confirm.no') },
                ],
              }}
              columns={2}
            />
            <EsferaInput />
          </Row>
          <Spacer length={10} />
          <Row width={[0.6, 0.4]}>
            <TextField name="cargo" label={tx('function')} />
          </Row>
          <Spacer length={10} />

          <div className={styles.container}>
            <h1>{tx('bankData')}</h1>
            <FieldSet name="conta">
              <Row width={[0.3, 0.3, 0.2, 0.2]}>
                <TextField name="banco" label={tx('bank')} />
                <TextField
                  name="agencia"
                  label={tx('agency')}
                  metadata={{
                    rules: { required: true, maxlength: 6, mask: { pattern: 'agency' } },
                  }}
                />
                <TextField
                  name="numero"
                  label={tx('account')}
                  metadata={{
                    rules: {
                      required: true,
                      mask: {
                        pattern: 'account',
                      },
                    },
                  }}
                />
              </Row>
            </FieldSet>
          </div>
          <Spacer length={10} />
          <Row width={[0.5, 0.5]}>
            <TextArea
              name="objetivo"
              label={tx('objective')}
              placeholder={tx('objective-placeholder')}
              minRows={8}
            />
          </Row>
          <Spacer length={10} />
          <Row width={[0.5, 0.4, 0.4, 0.2]}>
            <TextField name="destino" label={tx('destination')} align="left" />
            <DatePicker name="dataIda" label={tx('dataIda')} align="left" />

            <DatePicker name="dataVolta" label={tx('dataVolta')} align="left" />
          </Row>
          <Spacer length={10} />
          <Row width={[0.4, 0.6]}>
            <TextField name="meioTransporte" label={tx('transport')} />
          </Row>
          <Spacer length={10} />
          <Row width={[0.35, 0.35, 0.3]}>
            <TextField
              name="valorUnitario"
              label={tx('daily-value')}
              align="left"
              value={0}
            />
            <TextField
              name="quantidade"
              label={tx('daily-amount')}
              align="left"
              value={1}
            />
          </Row>
          <Spacer length={10} />
          <Row width={[0.35, 0.35, 0.3]}>
            <TextField
              name="valorDisponivel"
              value={item?.disponivel}
              adornment={{
                left: t('currency'),
              }}
              metadata={{ rules: { mask: { pattern: 'currency' } } }}
              label={tx('valueAvailable')}
              type="readonly"
              align="left"
            />
            <AutoCompute
              name="valor"
              adornment={{
                left: t('currency'),
              }}
              expression="valorUnitario * quantidade"
              align="left"
              label={tx('valueRequested')}
            />
          </Row>
          <Spacer length={50} />
        </>
      )}
      {type === 'passagem' && (
        <>
          <Row width={[0.4, 0.3, 0.3, 0.6]}>
            <TextField
              name="passaporte"
              label={tx('numberPassport')}
              align="left"
              metadata={{
                rules: { required: false },
              }}
            />
            <DatePicker name="dataIda" label={tx('dataIda')} align="left" />
            <DatePicker name="dataVolta" label={tx('dataVolta')} align="left" />
          </Row>
          <Spacer length={10} />
          <Row width={[0.4, 0.6]}>
            <TextArea
              name="motivo"
              label={tx('motivo')}
              placeholder={tx('motivo-placeholder')}
              minRows={8}
            />
          </Row>
          <Row width={[0.4, 0.6]}>
            <TextArea
              name="itinerario"
              label={tx('itinerario')}
              placeholder={tx('itinerario-placeholder')}
              minRows={8}
            />
          </Row>
          <Spacer length={10} />
          <div className={styles.container}>
            <h1>{tx('flightSuggestion')}</h1>
            <TableData
              name="trechos"
              textSize="small"
              addLabel={tx('newSuggestion')}
              style={{ table: { minWidth: 1110 } }}
              config={[
                { title: tx('cod'), field: 'codigo', align: 'left', weight: 1.5 },
                { title: tx('origin'), field: 'origem', align: 'left' },
                {
                  title: tx('destination'),
                  field: 'destino',
                  align: 'left',
                },
                {
                  title: tx('boarding'),
                  field: 'embarque',
                  align: 'left',
                },
                {
                  title: tx('departure'),
                  field: 'partida',
                  type: 'date',
                  align: 'left',
                },
                {
                  title: tx('arrival'),
                  field: 'chegada',
                  type: 'date',
                  align: 'left',
                },
              ]}
            />
          </div>
        </>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      />
    </Grid>
  );
};

const classificationProfiles = {
  Estudante: 'DISCENTE',
  Pesquisador: 'DOCENTE',
};

const LoopProtect = () => {
  const form = useForm();

  const { tx } = useTranslationX('request.form', 'project');

  useFormObserver('contrato.vinculoEmpregaticio');

  const vinculoValue = form.getFieldValue('contrato.vinculoEmpregaticio');

  return (
    <div className={styles.dashedContainer}>
      <TextField
        name="localTrabalho"
        label={tx('workplace')}
        style={{
          root: {
            width: '100%',
          },
        }}
        type={!!vinculoValue ? undefined : 'disabled'}
        metadata={{
          rules: {
            required: true,
          },
        }}
      />
    </div>
  );
};

const HumanResources = ({
  handleRhArchives,
  projectId,
  item,
  step,
  downloadTermo,
}: RhProps) => {
  const { dispatch } = useProjectEditor();

  const history = useHistory<{ profileType?: string; id?: number }>();

  const { id } = history.location.state || {};

  const [profileId, setProfileId] = useState(undefined);

  const profileType = useRef(history.location.state?.profileType);

  const { tx } = useTranslationX('request.form', 'project');
  const { t } = useTranslation('translation');
  const form = useForm();
  const formInstance = useContext(FormObserverContext);
  const request = useRequest();

  const peopleChange = useFormObserver('pessoa.id', true);

  const contractChange = useFormObserver(
    new RegExp('contrato\\.(duracao|dataInicio)'),
    true,
    false,
    true
  );

  const peopleId =
    peopleChange?.data?.value?.id ||
    (peopleChange?.data?.value as number | null | undefined);

  const isCLT = item?.tipo.includes('CLT');
  const isIntern = item?.tipo.includes('Estágio');

  const itemStatus = item?.status;

  const fetchData = itemStatus !== 'AVA' || step !== 0;

  const { data, reload } = useFetchWithEnable<ContractAndPeople>(
    `/projeto/${projectId}/recursos-humanos/${id}/contrato-info`,
    fetchData
  );

  const updateItem = useCallback(() => {
    const newItem = {
      ...item,
      ...data?.item,
    };
    if (isEqual(item, newItem)) return;
    dispatch({
      type: 'SET_ITEM',
      payload: newItem as RequestItem,
    });
  }, [data?.item, dispatch, item]);

  useEffect(() => {
    updateItem();
  }, [data, updateItem]);

  useEffect(() => {
    reload();
  }, [step, reload]);

  useEffect(() => {
    if (form) {
      const callback = () => {
        form.setFieldValue('pessoa.id', undefined);
      };
      formInstance.subscribe(callback, { onError: true });
      return () => formInstance.unsubscribe(callback);
    }
  }, [form, formInstance]);

  const changePeopleDataFromRequest = useCallback(
    (data: People) => {
      const keysOfPeople = ['nome', 'cpf'] as (keyof People)[];
      keysOfPeople.forEach(field => {
        form.setFieldValue(`pessoa.${field}`, data[field]);
      });

      if (isCLT && data.endereco) {
        const keysOfPeople = [
          'bairro',
          'cep',
          'cidade',
          'complemento',
          'numero',
          'uf',
          'logradouro',
        ] as (keyof PeopleAddress)[];
        keysOfPeople.forEach(field => {
          form.setFieldValue(
            `contrato.localAtividades.endereco.${field}`,
            data.endereco?.[field]
          );
        });
      }
    },
    [form, isCLT]
  );

  const onPeopleChange = useCallback(() => {
    if (peopleId) {
      request<People>({
        url: `pessoa/basico/${peopleId}`,
        onSuccess: changePeopleDataFromRequest,
      });
    }
  }, [peopleId, request, changePeopleDataFromRequest]);

  const validateAndAddContratoField = useCallback(
    (field: string, value: any) => {
      if (value) {
        form.setFieldValue(`contrato.${field}`, value, true);
      }
    },
    [form]
  );

  const removeContratoFields = useCallback(
    (fields: string[]) => {
      fields.forEach(field => {
        const fieldPath = `contrato.${field}`;
        if (form.getFieldValue(fieldPath) !== undefined) {
          form.setFieldValue(fieldPath, undefined);
        }
      });
    },
    [form]
  );

  const onPopulateData = useCallback(
    (data: any) => {
      if (data) {
        if (data.perfil && itemStatus !== 'AVA') {
          profileType.current = data.perfil;
        }
        if (!isCLT) {
          validateAndAddContratoField('localTrabalho', data.instituicao);
        }
        if (isCLT) {
          validateAndAddContratoField('email', data.email);
          validateAndAddContratoField('racaCor', data.racaCor);
        }
        validateAndAddContratoField('nomeSocial', data.nome);
        validateAndAddContratoField('rg', data.rg);
        validateAndAddContratoField('genero', data.genero);
        validateAndAddContratoField('rgUf', data.rgUf);
        validateAndAddContratoField('rgEmissor', data.rgEmissor);
        validateAndAddContratoField('vinculoEmpregaticio', Number(!!data.instituicao));
        validateAndAddContratoField(
          'classificacao',
          data.perfil === 'Estudante' || data.perfil === 'Pesquisador'
            ? classificationProfiles[data.perfil as keyof typeof classificationProfiles]
            : 'OUTROS'
        );

        if (data.dataNascimento) {
          validateAndAddContratoField(
            'dataNascimento',
            toSQLTimestamp(data.dataNascimento)
          );
        }

        setProfileId(data.perfil_id);
      } else {
        setProfileId(undefined);
        form.setFieldValue('pessoa.nome', undefined);
        removeContratoFields(['rg', 'genero', 'rgUf', 'rgEmissor', 'dataNascimento']);
      }
    },
    [itemStatus, isCLT, validateAndAddContratoField, form, removeContratoFields]
  );

  useEffect(() => {
    onPeopleChange();
  }, [peopleId, onPeopleChange]);

  useEffect(() => {
    if (itemStatus === 'EDT' && data && !form.getFieldValue('pessoa.cpf')) {
      form.setFormValues({ ...data, id: undefined }, true, false, true);
    }
  }, [data, form, itemStatus]);

  useEffect(() => {
    const contractName = {
      'contrato.duracao': 'contrato.dataInicio',
      'contrato.dataInicio': 'contrato.duracao',
    };

    const fieldToRemove = contractName[contractChange?.name as keyof typeof contractName];

    if (contractChange?.name === fieldToRemove) {
      form.removeRejected(fieldToRemove);
    }
  }, [contractChange, form]);

  if (!data && fetchData) {
    return <Loader type="spin" />;
  }

  function onDownloadTermo() {
    downloadTermo(true);
  }

  if (step === 1) {
    return (
      <>
        {!isIntern && !isCLT ? (
          <InfoDialog
            visible={true}
            width={550}
            title={tx('hrDocumentsWarning.title')}
            message={tx('hrDocumentsWarning.message')}
            buttonLabel={tx('downloadOutorga')}
            closeButton
            onSubmit={onDownloadTermo}
          />
        ) : null}

        <HumanResourcesArchives
          data={data}
          reload={reload}
          handleArchives={handleRhArchives}
          profileType={profileType.current as ProfileType}
          projectId={projectId}
        />
      </>
    );
  }

  if (isCLT) {
    return (
      <CLTForm
        onPopulateData={onPopulateData}
        data={data}
        item={item}
        projectId={projectId}
        profileId={profileId}
      />
    );
  }

  return (
    <Grid maxWidth="970px" minWidth={isIntern ? '900px' : undefined}>
      <HiddenField name="id" value={item?.id} />
      <HiddenField name="perfilId" value={profileId} />
      <FieldSet name="pessoa">
        <HiddenField
          name="dataNascimento"
          value={toDate(form.getFieldValue('contrato.dataNascimento'))}
        />
        <Row width={[0.4, 0.1, 0.5]} align="baseline">
          <TableSelect
            name="id"
            label={tx('person.select')}
            title={tx('person.title')}
            placeholder={tx('person.placeholder')}
            metadata={{ rules: { required: true } }}
            onPopulate={onPopulateData}
            initialValue={
              data
                ? {
                    id: data?.pessoa.id,
                    nome: data?.pessoa.nome,
                  }
                : null
            }
            formatOption={option => option.nome}
            fetchUrl={`/projeto/${projectId}/recursos-humanos/${item?.id}/candidatos`}
            tableConfig={[
              {
                title: tx('name.label'),
                field: 'nome',
                observerField: 'pessoa.select.nome',
                align: 'left',
                search: true,
                weight: 1.2,
              },
              {
                title: tx('profile'),
                field: 'perfil',
                align: 'center',
                weight: 0.4,
              },
              {
                title: tx('titration'),
                field: 'titulacao',
                align: 'center',
                weight: 0.4,
              },
              { title: tx('link'), field: 'vinculo', align: 'center', weight: 0.4 },
            ]}
            style={{
              root: {
                margin: 0,
              },
            }}
          />
          <Fragment />
          <CardRoleHire
            role={item as RoleItem}
            cardStyle={{
              margin: '0px 0px 9px',
            }}
          />
        </Row>
      </FieldSet>
      <Row width={[0.4, 0.6]}>
        <FieldSet name="contrato">
          <RadioGroup
            label={tx('classificacao')}
            name="classificacao"
            columns={3}
            parseOption={(value: FieldOption) => tx(`${value.text}`)}
            metadata={{
              options: ['docente', 'discente', 'outros'].map(value => {
                return { text: capitalize(value), value: value.toUpperCase() };
              }),
              rules: {
                required: true,
              },
            }}
          />
        </FieldSet>
      </Row>
      <Spacer length={10} />

      <Row width={[0.4, 0.3, 0.2, 0.1]}>
        <FieldSet name="pessoa">
          <TextField
            name="nome"
            label={tx('name.label')}
            placeholder={tx('name.placeholder')}
            align="left"
            type="readonly"
          />
        </FieldSet>

        <DatePicker name="contrato.dataNascimento" label={tx('birth')} align="left" />
        <Select name="contrato.genero" label={tx('gender.label')} />
      </Row>
      <Row width={[0.7, 0.3]}>
        <FieldSet name="contrato">
          <TextField
            name="nomeSocial"
            label={tx('socialName.label')}
            placeholder={tx('socialName.placeholder')}
            align="left"
          />
        </FieldSet>
      </Row>

      <FieldSet name="contrato">
        <HiddenField name="tipo" value={item?.tipo} />
        <HiddenField name="descricaoAtividades" value="-" />

        <Spacer length={10} />
        <Row width={[0.2, 0.2, 0.12, 0.38]}>
          <TextField name="rg" label={tx('rg')} placeholder={tx('rg')} align="left" />
          <TextField
            name="rgEmissor"
            label={tx('orgaoEmissor')}
            placeholder={tx('orgaoEmissor')}
            align="left"
          />
          <Select name="rgUf" label={tx('uf')} />
          <Localidades state="rgUf" prefix="contrato" />
        </Row>
        <Spacer length={10} />
        <FieldSet name="conta">
          <div className={styles.container}>
            <h1>{tx('bankData')}</h1>
            <Row width={[0.3, 0.2, 0.2, 0.35]}>
              <TextField name="banco" label={tx('bank')} />
              <TextField
                name="agencia"
                label={tx('agency')}
                metadata={{
                  rules: { required: true, maxlength: 6, mask: { pattern: 'agency' } },
                }}
              />
              <TextField name="numero" label={tx('account')} />
            </Row>
          </div>
        </FieldSet>

        {!isIntern && (
          <Row width={[0.3, 0.8]} align="center">
            <RadioGroup
              name="vinculoEmpregaticio"
              label={tx('vinculo')}
              metadata={{
                options: [
                  { value: 1, text: t('components.dialog.confirm.yes') },
                  { value: 0, text: t('components.dialog.confirm.no') },
                ],
              }}
              columns={2}
            />
            <LoopProtect />
          </Row>
        )}

        <Spacer length={10} />
        <FieldSet name="localAtividades">
          <div className={styles.container}>
            <h1>{tx('placeActivities')}</h1>
            <Row width={[0.3, 0.2, 0.25, 0.35]}>
              <TextField name="instituicao" label={tx('institution')} />
              <TextField name="orgao" label={tx('orgao')} />
              <TextField name="local" label={tx('place')} />
            </Row>
            <FieldSet name="endereco">
              <Row width={[0.25, 0.15, 0.25, 0.35]}>
                <TextField name="cep" label={tx('code')} />
                <Select name="uf" label={tx('state')} />
                <Select name="cidade" label={tx('city')} />
              </Row>
              <Row width={[0.3, 0.1, 0.2, 0.2, 0.3]}>
                <TextField name="logradouro" label={tx('street')} />
                <TextField name="numero" label={tx('number')} />
                <TextField name="complemento" label={tx('complement')} />
                <TextField name="bairro" label={tx('district')} />
              </Row>
              <Localidades
                city="cidade"
                prefix="contrato.localAtividades.endereco"
                state="uf"
              />
              <CEP
                cep="cep"
                uf="uf"
                logradouro="logradouro"
                bairro="bairro"
                cidade="cidade"
                prefix="contrato.localAtividades.endereco"
              />
            </FieldSet>
          </div>
        </FieldSet>
        <div className={styles.container}>
          <h1>{isIntern ? tx('internData') : tx('scholarshipData')}</h1>
          <Row width={[1]}>
            <TextArea
              name="resumoAtividades"
              label={tx('resumoAtividades')}
              placeholder={tx('resumoAtividades-placeholder')}
              minRows={8}
              metadata={{
                rules: {
                  required: true,
                },
              }}
            />
          </Row>
          <Row width={[0.2, 0.2, 0.15, 0.4]}>
            <DatePicker
              name="dataInicio"
              label={isIntern ? tx('internStart') : tx('scholarshipStart')}
            />
            <TextField
              name="cargaHoraria"
              label={tx('monthlyWorkload')}
              placeholder="00"
              adornment={{ right: tx('hourspermonth') }}
            />
            <TextField
              name="duracao"
              label={tx('durationMonths')}
              placeholder="00"
              adornment={{ right: tx('months') }}
            />
          </Row>
          <Spacer length={30} />
        </div>
      </FieldSet>
    </Grid>
  );
};

export default RequestForm;
