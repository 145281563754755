import { Route, Switch, useRouteMatch } from 'react-router-dom';
import {
  Dashboard,
  ConciliationSourceList,
  ImportReviewAndAlerts,
  MotherAccountsList,
  MotherAccountView,
  ConciliationExpensesList,
  ConciliationExpenseDetail,
  ProjectList,
} from 'pages/finance';
import RouteData from 'pages/utils/RouteData';
import ConciliationExpensesReview from 'pages/finance/ConciliationExpensesReview/ConciliationExpensesReview';
import ProjectDetail from 'pages/finance/Accountability/ProjectDetail/ProjectDetail';
import AccountabilityForm from 'pages/finance/Accountability/AccountabilityForm/AccountabilityForm';

const Financeiro = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/dashboard`}>
        <Dashboard />
      </Route>
      <Route path={`${path}/revisao`}>
        <ImportReviewAndAlerts />
      </Route>
      <Route exact path={`${path}/conciliacao/:source/:id`}>
        <ConciliationExpensesList />
      </Route>

      <Route path={`${path}/conciliacao/:source/:id/revisao`}>
        <ConciliationExpensesReview />
      </Route>

      <Route path={`${path}/conciliacao/:source/:id/lancamento/:lancamento_id`}>
        <ConciliationExpenseDetail />
      </Route>

      <Route path={`${path}/conciliacao`}>
        <ConciliationSourceList />
      </Route>

      <Route exact path={`${path}/contas-mae`}>
        <MotherAccountsList />
      </Route>
      <Route exact path={`${path}/contas-mae/:id`}>
        <RouteData>{props => <MotherAccountView {...props} />}</RouteData>
      </Route>

      <Route exact path={`${path}/prestacao-contas`}>
        <ProjectList />
      </Route>
      <Route exact path={`${path}/prestacao-contas/:id`}>
        <ProjectDetail />
      </Route>
      <Route exact path={`${path}/prestacao-contas/:id/nova-prestacao`}>
        <AccountabilityForm />
      </Route>
    </Switch>
  );
};
export default Financeiro;
