import React from 'react';

import { Grid, Row } from 'components/containers';
import { WrapperCard } from 'components/elements';
import {
  Autocomplete,
  DatePicker,
  Form,
  MonthYearPicker,
  Select,
  TextField,
} from 'components/form';
import { FormInstance } from 'components/form/types';
import { useTranslationX } from 'i18n';

import styles from './RHExpenseForm.module.scss';

type Props = {
  onDone: VoidFunction;
  formRef: React.RefObject<FormInstance>;
  id: number;
};

const RHExpenseForm = ({ formRef, onDone, id }: Props) => {
  const { tx } = useTranslationX('expenses', 'finance');

  const Content = () => {
    return (
      <Grid spacing={0}>
        <Row>
          <Autocomplete
            url={`/financeiro/conciliacao/despesas/contas-projeto/${id}/beneficiarios`}
            name="projetoRh"
            placeholder={tx('searchBeneficiary')}
            fetchDetail={false}
            label={tx('beneficiary')}
            formatOption={item => item.text}
            minCharacters={0}
          />
        </Row>
        <Row>
          <MonthYearPicker
            style={{
              root: {
                fontSize: 12,
              },
            }}
            label={tx('competence')}
            name="competencia"
            theme="classic"
          />
          <Select name="tipo" label={tx('type')} />
          <Select
            name="identificacaoDespesaConciliacao"
            label={tx('identificationField')}
          />
        </Row>

        <Row width={[0.5, 0.5]}>
          <TextField name="credor" label={tx('creditor')} />
        </Row>
        <Row width={[0.5, 0.5]}>
          <TextField name="cnpj" label={tx('cnpj')} />
        </Row>
        <div className={styles.container}>
          <h1>{tx('invoice')}</h1>
          <Row>
            <TextField name="numeroNotaFiscal" label={tx('invoiceNumber')} />
          </Row>
          <Row width={[0.5, 0.5]}>
            <DatePicker name="dataNotaFiscal" label={tx('invoiceDate')} />
            <TextField name="valor" label={tx('value')} />
          </Row>
        </div>
      </Grid>
    );
  };

  return (
    <>
      <WrapperCard maxWidth="100%" padding="30px 32px">
        <Form
          baseUrl={`/financeiro/conciliacao/despesas/contas-projeto/${id}/despesa-rh`}
          ref={formRef}
          onDone={onDone}
          submit={{
            method: 'post',
          }}
        >
          <Content />
        </Form>
      </WrapperCard>
    </>
  );
};

export default RHExpenseForm;
