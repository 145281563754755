import { useTranslationX } from 'i18n';

import { Card, Grid, Row, ScrollPanel, SuccessDialog } from 'components/containers';

import {
  Checkbox,
  Form,
  MonthYearPicker,
  RadioGroup,
  SubmitButton,
  useFormObserver,
} from 'components/form';
import { Button } from 'components/elements';
import { useHistory, useParams } from 'react-router-dom';
import { RouteParams } from 'pages/finance/ConciliationSourceList/types';

import { useVisible } from 'components/hooks';

import styles from './AccountabilityForm.module.scss';
import { ResponseExtras, useRequest } from 'apis';
import { downloadBlob } from 'utils/file';
import { useRef } from 'react';

const AccountabilityForm: React.FC = () => {
  const { tx } = useTranslationX('accountability.form', 'finance');

  const { id } = useParams<RouteParams>();

  const { visible, setVisible } = useVisible(false);

  const createdId = useRef<number>();

  const request = useRequest();

  const history = useHistory();

  const handleShowSuccess = (id: number) => {
    setVisible(true);
    createdId.current = id;
  };

  const Content = () => {
    const observer = useFormObserver('periodoCompleto', true);

    const periodoCompleto = observer?.data?.value ?? false;

    return (
      <>
        <Row width={[0.3, 0.7]}>
          <RadioGroup name="formato" label={tx('format')} columns={2} />
        </Row>
        <Row width={[0.3, 0.3, 0.4]} align="center">
          <MonthYearPicker
            name="dataInicio"
            label={tx('startDate')}
            theme="classic"
            type={periodoCompleto ? 'disabled' : undefined}
            omit={periodoCompleto}
            metadata={{
              rules: {
                required: true,
              },
            }}
          />
          <MonthYearPicker
            name="dataFim"
            label={tx('endDate')}
            theme="classic"
            type={periodoCompleto ? 'disabled' : undefined}
            omit={periodoCompleto}
            metadata={{
              rules: {
                required: true,
              },
            }}
          />
          <Checkbox name="periodoCompleto" label={tx('completePeriod')} omit />
        </Row>

        <div className={styles.documents}>
          <label>{tx('documents')}</label>

          <Checkbox
            name="arquivoRelacaoPagamentos"
            label={tx('paymentRelation')}
            transform="uppercase"
          />
          <Checkbox
            name="arquivoConciliacaoBancaria"
            label={tx('conciliation')}
            transform="uppercase"
          />
          <Checkbox
            name="arquivoDemContrapartidaEc"
            label={tx('demonstration')}
            transform="uppercase"
          />
          <Checkbox
            name="arquivoRelacaoPessoal"
            label={tx('personalRelation')}
            transform="uppercase"
          />
        </div>
        <div>
          <h3>{tx('invoiceDateOptions')}</h3>
          <Row width={[0.6, 0.4]}>
            <RadioGroup name="despesasRh" label={tx('rhExpense')} columns={2} />
          </Row>
          <Row width={[0.6, 0.4]}>
            <RadioGroup name="despesasDr" label={tx('drExpense')} columns={2} />
          </Row>
          <div
            style={{
              marginTop: 12,
            }}
          >
            <Checkbox
              name="semDataNf"
              label={tx('notInvoiceDate')}
              transform="uppercase"
            />
          </div>
        </div>
        <div>
          <h3>{tx('invoiceNumberOptions')}</h3>
          <div className={styles.documents}>
            <label>{tx('rhExpenseWithoutNumber')}</label>
            <Checkbox name="preencherMesCompetencia" label={tx('fillCompetenceMonth')} />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          <Button type="secondary" onClick={() => history.goBack()}>
            {tx('cancel')}
          </Button>
          <SubmitButton>{tx('submit')}</SubmitButton>
        </div>
      </>
    );
  };

  const getDocuments = () => {
    const accountabilityId = createdId.current;
    request<any>({
      url: `/financeiro/prestacao-contas/${accountabilityId}/arquivos`,

      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
      onSuccess: (blob: Blob, extras?: ResponseExtras) => {
        downloadBlob(blob, 'documentos.zip', extras);
      },
      onError: data => console.error(data),
    });
  };

  return (
    <ScrollPanel>
      <Form
        baseUrl={`/financeiro/prestacao-contas/${id}`}
        onDone={({ responseBody }) => {
          handleShowSuccess(responseBody.id as number);
        }}
      >
        <Card width={{ max: '50%', min: 600 }}>
          <div className={styles.card}>
            <h2>{tx('title')}</h2>
            <Grid gap={36}>
              <Content />
            </Grid>
          </div>
        </Card>
      </Form>
      <SuccessDialog
        visible={visible}
        title={tx('success')}
        buttonLabel={tx('downloadButton')}
        onClose={() => history.goBack()}
        onClick={getDocuments}
      />
    </ScrollPanel>
  );
};

export default AccountabilityForm;
