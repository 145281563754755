import React from 'react';

const Restore = ({
  size = 1,
  color = 'currentColor',
}: {
  size?: number;
  color?: string;
}) => (
  <svg
    width={`${size}em`}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_14046_6767"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x="1"
      y="2"
      width="22"
      height="20"
    >
      <path
        fill-rule="evenodd"
        clipRule="evenodd"
        d="M4.14407 12.0015C4.14407 6.95154 8.31407 2.86154 13.4041 3.00154C18.0941 3.13154 22.0141 7.05154 22.1441 11.7415C22.2841 16.8315 18.1941 21.0015 13.1441 21.0015C11.5341 21.0015 10.0341 20.5715 8.72407 19.8415C8.15407 19.5215 8.06407 18.7315 8.52407 18.2715C8.83407 17.9515 9.31407 17.8715 9.70407 18.0915C10.7241 18.6615 11.8941 19.0015 13.1441 19.0015C17.0441 19.0015 20.1941 15.8115 20.1441 11.9015C20.0941 8.18154 16.9641 5.05154 13.2441 5.00154C9.32407 4.95154 6.14407 8.10154 6.14407 12.0015H7.93407C8.38407 12.0015 8.60407 12.5415 8.29407 12.8515L5.50407 15.6415C5.30407 15.8415 4.99407 15.8415 4.79407 15.6415L2.00407 12.8515C1.68407 12.5415 1.90407 12.0015 2.35407 12.0015H4.14407ZM13.1441 10.0015C14.2441 10.0015 15.1441 10.9015 15.1441 12.0015C15.1441 13.1015 14.2441 14.0015 13.1441 14.0015C12.0441 14.0015 11.1441 13.1015 11.1441 12.0015C11.1441 10.9015 12.0441 10.0015 13.1441 10.0015Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_14046_6767)">
      <rect width="24" height="24" fill="currentColor" />
    </g>
  </svg>
);

export default Restore;
