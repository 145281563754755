import React, { createRef, useCallback, useMemo, useRef, useState } from 'react';
import { Dialog } from 'components/containers';
import { Button } from 'components/elements';
import { useTranslation, useTranslationX } from 'i18n';
import { currencyDefault } from 'components/form/helpers';
import {
  renderColorizedStatus,
  renderColorizedValue,
  statusOptions,
} from 'pages/finance/utils';
import { BalanceStatus } from 'pages/finance/types';
import { ColumnConfig } from 'components/containers/TableList/types';

import { messageService } from 'services';

import { FormInstance } from 'components/form/types';
import { Instance } from 'components/containers/TableList/TableList';
import { useTransactionActions } from '../useTransactionActions';
import TransactionList from '../TransactionList';
import DRExpenseForm from './DRExpenseForm/DRExpenseForm';
import FinishSolicitationModal from './FinishSolicitationModal/FinishSolicitationModal';
import FillSolicitationModal from './FillSolicitationModal/FillSolicitationModal';

type Props = {
  onReload: () => void;
  lancamento_id: number;
  id: number;
};

type Item = {
  id: number;

  item: string;
  tipo: string;

  situacao: BalanceStatus;
  tem_vinculo: boolean;
  finalizado: boolean;
  eh_solicitacao: boolean;
  valor: number;
  valor_conciliado: number;
  saldo_a_conciliar: number;
};

const DRDebitList: React.FC<Props> = props => {
  const { onReload, id, lancamento_id } = props;

  const { tx } = useTranslationX('expenses', 'finance');
  const { t } = useTranslation('translation');

  const table = useRef<Instance>();
  const formRef = createRef<FormInstance>();
  const createAccountFormRef = createRef<any>();
  const [showForm, setShowForm] = useState(false);

  const handleRefresh = useCallback(() => {
    onReload();
    table.current?.resetAfterDelete();
  }, [onReload]);

  const closeForm = () => {
    setShowForm(false);
  };
  const openForm = () => {
    setShowForm(true);
  };

  const onSubmitNewDRExpenses = () => {
    messageService.success(tx('DRExpenseSuccess'), { duration: 2000 });
    closeForm();
    handleRefresh();
  };

  const transactionActions = useTransactionActions<Item>({
    onReload: handleRefresh,
    id: lancamento_id,
    tipo: 'despesas-dr',
  });
  const getActions = useCallback(
    (data: Item, index: number) => transactionActions.getActions(data, index, formRef),
    [transactionActions, formRef]
  );

  const config: ColumnConfig<any> = [
    {
      title: tx('rubric'),
      field: 'tipo',
      align: 'center',
      weight: 0.07,
      search: true,
      parse: value => tx(`rubrics.${value}`),
    },
    {
      title: tx('item'),
      field: 'item',
      weight: 0.15,
      align: 'left',
      search: true,
      type: 'html',
      parse: value => `<strong>${value}<strong/>`,
      appendDots: false,
    },
    {
      title: tx('solicitation'),
      field: 'solicitacao',
      weight: 0.08,
      headerAlign: 'left',
      align: 'center',
      type: 'date',
      searchType: 'date',
      search: true,
    },
    {
      title: tx('creditor'),
      field: 'credor',
      weight: 0.11,
      headerAlign: 'left',
      align: 'center',
      search: true,
    },
    {
      title: tx('invoice'),
      field: 'nota_fiscal',
      weight: 0.09,
      headerAlign: 'left',
      align: 'center',
      search: true,
    },
    {
      title: tx('cnpjCpf'),
      field: 'cnpj_cpf',
      weight: 0.09,
      headerAlign: 'left',
      align: 'center',
      search: true,
    },
    {
      title: tx('situation'),
      field: 'situacao',
      weight: 0.11,
      type: 'html',
      search: true,
      searchType: 'status',
      metadata: {
        options: statusOptions(tx),
      },
      parse: renderColorizedStatus,
    },
    {
      title: tx('value'),
      field: 'valor',
      type: 'html',
      headerAlign: 'right',
      align: 'right',
      weight: 0.08,
      search: true,
      searchType: 'range',
      parse: (value: number, data: Item) => renderColorizedValue(value, data.situacao, t),
    },
    {
      title: tx('pendingBalance'),
      field: 'saldo_a_conciliar',
      search: true,
      weight: 0.1,
      appendDots: false,
      align: 'right',
      headerAlign: 'right',
      searchType: 'range',
      type: 'html',
      parse: (value: number, data: Item) => renderColorizedValue(value, data.situacao, t),
    },
    {
      title: tx('conciliatedValue'),
      field: 'valor_conciliado',
      search: true,
      weight: 0.1,
      type: 'input',
      appendDots: false,
      align: 'right',
      headerAlign: 'right',
      searchType: 'range',
      fieldProps: (item: Item) => {
        return {
          ...currencyDefault(
            Number(item.saldo_a_conciliar + item.valor_conciliado).toFixed(2)
          ),
          type:
            (item.situacao === 'CCI' && !item.tem_vinculo) || item.finalizado
              ? 'readonly'
              : undefined,
        };
      },
    },
    {
      title: '',
      field: 'actions',
      weight: 0.06,
      type: 'actions',
      search: false,
      parse: (_, data: Item, index: number) => getActions(data, index),
    },
  ];

  const extraButtons = useMemo(() => {
    return [
      {
        label: tx('button.newDRExpense'),
        type: 'green',
        action: openForm,
      },
    ];
  }, [tx]);

  return (
    <>
      <TransactionList
        lancamento_id={lancamento_id}
        onRefresh={handleRefresh}
        formRef={formRef}
        tableRef={table}
        config={config}
        baseUrl={`/financeiro/conciliacao/despesas/lancamentos/${lancamento_id}/debitos?tipo=DR`}
        listHeaderTitle={tx('expenses')}
        extraButtons={extraButtons}
        tipo="despesas-dr"
      />
      {transactionActions.selectedRow ? (
        <>
          <FinishSolicitationModal
            lancamentoId={lancamento_id}
            onRefresh={handleRefresh}
            uc={transactionActions.selectedRow}
            visible={transactionActions.modal === 'finishSolicitation'}
            onClose={transactionActions.handleCloseModal}
          />
          <FillSolicitationModal
            lancamentoId={lancamento_id}
            onRefresh={handleRefresh}
            uc={transactionActions.selectedRow}
            visible={transactionActions.modal === 'fillSolicitation'}
            onClose={transactionActions.handleCloseModal}
          />
        </>
      ) : null}
      <Dialog
        title={tx('newDRExpense')}
        visible={showForm}
        onClose={closeForm}
        closeButton
        width={640}
        actions={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="secondary" onClick={closeForm}>
              {tx('cancel')}
            </Button>
            <Button onClick={() => createAccountFormRef.current?.context.submit()}>
              {tx('save')}
            </Button>
          </div>
        }
      >
        <DRExpenseForm
          id={id}
          formRef={createAccountFormRef}
          onDone={onSubmitNewDRExpenses}
        />
      </Dialog>
    </>
  );
};

export default DRDebitList;
