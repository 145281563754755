import React, { ReactNode, forwardRef, useEffect, useRef, useState } from 'react';
import Tippy from '@tippyjs/react/headless';

import { useTranslation } from 'i18n';
import { InputProps, Value } from '../types';
import { readAttr } from 'utils/object';
import { FieldOption, Pageable } from 'components/form/types';
import { call, splitValueUnit } from 'utils';
import { useRequest } from 'apis';
import { X } from 'components/icons';
import { Loader, NotFoundMessage } from 'components/elements';

import { renderComponent } from '../../../utils/components';
import { Method } from 'axios';
import useDebounce from 'components/hooks/useDebounce';

import styles from './Autocomplete.module.scss';

export type Props = {
  align?: 'left' | 'center' | 'right';
  adornment?: string | { left?: React.ElementType; right?: React.ElementType };
  placeholder?: string;
  icon?: React.ElementType;
  url: string;
  method?: Method;
  formatOption?(arg0: any): string;
  onSelectOption?: (option: any) => void;
  pageable?: boolean;
  fieldQuery?: string | string[];
  preFilter?: object;
  fetchDetail?: boolean;
  minCharacters?: number;
};

const Autocomplete = forwardRef<HTMLDivElement, InputProps & Props>((props, ref) => {
  const {
    value,
    metadata,
    control,
    inputProps,
    align = 'left',
    adornment,
    placeholder,
    url,
    method,
    formatOption,
    pageable = false,
    fieldQuery = 'nome',
    preFilter,
    minCharacters = 3,
    fetchDetail = true,
    onSelectOption,
  } = props;
  const request = useRequest();

  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [selected, setSelected] = useState(value);

  const debounce = useDebounce(search, 500);

  const active = !loading && Array.isArray(options);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const optionRef = useRef<HTMLLIElement>(null);
  const anchorRef = useRef<HTMLDivElement>();
  const popupRef = useRef<HTMLDivElement>(null);

  const { onChange, onBlur, onFocus, ...rest } = inputProps;
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      const { current: wrap } = wrapperRef;
      if (wrap && !wrap.contains(e.target)) {
        setExpanded(false);
      }
    };

    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => setSelected(value), [value]);

  useEffect(() => {
    onChange(selected);

    if (optionRef.current) {
      optionRef.current.focus();
    }
  }, [selected, onChange]);

  useEffect(() => {
    if (debounce.length < minCharacters) return;
    setLoading(true);
    const requestBody = Array.isArray(fieldQuery)
      ? fieldQuery.reduce((a, v) => ({ ...a, [v]: debounce }), {})
      : { [fieldQuery]: debounce };

    const fields = { ...requestBody, ...preFilter };
    const filter = request<Pageable<any>>({
      url: url,
      params: !method ? fields : undefined,
      method,
      data: method ? fields : undefined,
      onSuccess: data => {
        if (pageable) {
          setOptions(data.content);
        } else {
          if (Array.isArray(data)) {
            setOptions(data);
          }
        }

        setLoading(false);
      },
    });

    return () => filter.cancel();
  }, [debounce, request, url, method, pageable, fieldQuery, preFilter, minCharacters]);

  useEffect(() => {
    if (
      selected !== undefined &&
      Array.isArray(options) &&
      options.length &&
      !getOption(selected, options)
    ) {
      setSelected(undefined);
    }
  }, [options, selected]);

  useEffect(() => {
    if (selected && fetchDetail) {
      const filter = request<any>({
        url: `${url}/${selected}`,
        onSuccess: data => {
          const { sigla, nome } = data;
          let optionLabel = sigla ? sigla + ' - ' + nome : nome;
          if (formatOption) {
            optionLabel = formatOption(data);
          }
          setSearch(optionLabel);
        },
      });

      return () => filter.cancel();
    }
  }, [selected, formatOption, request, url, fetchDetail]);

  function collapse() {
    anchorRef.current?.focus();
    setExpanded(false);
  }

  function handleFocus(event: React.FocusEvent) {
    if (anchorRef.current?.contains(event.target) && !expanded) {
      call(event.type === 'blur' ? onBlur : onFocus);
    }
  }

  const handlers = active
    ? {
        onClick: () => setExpanded(expanded => !expanded),
        onBlur: handleFocus,
        onFocus: handleFocus,
      }
    : {};

  function renderPopupContent(props: object) {
    const { width } = control.ref.current.getBoundingClientRect();
    const { value: border } = splitValueUnit(styles.borderWidth);

    return (
      <div
        ref={popupRef}
        className={styles.popup}
        {...props}
        style={{ width: width - border * 2, marginLeft: border }}
        tabIndex={0}
      >
        {loading ? (
          <div style={{ padding: 10 }}>
            <Loader size={26} type="spin" color={control.status} />
          </div>
        ) : Array.isArray(options) && options.length === 0 && selected === undefined ? (
          <NotFoundMessage />
        ) : (
          <ul>
            {Array.isArray(options)
              ? options.map(option => {
                  const { id, sigla, nome } = option;
                  let optionLabel = sigla ? sigla + ' - ' + nome : nome;
                  if (formatOption) {
                    optionLabel = formatOption(option);
                  }
                  return (
                    <li
                      key={id}
                      ref={id === selected ? optionRef : null}
                      className={id === selected ? styles.selected : ''}
                      onClick={() => {
                        collapse();
                        setSelected(id);
                        updateValue(optionLabel);
                        onSelectOption?.(option);
                      }}
                      tabIndex={0}
                    >
                      {optionLabel}
                    </li>
                  );
                })
              : null}
          </ul>
        )}
      </div>
    );
  }

  function renderPopup() {
    if (control.ref.current && Array.isArray(options) && search.length >= minCharacters) {
      return (
        <Tippy
          appendTo={() => document.body}
          placement="bottom-start"
          offset={[0, 7]}
          render={renderPopupContent}
          interactive={true}
          visible={expanded}
          reference={control.ref}
          onMount={() => {
            if (optionRef.current) {
              optionRef.current.focus();
            }
          }}
          onClickOutside={() => {
            setExpanded(false);
            call(onBlur);
          }}
          popperOptions={{
            modifiers: [
              {
                name: 'preventOverflow',
                enabled: false,
              },
            ],
          }}
          aria={{ content: null, expanded: false }}
        />
      );
    }
  }

  function attachRef(el: HTMLDivElement) {
    anchorRef.current = el;

    if (typeof ref === 'function') {
      ref(el);
    } else if (ref) {
      ref.current = el;
    }
  }

  const updateValue = (value: string) => {
    setSearch(value);
    setExpanded(false);
  };

  function adornmentLeft() {
    let val: ReactNode;

    if (adornment) {
      if (typeof adornment === 'string') {
        val = adornment;
      } else if (adornment.left) {
        val = adornment.left as ReactNode;
      }
    }

    return val ? <span className={styles.adornment}>{val}</span> : null;
  }

  function adornmentRight() {
    return typeof adornment === 'object' && adornment.right ? (
      <span className={styles.adornment}>{renderComponent(adornment.right)}</span>
    ) : null;
  }

  function handleClear() {
    setOptions([]);
    setSearch('');
    setSelected('');
  }

  return (
    <div
      className={styles.autocompleteInput}
      style={active ? undefined : { pointerEvents: 'none' }}
    >
      <div className={styles.autocompleteInput} ref={attachRef}>
        {adornmentLeft()}
        <input
          {...rest}
          placeholder={placeholder || t('components.autocomplete.placeholder')}
          className={styles.input}
          type="text"
          autoComplete="off"
          value={search}
          maxLength={readAttr('rules.maxlength', metadata)}
          style={{ textAlign: align }}
          onClick={() => setExpanded(!expanded)}
          onChange={event => setSearch(event.target.value)}
          {...handlers}
        />
        {adornmentRight()}
        <div className={styles.controls}>
          {selected ? (
            <div onClick={handleClear}>
              <X />
            </div>
          ) : null}
        </div>
      </div>
      {renderPopup()}
    </div>
  );
});

Autocomplete.displayName = 'Autocomplete';
export default Autocomplete;

function getOption(
  value: Value,
  options: any[],
  offset: number = 0
): FieldOption | undefined {
  if (value !== undefined) {
    if (Array.isArray(options) && options.length) {
      for (let i = 0; i < options.length; ++i) {
        if (options[i].value === value || options[i].id === value) {
          return options[Math.max(Math.min(i + offset, options.length - 1), 0)];
        }
      }

      return offset ? options[offset < 0 ? options.length - 1 : 0] : undefined;
    }
  } else if (offset && Array.isArray(options) && options.length) {
    return options[offset < 0 ? options.length - 1 : 0];
  }
}
