import React, { createRef, useEffect, useRef, useState } from 'react';

import { Dialog, TableList } from 'components/containers';
import { useTranslationX } from 'i18n';
import { ColumnConfig } from 'components/containers/TableList/types';
import { Instance, ListType } from 'components/containers/TableList/TableList';
import { Button } from 'components/elements';

import MotherAccountsForm from '../MotherAccountsForm/MotherAccountsForm';
import { FormInstance } from 'components/form/types';
import { messageService } from 'services';
import { Archive, Pencil, Unarchive } from 'components/icons';
import { useRequest } from 'apis';

import { useHistory, useLocation } from 'react-router-dom';
import { useDynamicHeader } from 'components/containers/DynamicHeader';

import styles from './MotherAccountsList.module.scss';

const status: { [key: string]: string } = {
  ARQ: '#828f8f',
  ATV: '#44CF5C',
};

export type MotherAccount = {
  id: number;
  nomeContaMae?: string;
  banco?: string;
  agencia?: string;
  numero?: string;
  fonteContaMae?: string;
  status: 'ARQ' | 'ATV';
  projectAccountIds?: string[];
  contas: number[];
};

const MotherAccountsList = () => {
  const { tx } = useTranslationX('accounts', 'finance');
  const history = useHistory();
  const location = useLocation();
  const table = useRef<Instance>();

  const request = useRequest();

  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState<MotherAccount>();
  const createAccountFormRef = createRef<FormInstance>();

  let config: ColumnConfig<ListType> = [
    {
      title: tx('name'),
      field: 'nomeContaMae',
      align: 'left',
      width: 0,
      search: true,
    },
    {
      title: tx('bank'),
      field: 'banco',
      weight: 0.8,
      search: true,
    },
    {
      title: tx('agency'),
      field: 'agencia',
      weight: 0.8,
      search: true,
    },
    {
      title: tx('account'),
      field: 'numero',
      weight: 0.8,
      search: true,
    },
    {
      title: tx('status'),
      field: 'status',
      type: 'tag',
      weight: 0.8,
      search: true,
      align: 'center',
      metadata: {
        options: Object.keys(status).map(key => {
          return {
            value: key,
            text: tx(key),
          };
        }),
      },
      parse: value => {
        return {
          value: tx(value),
          bgColor: status[value],
        };
      },
      searchType: 'status',
    },
    {
      title: tx('source'),
      field: 'fonteContaMae',
      weight: 0.8,
      align: 'center',
      search: true,
    },
    {
      title: tx('actions.title'),
      weight: 0.6,
      align: 'left',
      field: 'actions',
      type: 'actions',
      sort: false,
      parse(_, data) {
        const isArchived = data.status !== 'ATV';
        return [
          {
            icon: !isArchived ? Archive : Unarchive,
            color: '#8A92A6',
            identifier: data.status,
            label: isArchived ? tx(`actions.ATV`) : tx(`actions.ARQ`),
            onClick: () => changeAccountStatus(data),
          },
          {
            icon: Pencil,
            color: '#8A92A6',
            identifier: 'edit',
            label: tx('actions.edit'),
            onClick: () => handleEditAccount(data),
          },
        ];
      },
    },
  ];

  const handleEditAccount = (account: MotherAccount) => {
    setShowModal(true);
    setCurrentItem(account);
  };

  const changeAccountStatus = (account: MotherAccount) => {
    const source = request({
      url: `financeiro/contas-mae/${account.id}/arquivar`,
      method: 'PATCH',
      onSuccess: () => {
        messageService.success(
          tx('updateStatusSuccess', {
            value: tx(`success-${account.status === 'ARQ' ? 'ATV' : 'ARQ'}`),
          }),
          {
            duration: 2000,
          }
        );
        table.current?.resetAfterDelete();
      },
      onError: () =>
        messageService.error('updateStatusError', {
          duration: 2000,
        }),
    });

    return () => source.cancel();
  };

  function openModal() {
    setShowModal(true);
  }

  function hideModal() {
    setShowModal(false);
    setCurrentItem(undefined);
  }

  const { setBreadcrumbs, setFooter, setVisibility, clear } = useDynamicHeader();

  useEffect(() => {
    setBreadcrumbs(['finance']);
    setFooter(tx('motherAccounts'));

    setVisibility(true);
    return () => {
      clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear, setBreadcrumbs, setVisibility, setFooter]);

  return (
    <>
      <TableList<MotherAccount>
        defaultSearchProps={{
          sort: 'nomeContaMae,desc',
          filters: {},
        }}
        listHeader={{
          subtitle: tx('motherAccounts'),
          title: tx('motherAccountsList'),
        }}
        editIcon={null}
        insideHeader={
          <div className={styles.insideHeader}>
            <h2>{tx('motherAccounts')}</h2>
            <Button onClick={openModal}>{tx('addMotherAccount')}</Button>
          </div>
        }
        baseUrl="financeiro/contas-mae/list"
        filter
        config={config}
        textSize="small"
        theme="light"
        notFoundProps={{}}
        infinityScroll
        refreshesOn={95}
        pageSize={20}
        reference={table}
        editAction={data => history.push(`${location.pathname}/${data.id}`)}
        style={{
          root: {
            maxWidth: '100%',
            maxHeight: 'calc(100vh - 150px)',
          },
        }}
      />
      <Dialog
        title={tx(currentItem ? 'editMotherAccount' : 'addMotherAccount')}
        visible={showModal}
        onClose={hideModal}
        closeButton
        width={800}
        actions={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="secondary" onClick={hideModal}>
              {tx('cancel')}
            </Button>
            <Button onClick={() => createAccountFormRef.current?.context.submit()}>
              {tx(currentItem ? 'saveMotherAccount' : 'submitMotherAccount')}
            </Button>
          </div>
        }
      >
        <MotherAccountsForm
          type={currentItem ? 'update' : 'create'}
          populate={currentItem}
          formRef={createAccountFormRef}
          onDone={() => {
            hideModal();
            messageService.success(tx('accountCreated'), { duration: 2000 });
            table.current?.resetAfterDelete();
          }}
        />
      </Dialog>
    </>
  );
};

export default MotherAccountsList;
