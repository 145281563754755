export type ProjectStatus = 'SIG' | 'EXE' | 'FIN' | 'CAN';

export type PartData = {
  id: number;
  nome: string;
  ucs: RoleItem[] | CostUnitItem[];
};

export type MacroRole = {
  id: number;
  title: string;
  inicio1: number;
  inicio2: number;
  status: HiringStatus;
  value?: number;
};

type CBO = {
  id: number;
  codigo: string;
  titulo: string;
};

export type Pessoa = {
  id: number;
  rg: string;
  nome: string;
  rgUf: string;
  genero: string;
  rgEmissor: string;
  localTrabalho?: string;
  vinculoEmpregaticio: boolean;
  nascimento: Date;
  cargoCbo: CBO;
  formacao: string;
  naturalidade: string;
  estadoCivil: string;
  carteiraProfissional: string;
  carteiraProfissionalSerie: string;
  tituloEleitor: string;
  tituloEleitorZona: string;
  tituloEleitorSecao: string;
  pis: string;
  pisDataCadastro: Date;
  dependentes: number;
  certificadoMilitarN: string;
  certificadoMilitarSerie: string;
  certificadoMilitarCategoria: string;
};

export type Banco = {
  banco: string;
  conta: string;
  agencia: string;
};

export type Atividades = {
  instituicao: string;
  orgao: string;
  local: string;
  cep: string;
  logradouro: string;
  numero: string;
  bairro: string;
  cidade: string;
  complemento: string;
};

export type Bolsa = {
  chMes: number;
  duracao: number;
  dataInicio: Date;
};

export type ProjetoRH = {
  id: number;
  perfil?: string;
  vinculoTrabalho: number;
  estado: string;
  termoEstagioEnviado: boolean;
  remuneracao: number;
  vinculo: 'CELETISTA' | 'BOLSISTA' | 'ESTAGIARIO';
  duracao: number;
  solicitacaoId: number;
};

export type Ferias = {
  hasVacation: boolean;
  isInVacation?: boolean;
  vacationSolicitationId?: number;
};

export type Prorrogacao = {
  isInExtension?: boolean;
  extensionSolicitationId?: number;
};

export type Transferencia = {
  isInTransfer?: boolean;
  transferSolicitationId?: number;
};

export type DesligamentoData = {
  dataDesligamento: Date;
  avisoPrevio: string;
  iniciativa: string;
  justificativa: string;
};

export type Desligamento = {
  isSupervisorAnalysing: boolean;
  isInTermination?: boolean;
  terminationSolicitationId?: number;
  isStudentAndNotResponded?: boolean;
  data?: DesligamentoData;
};

export type Contract = {
  id: number;
  projetoRHId: number;
  pessoa: Pessoa;
  banco: Banco;
  ferias: Ferias;
  atividades: Atividades;
  bolsa: Bolsa;
  prorrogacao: Prorrogacao;
  transferencia: Transferencia;
  desligamento: Desligamento;
  projetoRH: ProjetoRH;
};

export type DashboardData = {
  gastoPartes: Record<string, number>;
  version: number;
};

export type CounterpartVacation = {
  dataInicio: Date;
  dataFim: Date;
};

export type Counterpart = {
  id: number;
  pessoa_id: number;
  nome: string;
  dataNascimento: Date;
  genero: string;
  rg: string;
  cpf: string;
  rgEmissor: string;
  rgUf: string;
  mesInicio: number;
  mesFim: number;
  chMes: number;
  ferias: CounterpartVacation[];
  regime: number;
  remuneracao: number;
  estado: string;
  termo: boolean;
  editable?: boolean;
};

export type MilestoneContribution = {
  id: number;
  ordem: number;
  mesExecucao: number;
  blocked: boolean;
  partes: MilestoneContributionPart[];
};

export type RangedMilestone = {
  id: number;
  range: number[];
};

export type MilestoneContributionPart = {
  id: number;
  valor: number;
  partId: number;
  rateio: MilestoneContributionApportionment;
  partNome: string;
  ordem: number;
};

export type MilestoneContributionApportionment = {
  diarias: number;
  outras_despesas: number;
  passagens: number;
  recursos_humanos: number;
  servicos_terceiros: number;
  subtotal: number;
  suporte_operacional: number;
  total: number;
};

export type RequestItem = {
  id: number;
  tipo: string;
};

export type CostUnitItem = {
  descricao: string;
  mesExecucao: number;
  ordem: number;
  total: number;
  disponivel: number;
  analise: number;
  aprovado: number;
  executado: number;
  alteracao_exclusao: boolean;
} & RequestItem;

export type RoleItem = {
  cargo: string;
  vinculo: string;
  duracao: number;
  categoria: string;
  tipo: RoleType;
  remuneracao: number;
  alteracao_exclusao: boolean;
  prhId?: number;
  status: HiringStatus;
  ordem: number;
} & RequestItem;

export const hiringStatusColors = {
  AVA: '#3FC75C',
  ANS: '#7A13D6',
  ECT: '#7A13D6',
  CON: '#617cf2',
  ACC: '#617cf2',
  FER: '#ed5b00',
  FIN: '#fa0000',
  TER: '#ed5b00',
  TRA: '#7A13D6',
  EDT: '#7A13D6',
};

export const projectStatusColors: { [key in ProjectStatus]: string } = {
  CAN: '#cd191e',
  FIN: '#2f9e41',
  EXE: '#2196f3',
  SIG: '#7a7a7a',
};

export const projectCounterpartRequestTypesColors: {
  [key in ProjectCounterpartRequestTypes]: string;
} = {
  'project.counterpart.hiring': '#C98CFF',
  'project.counterpart.termination': '#cd191e',
  'project.counterpart.month': '#2196f3',
  'project.counterpart.update': '#FF9900',
};

export const projectRequestStatusColors: { [key in ProjectRequestStatus]: string } = {
  ANS: '#C98CFF',
  REF: '#cd191e',
  ACC: '#2196f3',
  REQ: '#7a7a7a',
  EXE: '#4a9300',
  CAN: '#cd191e',
  ANC: '#FF9900',
};

export const projectCounterpartStatusColors: {
  [key in ProjectCounterpartStatus]: string;
} = {
  PEN: '#C98CFF',
  SOL: '#4a9300',
  RQF: '#4a9300',
  CAD: '#2196f3',
  FIN: '#7a7a7a',
  REJ: '#cd191e',
};

export type HiringStatus =
  | 'AVA'
  | 'ANS'
  | 'ECT'
  | 'ACC'
  | 'CON'
  | 'FER'
  | 'FIN'
  | 'TER'
  | 'TRA'
  | 'EDT';
export type RoleType = 'CLT' | 'Bolsa' | 'Outros' | 'Estágio';
export type ProjectRequestStatus = 'ANS' | 'REF' | 'ACC' | 'REQ' | 'EXE' | 'CAN' | 'ANC';
export type ProjectCounterpartRequestTypes =
  | 'project.counterpart.hiring'
  | 'project.counterpart.termination'
  | 'project.counterpart.month'
  | 'project.counterpart.update';
export type ProjectCounterpartStatus = 'PEN' | 'SOL' | 'RQF' | 'REJ' | 'CAD' | 'FIN';
export type SolicitationFinality = 'servico' | 'compra' | 'diaria' | 'passagem' | 'rh';

export type Solicitation = {
  id: number;
  notification_type: string;
  projetoId: number;
  tipo: string;
  solicitacaoId?: number;
  fonte: string;
  gpf: string;
  data: string;
  estado: ProjectRequestStatus;
  numOficio: string;
  dataOficio: string;
  valor: string;
  projetoTitulo?: string;
  projetoSigla?: string;
  arquivoTermo?: number;
  motivoRejeicao?: string;
  vinculoContratacao?: string;
  vinculoContratacaoId?: number;
  termo_id: number;
  propostas: {
    nome: string;
    id: number;
  }[];
  aditivo?: boolean;
  titulo?: string;

  cbo: string;
  formacao: string;
  isCLT?: boolean;
  naturalidade: string;
  estadoCivil: string;
  carteiraProfissional: string;
  carteiraProfissionalSerie: string;
  tituloEleitor: string;
  tituloEleitorZona: string;
  tituloEleitorSecao: string;
  pis: string;
  pisDataCadastro: Date;
  dependentes: number;

  avaliacaoBolsista?: Avaliacao[];
  avaliacaoCoordenador?: Avaliacao[];
  docOutorgaEConcessaoAssinado?: number | null;
  docRg?: number | null;
  docComprovanteResidencia?: number | null;
  docDeclaracaoMatricula?: number | null;
  docDeclaracaoCiencia?: number | null;
  docHistoricoEscolar?: number | null;
  docComprovanteContaCorrente?: number | null;
  docDescricaoProcessoSeletivo?: number | null;
  docSolicitacaoDeContratacaoDeBolsa?: number | null;
  docOutorgaEConcessao?: number | null;
  docAutorizacaoPolo?: number | null;
  docTermoConfidencialidade?: number | null;
  docContracheque?: number | null;
  docDeclaracaoVencimentos?: number | null;
  docDeclaracaoPi?: number | null;
  docComprovanteTitulacaoFormacao?: number | null;

  docCurriculo: number | null;
  docCtps: number | null;
  docCpf: number | null;
  docReservista: number | null;
  docTituloEleitor: number | null;
  docFoto: number | null;
  docCertidaoCasamento: number | null;
  docFormularioContratacao: number | null;
};

type Avaliacao = {
  id: number;
  pergunta: string;
  resposta: string;
};

export const projectRequestTypesColors: {
  [key in ProjectRequestTypes]: string;
} = {
  aceiteMacro: '#3FC75C',
  servico: '#617cf2',
  compra: '#617cf2',
  diaria: '#617cf2',
  passagem: '#617cf2',
  rh: '#7A13D6',
  pagamento: '#7A13D6',
  transferencia: '#7A13D6',
  prorrogacao: '#7A13D6',
  desligamento: '#fa0000',
  ferias: '#7A13D6',
  contrapartida: '#ed5b00',
  contrapartida_update: '#ed5b00',
  contrapartida_mes: '#ed5b00',
  'alteracao-pt': '#fa0000',
};

export type ProjectRequestTypes =
  | 'aceiteMacro'
  | 'servico'
  | 'rh'
  | 'compra'
  | 'pagamento'
  | 'diaria'
  | 'passagem'
  | 'transferencia'
  | 'prorrogacao'
  | 'desligamento'
  | 'ferias'
  | 'contrapartida'
  | 'contrapartida_update'
  | 'contrapartida_mes'
  | 'alteracao-pt';
