import React from 'react';

import { Grid, Row } from 'components/containers';
import { WrapperCard } from 'components/elements';
import { Form, HiddenField, TextField } from 'components/form';
import { FormInstance } from 'components/form/types';
import { useTranslationX } from 'i18n';
import { useParams } from 'react-router-dom';
import { currencyDefaultNonZero } from 'components/form/helpers';

type Props = {
  onDone: VoidFunction;
  formRef: React.RefObject<FormInstance>;
};

const AporteForm = ({ formRef, onDone }: Props) => {
  const { id } = useParams<{ id: string }>();

  const { tx } = useTranslationX('accounts', 'finance');

  return (
    <>
      <WrapperCard maxWidth="100%" padding="30px 32px">
        <Form
          baseUrl={`/financeiro/contas-mae/${id}/solicitacao`}
          ref={formRef}
          onDone={onDone}
          submit={{
            method: 'post',
          }}
        >
          <Grid spacing={0}>
            <Row>
              <TextField name="valor" label={tx('value')} {...currencyDefaultNonZero()} />
            </Row>
          </Grid>
          <HiddenField name="tipo" value={'APO'} />
        </Form>
      </WrapperCard>
    </>
  );
};

export default AporteForm;
