import moment from 'moment';
import 'moment/locale/pt';

export function formatDateToBrazilian(date: Date, hours: boolean = false): string {
  return hours
    ? moment(date).format('DD/MMM/YYYY HH:mm')
    : moment(date).format('DD/MMM/YYYY');
}

export function isDatetime(string: string): boolean {
  // Padrão para data com horário no formato yyyy-mm-dd HH:MM:SS
  const datetimePattern = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
  return datetimePattern.test(string);
}

export function safeDate(date: Date | string | number): Date {
  if (isDatetime(String(date))) {
    return new Date(date);
  } else {
    return new Date(`${date}T00:00:00`);
  }
}

export function capitalize(str: string): string {
  if (!str) return str;
  return str.toLowerCase().replace(/\w\S*/g, txt => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function convertHexColorToRGBA(hex: string, alpha = 1): string {
  const [r, g, b] = hex.match(/\w\w/g)!.map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
}

export function pad(value: string, times: number, character: string): string {
  let s = value + '';
  while (s.length < times) {
    s = character + s;
  }
  return s;
}

export function formatIdentificationNumber(value: string) {
  return format(pad(value, 8, '0'), '####.####');
}

export function format(value: string, pattern: string) {
  let i = 0;
  const v = value.toString();
  return pattern.replace(/#/g, _ => v[i++]);
}

export function appendDots(value: string, maxLength: number): string {
  return value.length > maxLength ? `${value.substring(0, maxLength)}...` : value;
}

// Padding zero to a number
export function zeroPad(value: number, length: number): string {
  return pad(value.toString(), length, '0');
}

export function removeLeadingZeros(value: string): string {
  return value.replace(/^0+/, '') || '0';
}
export function arrayBufferToBase64(buffer: ArrayBuffer) {
  let binary = '';
  let bytes = new Uint8Array(buffer);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function downloadFileFromData(
  data?: string | ArrayBuffer | null,
  fileName = 'file'
) {
  if (typeof data === 'string' || data instanceof ArrayBuffer) {
    const a = document.createElement('a');
    let dataUrlBase64 = typeof data === 'string' ? data : arrayBufferToBase64(data);
    a.setAttribute('href', dataUrlBase64);
    a.setAttribute('download', `${fileName}.pdf`);
    a.click();
  }
}

export function stripTags(html: string) {
  return html.replace(/(<([^>]+)>)/gi, '');
}
