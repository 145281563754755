import React from 'react';

import { Grid, Row } from 'components/containers';
import { WrapperCard } from 'components/elements';
import {
  Autocomplete,
  DatePicker,
  Form,
  HiddenField,
  RadioGroup,
  Select,
  TextField,
  useForm,
  useFormFieldObserver,
} from 'components/form';
import { FormInstance } from 'components/form/types';
import { useTranslationX } from 'i18n';

import styles from './DRExpenseForm.module.scss';

type Props = {
  onDone: VoidFunction;
  formRef: React.RefObject<FormInstance>;
  id: number;
};

const DRExpenseForm = ({ formRef, onDone, id }: Props) => {
  const { tx } = useTranslationX('expenses', 'finance');

  const Content = () => {
    const tipoObserver = useFormFieldObserver('tipo');

    const form = useForm();

    const handleSelectOption = (option: any) => {
      const tipo = option.tipo;
      form.setFieldValue('projetoUcTipo', tipo);

      let customIdentification = undefined;

      if (tipo === 'suporte') {
        customIdentification = 14;
      } else if (tipo === 'outros') {
        customIdentification = 13;
      }

      form.setFieldValue('identificacaoDespesaConciliacao', customIdentification);

      if (tipo !== 'suporte') {
        let customType = option.fonteRecursoStatus;

        if (tipo === 'movimentacoes_extraordinarias') {
          customType = 3;
        }
        form.setFieldValue('tipo', customType);
      }
    };

    return (
      <Grid spacing={0}>
        <Row>
          <Autocomplete
            url={`/financeiro/conciliacao/despesas/contas-projeto/${id}/itens`}
            name="projetoUc"
            placeholder={tx('searchUCItem')}
            fetchDetail={false}
            label={tx('UCItem')}
            formatOption={item => {
              if (['suporte', 'movimentacoes_extraordinarias'].includes(item.tipo)) {
                return tx('rubrics.' + item.tipo);
              } else {
                return `${tx('rubrics.' + item.tipo)} - ${item.descricao}${tx(
                  'rubricsStatus.' + item.fonteRecursoStatus
                )}`;
              }
            }}
            minCharacters={0}
            onSelectOption={handleSelectOption}
          />
        </Row>
        <HiddenField name="projetoUcTipo" />
        <Row>
          <Select name="tipo" label={tx('type')} />
          <Select
            name="identificacaoDespesaConciliacao"
            label={tx('identificationField')}
          />
        </Row>

        <Row width={[0.5, 0.5]}>
          <TextField name="credor" label={tx('creditor')} />
        </Row>
        <Row width={[0.4, 0.6]} align="baseline">
          <TextField name="cnpjCpf" label={tx('cnpjCpf')} />
          {tipoObserver.value === 3 ? (
            <Row>
              <RadioGroup
                name="origem"
                label={tx('origin')}
                columns={2}
                metadata={{
                  rules: {
                    required: true,
                  },
                }}
              />
            </Row>
          ) : null}
        </Row>
        <div className={styles.container}>
          <h1>{tx('invoice')}</h1>
          <Row>
            <TextField
              name="descricaoNotaFiscal"
              label={tx('description')}
              metadata={{
                rules: {
                  required: tipoObserver.value === 3,
                },
              }}
            />
          </Row>
          <Row>
            <TextField name="numeroNotaFiscal" label={tx('invoiceNumber')} />
          </Row>
          <Row width={[0.5, 0.5]}>
            <DatePicker name="dataNotaFiscal" label={tx('invoiceDate')} />
            <TextField name="valor" label={tx('value')} />
          </Row>
        </div>
      </Grid>
    );
  };

  return (
    <>
      <WrapperCard maxWidth="100%" padding="30px 32px">
        <Form
          baseUrl={`/financeiro/conciliacao/despesas/contas-projeto/${id}/despesa-dr`}
          ref={formRef}
          onDone={onDone}
          submit={{
            method: 'post',
          }}
        >
          <Content />
        </Form>
      </WrapperCard>
    </>
  );
};

export default DRExpenseForm;
