import { useTranslationX } from 'i18n';
import { useEffect } from 'react';

import { useDynamicHeader } from 'components/containers/DynamicHeader';
import { useHistory, useLocation } from 'react-router-dom';
import { ProjectItem } from '../ProjectList/ProjectList';
import { TableList } from 'components/containers';
import { ColumnConfig } from 'components/containers/TableList/types';
import { ListType } from 'components/containers/TableList/TableList';
import {
  accountabilityDateOptions,
  accountabilityFormatOptions,
  accountabilityNumberOptions,
} from 'pages/finance/utils';
import { DownloadDocument } from 'components/icons';
import { ResponseExtras, useRequest } from 'apis';
import { downloadBlob } from 'utils/file';

const ProjectDetail = () => {
  const { tx } = useTranslationX('accountability.accountabilityList', 'finance');

  const { setBreadcrumbs, setFooter, setVisibility, clear } = useDynamicHeader();

  const { state } = useLocation<ProjectItem>();
  const history = useHistory();

  const request = useRequest();

  const { sigla, numero, id } = state;

  useEffect(() => {
    setBreadcrumbs(['finance', 'accountability']);
    setFooter(
      tx('identification', {
        sigla: sigla,
        number: numero,
      })
    );

    setVisibility(true);
    return () => {
      clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear, setBreadcrumbs, setVisibility, setFooter]);

  const getDocuments = (id: number) => {
    request<any>({
      url: `/financeiro/prestacao-contas/${id}/arquivos`,

      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
      onSuccess: (blob: Blob, extras?: ResponseExtras) => {
        downloadBlob(blob, 'documentos.zip', extras);
      },
      onError: data => console.error(data),
    });
  };

  const getActions = (item: any) => {
    return [
      {
        color: '#686868',
        icon: DownloadDocument,
        label: tx('actions.download'),
        onClick: () => getDocuments(item.id),
      },
    ];
  };

  const config: ColumnConfig<ListType> = [
    {
      weight: 0.1,
      title: tx('columns.createdDate'),
      field: 'data_criado',
      search: true,
      type: 'datetime',
      align: 'left',
      searchType: 'date',
    },
    {
      weight: 0.08,
      title: tx('columns.format'),
      field: 'formato',
      metadata: {
        options: accountabilityFormatOptions(),
      },
      searchType: 'status',
      search: true,
      parse: option => (option ? tx(`formatOption.${option}`) : '-'),
    },
    {
      weight: 0.1,
      title: tx('columns.startDate'),
      field: 'data_inicio',
      search: true,
      align: 'left',
      searchType: 'date',
    },
    {
      weight: 0.1,
      title: tx('columns.endDate'),
      field: 'data_fim',
      search: true,
      searchType: 'date',
    },
    {
      weight: 0.12,
      title: tx('columns.generatedDocuments'),
      field: 'documentos_gerados',
      search: true,
      appendDots: false,
      align: 'left',
    },
    {
      weight: 0.15,
      title: tx('columns.invoiceDateOption'),
      field: 'opcao_nota_fiscal_data',
      search: true,
      searchType: 'status',
      metadata: {
        options: accountabilityDateOptions(),
      },
      appendDots: false,
      align: 'left',
      parse: option => (option ? tx(`option.${option}`) : '-'),
    },
    {
      weight: 0.17,
      title: tx('columns.invoiceNumberOption'),
      field: 'opcao_nota_fiscal_numero',
      search: true,
      searchType: 'status',
      metadata: {
        options: accountabilityNumberOptions(),
      },
      appendDots: false,
      align: 'left',
      parse: option => (option ? tx(`option.${option}`) : '-'),
    },
    {
      title: tx('columns.actions'),
      field: 'actions',
      weight: 0.08,
      type: 'actions',
      search: false,
      parse: (_, item) => getActions(item),
    },
  ];

  return (
    <TableList<ProjectItem>
      baseUrl={`/financeiro/prestacao-contas/${id}/list`}
      listHeader={{
        position: 'inside',
        title: tx('subtitle', {
          number: numero,
        }),
        subtitle: tx('title', {
          number: numero,
        }),
      }}
      defaultSearchProps={{
        sort: 'data_criado,desc',
        filters: {},
      }}
      config={config}
      style={{
        root: {
          maxWidth: 'calc(100% - 20px)',
          maxHeight: 'calc(100vh - 150px)',
          gap: '1.5rem',
        },
      }}
      textSize="small"
      theme="light"
      keyComposer={(item, index) => `${item.id}-${index}`}
      editIcon={null}
      editAction={({ id }) => {
        if (id === undefined) {
          history.push(history.location.pathname + '/nova-prestacao');
        }
      }}
      refreshesOn={95}
      pageSize={20}
      notFoundProps={{
        title: tx('error.not_found.title'),
        description: tx('error.not_found.description'),
      }}
      filter
      infinityScroll
      addButton={{
        label: tx('add'),
        type: 'green',
      }}
    />
  );
};

export default ProjectDetail;
