import React from 'react';

import styles from './InfoCard.module.scss';
import { classes } from 'utils/components';

interface Props {
  items: {
    label: string;
    content: React.ReactNode;
    align?: 'left' | 'center' | 'right';
  }[];
}

const InfoCard: React.FC<Props> = ({ items }) => {
  return (
    <div className={classes(styles.infoCard)}>
      {items.map(item => (
        <div
          className={classes(styles.info, styles[item.align ?? 'left'])}
          key={item.label}
        >
          <span>{item.label}</span>
          <div className={styles.content}>{item.content}</div>
        </div>
      ))}
    </div>
  );
};

export default InfoCard;
