import { useRef, useCallback } from 'react';

type AsyncTask = () => Promise<void>;

export const useAsyncQueue = (handleRefresh: () => void) => {
  const queue = useRef<AsyncTask[]>([]);
  const processing = useRef<boolean>(false);

  const processQueue = useCallback(async () => {
    if (processing.current) return;
    processing.current = true;

    while (queue.current.length > 0) {
      const task = queue.current.shift();
      if (task) await task();
    }

    processing.current = false;
    handleRefresh(); // Só chama no final
  }, [handleRefresh]);

  const addToQueue = useCallback(
    (task: AsyncTask) => {
      queue.current.push(task);
      processQueue();
    },
    [processQueue]
  );

  return addToQueue;
};
