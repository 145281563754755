import React, { useMemo } from 'react';

import { useHistory } from 'react-router-dom';

import { useTranslationX } from 'i18n';
import { useRequest } from 'apis';

import TableList, { ListType } from 'components/containers/TableList/TableList';
import { ColumnConfig } from 'components/containers/TableList/types';
import { formatDateToBrazilian } from 'utils/stringUtils';
import { Button } from 'components/elements';
import { Pageable } from 'components/form/types';
import { messageService } from 'services';
import { ImportacaoExtrato } from './ImportReviewAndAlerts';

type Props = {
  variant: 'mother' | 'normal';
};

const ImportReview = ({ variant = 'normal' }: Props) => {
  const { tx } = useTranslationX('importReviewAndAlerts', 'finance');
  const history = useHistory();
  const request = useRequest();

  const configSuccess: ColumnConfig<ListType> = useMemo(() => {
    if (variant === 'normal') {
      return [
        {
          weight: 1.1,
          title: tx('fields.arquivo'),
          field: 'arquivo',
          align: 'left',
          search: true,
        },
        {
          weight: 0.8,
          title: tx('fields.projeto'),
          field: 'numeroProjeto',
          align: 'center',
          search: true,
        },
        {
          weight: 0.7,
          title: tx('fields.gpf'),
          field: 'gpf',
          align: 'center',
          search: true,
        },
        {
          weight: 0.7,
          title: tx('fields.fonte'),
          field: 'fonteRecurso',
          align: 'center',
          search: true,
        },
        {
          weight: 0.7,
          title: tx('fields.banco'),
          field: 'banco',
          align: 'center',
          search: true,
        },
        {
          weight: 0.7,
          title: tx('fields.agencia'),
          field: 'agencia',
          align: 'center',
          search: true,
        },
        {
          weight: 0.7,
          title: tx('fields.conta'),
          field: 'conta',
          align: 'center',
          search: true,
        },
        {
          weight: 0.7,
          title: tx('fields.periodo'),
          field: 'dataInicio',
          align: 'center',
          type: 'date',
          searchType: 'date',
          search: true,
          parse: (_, data: ImportacaoExtrato) =>
            `${formatDateToBrazilian(data.dataInicio, false)} a ${formatDateToBrazilian(
              data.dataFim,
              false
            )}`,
        },
        {
          weight: 0.7,
          title: tx('fields.lancamentos'),
          field: 'lancamentos',
          align: 'right',
          searchType: 'range',
          search: true,
        },
      ];
    } else if (variant === 'mother') {
      return [
        {
          weight: 1.1,
          title: tx('fields.arquivo'),
          field: 'arquivo',
          align: 'left',
          search: true,
        },
        {
          weight: 0.8,
          title: tx('fields.nome'),
          field: 'nomeContaMae',
          align: 'center',
          search: true,
        },
        {
          weight: 0.7,
          title: tx('fields.fonte'),
          field: 'fonteContaMae',
          align: 'center',
          search: true,
        },

        {
          weight: 0.7,
          title: tx('fields.banco'),
          field: 'banco',
          align: 'center',
          search: true,
        },
        {
          weight: 0.7,
          title: tx('fields.agencia'),
          field: 'agencia',
          align: 'center',
          search: true,
        },
        {
          weight: 0.7,
          title: tx('fields.conta'),
          field: 'conta',
          align: 'center',
          search: true,
        },
        {
          weight: 0.7,
          title: tx('fields.periodo'),
          field: 'dataInicio',
          align: 'center',
          type: 'date',
          searchType: 'date',
          search: true,
          parse: (_, data: ImportacaoExtrato) =>
            `${formatDateToBrazilian(data.dataInicio, false)} a ${formatDateToBrazilian(
              data.dataFim,
              false
            )}`,
        },
        {
          weight: 0.7,
          title: tx('fields.lancamentos'),
          field: 'lancamentos',
          align: 'right',
          searchType: 'range',
          search: true,
        },
      ];
    }
    return [];
  }, [tx, variant]);

  const getColor = (motivoFalha: string): string => {
    switch (motivoFalha) {
      case 'CNF':
        return '#FFCFCF';
      case 'CFNF':
        return '#FFCFCF';
      case 'IPPLS':
        return '#FFFFCC';
      case 'PNC':
        return '#FFFFCC';
    }
    return '#FFF';
  };

  const cancelImport = () => {
    const source = request<Pageable<any>>({
      url: '/financeiro/importacao/cancelar',
      method: 'PUT',
      onError: () => {
        messageService.error(tx('importError'), {
          duration: 2000,
        });
      },
      onSuccess: () => {
        history.push(`/dashboard/financeiro/dashboard`);
      },
    });

    return () => source.cancel();
  };

  const configError: ColumnConfig<ListType> = [
    {
      weight: 0.7,
      title: tx('fields.arquivo'),
      field: 'arquivo',
      align: 'left',
      search: true,
    },
    {
      weight: 1.1,
      title: tx('fields.motivo'),
      field: 'motivoFalha',
      align: 'center',
      type: 'toBuild',
      searchType: 'status',
      metadata: {
        options: [
          {
            text: tx('error.CNF'),
            value: 'CNF',
          },
          {
            text: tx('error.CFNF'),
            value: 'CFNF',
          },
          {
            text: tx('error.IPPLS'),
            value: 'IPPLS',
          },
        ],
      },
      search: true,
      columnBuilder: (data: ImportacaoExtrato) => (
        <div style={{ backgroundColor: getColor(data.motivoFalha) }}>
          {tx('error.' + data.motivoFalha)}
        </div>
      ),
    },
    {
      weight: 0.7,
      title: tx('fields.banco'),
      field: 'banco',
      align: 'center',
      search: true,
    },
    {
      weight: 0.7,
      title: tx('fields.agencia'),
      field: 'agencia',
      align: 'center',
      search: true,
    },
    {
      weight: 0.7,
      title: tx('fields.conta'),
      field: 'conta',
      align: 'center',
      search: true,
    },
    {
      weight: 0.7,
      title: tx('fields.periodo'),
      field: 'dataInicio',
      align: 'center',
      type: 'date',
      searchType: 'date',
      search: true,
      parse: (value, data: ImportacaoExtrato) =>
        `${formatDateToBrazilian(data.dataInicio, false)} a ${formatDateToBrazilian(
          data.dataFim,
          false
        )}`,
    },
    {
      weight: 0.7,
      title: tx('fields.lancamentos'),
      field: 'lancamentos',
      align: 'right',
      searchType: 'range',
      search: true,
    },
  ];

  const nextStep = useMemo(() => {
    if (variant === 'normal') {
      return '/dashboard/financeiro/revisao/revisao-mae';
    } else {
      return '/dashboard/financeiro/revisao/alertas';
    }
  }, [variant]);

  return (
    <>
      <TableList<ImportacaoExtrato>
        baseUrl={`/financeiro/importacao/importacoes`}
        listHeader={{
          title: tx('review.tableSuccessTitle'),
          position: 'inside',
        }}
        config={configSuccess}
        textSize="small"
        theme="light"
        editIcon={null}
        refreshesOn={95}
        notFoundProps={{}}
        filter
        filterFixed={{ ehContaMae: variant !== 'normal', estado: ['UPD'] }}
        infinityScroll
      />
      <TableList<ImportacaoExtrato>
        baseUrl={`/financeiro/importacao/importacoes`}
        listHeader={{
          title: tx('review.tableErrorTitle'),
          position: 'inside',
        }}
        config={configError}
        textSize="small"
        theme="light"
        editIcon={null}
        refreshesOn={95}
        notFoundProps={{}}
        filter
        filterFixed={{ ehContaMae: variant !== 'normal', estado: ['PRO'] }}
        infinityScroll
      />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button type="secondary" onClick={cancelImport}>
          {tx('actions.cancel')}
        </Button>
        <Button onClick={() => history.push(nextStep)}>{tx('actions.continue')}</Button>
      </div>
    </>
  );
};

export default ImportReview;
