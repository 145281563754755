import React, { ElementType, useEffect, useRef } from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { renderComponent } from 'utils/components';
import { MenuItem } from '../types';

type Props = {
  items: MenuItem[];
  forwardProps?: object | ((item: MenuItem) => object);
  contentWrapper?: ElementType;
  onChange?: (item?: MenuItem) => void;
};

const ContentSwitcher = (props: Props) => {
  const { items, forwardProps, contentWrapper, onChange } = props;

  const { path, url } = useRouteMatch();
  const location = useLocation();
  const pathname = useRef<string>();

  useEffect(() => {
    if (onChange && location.pathname !== pathname.current) {
      pathname.current = location.pathname;
      let itemPath = location.pathname.replace(url, '');

      if (itemPath) {
        if (itemPath.startsWith('/')) {
          itemPath = itemPath.substring(1);
        }

        for (const item of items) {
          if (itemPath === (item.path || item.key)) {
            return onChange(item);
          }
        }
      }

      onChange();
    }
  }, [items, location.pathname, onChange, url]);

  const renderContent = (item: MenuItem) => {
    const props = typeof forwardProps === 'function' ? forwardProps(item) : forwardProps;
    let newProps = props;
    if (props && props.state) {
      newProps = {
        ...props,
        state: {
          ...props.state,
          section: item.key,
        },
      };
    }
    newProps = {
      ...newProps,
      ...(item?.itemProps !== undefined ? item.itemProps : {}),
    };

    if (contentWrapper) {
      return renderComponent(contentWrapper, {
        newProps,
        item,
      });
    }

    return renderComponent(item.component, newProps);
  };

  const renderSwitch = () => (
    <Switch>
      <Route exact path={path}>
        {items.length ? (
          <Redirect to={`${url}/${items[0].path || items[0].key}`} />
        ) : null}
      </Route>

      {items.map(item => (
        <Route key={item.key} path={`${url}/${item.path || item.key}`}>
          {renderContent(item)}
        </Route>
      ))}

      <Redirect to={url} />
    </Switch>
  );

  return renderSwitch();
};

export default ContentSwitcher;
