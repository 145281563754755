import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';

import { useTranslation } from 'i18n';
import { InputProps, Value } from '../types';
import { Loader } from 'components/elements';
import { Triangle as Icon } from 'components/icons';
import { useAsyncOptions, useFirstRender } from 'components/hooks';
import { useForm } from 'components/form';
import Source from 'components/inputs/AutoCompute/Source';
import { FieldOptions, FieldOption, ObserveFieldsProps } from 'components/form/types';
import { call } from 'utils';
import { classes } from 'utils/components';
import Dropdown from './Dropdown';

import styles from './Select.module.scss';

export type Props = { parseOption?: (value: any) => any };

const Select = forwardRef<HTMLDivElement, InputProps & Props & ObserveFieldsProps>(
  (props, ref) => {
    const { value, metadata, control, inputProps, parseOption = x => x, name } = props;

    const [expanded, setExpanded] = useState(false);
    const [selected, setSelected] = useState(value);
    const [dynamicBlocked, setDynamicBlocked] = useState(false);

    const firstRender = useFirstRender();

    const anchorRef = useRef<HTMLDivElement>();
    const optionRef = useRef<HTMLDivElement>(null);

    const { onChange, onBlur, onFocus, ...rest } = inputProps;
    const { t } = useTranslation();
    const { options, loading, active } = useAsyncOptions(metadata);
    const blocked =
      dynamicBlocked || control.type === 'disabled' || control.type === 'readonly';

    const filteredOptions = useMemo(() => {
      if (Array.isArray(options) && options.length) {
        return options.filter(option => option.active !== false);
      }
      return options;
    }, [options]);

    useEffect(() => setSelected(value), [value]);

    useEffect(() => {
      onChange(selected);

      if (optionRef.current) {
        optionRef.current.focus();
      }
    }, [selected, onChange]);

    const form = useForm();

    const onSourceChange = useCallback(
      (id: string, value: any, params?: string[]) => {
        if (props.onObservedFieldChange) {
          props.onObservedFieldChange(
            metadata?.name || id,
            value,
            form,
            params || [],
            setDynamicBlocked,
            setSelected,
            setExpanded
          );
        }
      },
      [form, metadata, props]
    );

    const sources: ReactNode[] = [];

    if (props.observeFields) {
      props.observeFields.forEach(field => {
        let basePath = '';
        const strings = metadata?.name?.split('.');
        if (strings && strings.length > 0) {
          basePath = strings.slice(0, strings.length - 1).join('.') + '.';
        }
        sources.push(
          <Source
            key={field}
            id={field}
            fieldName={basePath + field}
            onChange={onSourceChange}
            hasParams={false}
          />
        );
      });
    }

    // Verifica se o item selecionado está na lista de opções
    useEffect(() => {
      if (firstRender) return;
      if (
        control.type !== 'readonly' &&
        selected !== undefined &&
        Array.isArray(filteredOptions) &&
        filteredOptions.length &&
        !getOption(selected, filteredOptions)
      ) {
        setSelected(undefined);
      }
    }, [active, control.type, filteredOptions, firstRender, name, selected]);

    function collapse() {
      anchorRef.current?.focus();
      setExpanded(false);
    }

    function handlePopupKey(event: React.KeyboardEvent) {
      event.preventDefault();

      switch (event.key) {
        case 'Escape':
        case 'Tab':
        case 'Enter':
          collapse();
          break;

        case 'ArrowUp':
          setSelected(selected => previousValue(selected, options));
          break;

        case 'ArrowDown':
          setSelected(selected => nextValue(selected, options));
          break;

        default:
          const match = getFirstMatch(event.key, options, parseOption);

          if (match) {
            setSelected(match);
          }

          break;
      }
    }

    function handleButtonKey(event: React.KeyboardEvent) {
      switch (event.key) {
        case 'Enter':
        case ' ':
          setExpanded(expanded => !expanded);
          break;

        default:
          break;
      }
    }

    function handleFocus(event: React.FocusEvent) {
      if (anchorRef.current?.contains(event.target) && !expanded) {
        call(event.type === 'blur' ? onBlur : onFocus);
      }
    }

    function attachRef(el: HTMLDivElement) {
      anchorRef.current = el;

      if (typeof ref === 'function') {
        ref(el);
      } else if (ref) {
        ref.current = el;
      }
    }

    const handlers =
      active && !blocked
        ? {
            onClick: () => setExpanded(expanded => !expanded),
            onBlur: handleFocus,
            onFocus: handleFocus,
            onKeyDown: handleButtonKey,
          }
        : {};

    const selectedText = getSelectedText(selected, options, parseOption);
    const placeholder =
      !blocked && !selectedText && Array.isArray(options) && options.length;

    return (
      <div
        className={classes(styles.selectInput, styles[control.type || ''])}
        style={active && !blocked ? undefined : { pointerEvents: 'none' }}
      >
        <div
          {...rest}
          ref={attachRef}
          className={styles.button}
          role="button"
          aria-haspopup="listbox"
          aria-expanded={expanded}
          {...handlers}
        >
          {loading ? (
            <Loader size={26} type="spin" color={control.status} />
          ) : (
            <div className={classes(styles.input, placeholder ? styles.placeholder : '')}>
              {placeholder ? (
                <div>{t('components.select.placeholder')}</div>
              ) : (
                selectedText
              )}
            </div>
          )}

          {!blocked && (
            <div className={styles.icon}>
              <Icon size={1.2} />
            </div>
          )}
          {sources}
        </div>

        <Dropdown
          visible={expanded}
          options={filteredOptions}
          anchor={control.ref.current}
          renderOption={({ text, value }) => (
            <div
              ref={value === selected ? optionRef : null}
              className={classes(
                styles.option,
                value === selected ? styles.selected : ''
              )}
              onClick={() => {
                collapse();
                setSelected(value);
              }}
              tabIndex={0}
            >
              {parseOption(text)}
            </div>
          )}
          onMount={() => optionRef.current?.focus()}
          onBlur={() => {
            setExpanded(false);
            call(onBlur);
          }}
          onKeyDown={handlePopupKey}
        />
      </div>
    );
  }
);

export default Select;

function getOption(
  value: Value,
  options: FieldOptions,
  offset: number = 0
): FieldOption | undefined {
  if (value !== undefined) {
    if (Array.isArray(options) && options.length) {
      for (let i = 0; i < options.length; ++i) {
        if (options[i].value === value) {
          return options[Math.max(Math.min(i + offset, options.length - 1), 0)];
        }
      }

      return offset ? options[offset < 0 ? options.length - 1 : 0] : undefined;
    }
  } else if (offset && Array.isArray(options) && options.length) {
    return options[offset < 0 ? options.length - 1 : 0];
  }
}

function nextValue(current: Value, options: FieldOptions): Value {
  const option = getOption(current, options, 1);
  return option ? option.value : undefined;
}

function previousValue(current: Value, options: FieldOptions): Value {
  const option = getOption(current, options, -1);
  return option ? option.value : undefined;
}

function getSelectedText(
  current: Value,
  options: FieldOptions,
  parseOption: (value: any) => any
): string | undefined {
  const option = getOption(current, options);
  return option ? parseOption(option.text) : undefined;
}

function getFirstMatch(ch: string, options: any, parseOption: (value: any) => any) {
  for (const option of options) {
    if (parseOption(option.text).charAt(0).toLowerCase() === ch.toLowerCase()) {
      return option.value;
    }
  }
}
