import React from 'react';

import { useFetch } from 'apis';
import { Grid, Row } from 'components/containers';
import { KeyValueRow, Loader, WrapperCard } from 'components/elements';
import { Form, MultiSelect, TextField } from 'components/form';
import { FormInstance } from 'components/form/types';
import { useTranslationX } from 'i18n';

import Tippy from '@tippyjs/react';
import { appendDots } from 'utils/stringUtils';
import { MotherAccount } from '../MotherAccountsList/MotherAccountsList';

import styles from './MotherAccountsForm.module.scss';

type Props = {
  onDone: VoidFunction;
  formRef: React.RefObject<FormInstance>;
  type: 'create' | 'update';
  populate?: MotherAccount;
};

export type ProjectAccountItem = {
  titulo: string;
  identificacao: string;
  sigla: string;
  fonteNome: string;
  gpf: string;
  conta: {
    id: number;
    banco: string;
    agencia: string;
    numero: string;
  };
};

const MotherAccountsForm = ({ formRef, onDone, type, populate }: Props) => {
  const { tx } = useTranslationX('accounts', 'finance');
  const { tx: tx2 } = useTranslationX('expenses', 'finance');

  const { data } = useFetch<ProjectAccountItem[]>('/projetos/contas');

  const baseUrl =
    type === 'create' && !populate
      ? '/financeiro/contas-mae'
      : `/financeiro/contas-mae/${populate?.id}`;

  if (!data) return <Loader />;

  return (
    <>
      <WrapperCard maxWidth="100%" padding="30px 32px">
        <Form
          submit={{
            method: type === 'create' ? 'post' : 'put',
          }}
          baseUrl={baseUrl}
          ref={formRef}
          onDone={onDone}
          metadataUrl="/financeiro/contas-mae/metadata"
        >
          <Grid spacing={0}>
            <Row width={[12]}>
              <TextField name="nome" label={tx('name')} value={populate?.nomeContaMae} />
            </Row>
            {type === 'create' ? (
              <>
                <Row>
                  <TextField name="banco" label={tx('bank')} />
                  <TextField
                    name="agencia"
                    label={tx('agency')}
                    metadata={{
                      rules: {
                        required: true,
                        maxlength: 6,
                        mask: { pattern: 'agency' },
                      },
                    }}
                  />
                  <TextField
                    name="numero"
                    label={tx('account')}
                    metadata={{
                      rules: { required: true, mask: { pattern: 'account' } },
                    }}
                  />
                </Row>
                <Row>
                  <TextField name="fonte" label={tx('source')} />
                </Row>
              </>
            ) : null}
            <Row>
              <MultiSelect
                name="projetoContas"
                label={tx('projectAccounts')}
                metadata={{
                  options: data.map(account => {
                    return {
                      text: account,
                      value: account.conta.id,
                    };
                  }),
                }}
                value={populate?.contas}
                parseOption={account =>
                  `${tx2('identification')} ${account.identificacao} - ${
                    account.sigla
                  } - ${tx('account')}: ${account.conta.numero}`
                }
                modalProps={{
                  width: 900,
                }}
                styles={{
                  container: {
                    paddingInline: 24,
                    backgroundColor: 'red',
                  },
                  root: {
                    maxHeight: 700,
                  },
                }}
                renderOption={value => {
                  return <ProjectAccount account={value} />;
                }}
                modalTitle={tx('projectAccountsList')}
                trimSize={100}
              />
            </Row>
          </Grid>
        </Form>
      </WrapperCard>
    </>
  );
};

type ProjectAccountProps = {
  account: ProjectAccountItem;
};

export const ProjectAccount = ({ account }: ProjectAccountProps) => {
  const { tx } = useTranslationX('expenses', 'finance');

  const title = `${tx('identification')} ${account.identificacao} - ${account.sigla}`;

  return (
    <WrapperCard minWidth="350px" padding="0px" height="150px">
      <div className={styles.expensePart}>
        <p className={styles.subtitle}>
          <span>{account.fonteNome}</span>
        </p>
        <p className={styles.title}>
          <Tippy
            content={title}
            animation="perspective"
            touch={false}
            className={styles.popup}
          >
            <span>{appendDots(title, 30)}</span>
          </Tippy>
        </p>
        <Grid spacing={0}>
          <div className={styles.cardContent}>
            <Row>
              <KeyValueRow
                label={tx('gpf')}
                value={account.gpf}
                size="small"
                margin="0"
                customStyles={{
                  flexWrap: 'nowrap',
                }}
              />

              <Tippy
                content={account?.conta?.banco}
                animation="perspective"
                touch={false}
                className={styles.popup}
              >
                <div>
                  <KeyValueRow
                    label={tx('bank')}
                    value={appendDots(account?.conta?.banco, 12)}
                    margin="0"
                  />
                </div>
              </Tippy>
            </Row>
            <Row>
              <KeyValueRow
                label={tx('agency')}
                value={account.conta.agencia}
                size="small"
                margin="0"
              />

              <KeyValueRow
                label={tx('account')}
                value={account.conta.numero}
                margin="0"
              />
            </Row>
          </div>
        </Grid>
      </div>
    </WrapperCard>
  );
};

export default MotherAccountsForm;
